import AppCodeField from './app-components/AppCodeField'
import AppCombobox from './app-components/AppCombobox'
import AppDashed from './app-components/AppDashed'
import AppDatePicker from './app-components/AppDatePicker'
import AppDiv from './app-components/AppDiv'
import AppMarkdownEditor from './app-components/AppMarkdownEditor'
import AppMarkdownViewer from './app-components/AppMarkdownViewer'
import AppPagination from './app-components/AppPagination'
import AppQrCode from './app-components/AppQrCode'
import AppSelect from './app-components/AppSelect'
import AppSwitch from './app-components/AppSwitch'
import AppTable from './app-components/AppTable'
import AppTextarea from './app-components/AppTextarea'
import AppTextField from './app-components/AppTextField'

// register all base components here (prefixed with App)
export default Vue => {
  Vue.component(AppCodeField.name, AppCodeField)
  Vue.component(AppCombobox.name, AppCombobox)
  Vue.component(AppDashed.name, AppDashed)
  Vue.component(AppDatePicker.name, AppDatePicker)
  Vue.component(AppDiv.name, AppDiv)
  Vue.component('AppMarkdownEditor', AppMarkdownEditor)
  Vue.component(AppMarkdownViewer.name, AppMarkdownViewer)
  Vue.component(AppPagination.name, AppPagination)
  Vue.component(AppQrCode.name, AppQrCode)
  Vue.component(AppSelect.name, AppSelect)
  Vue.component(AppSwitch.name, AppSwitch)
  Vue.component(AppTable.name, AppTable)
  Vue.component(AppTextarea.name, AppTextarea)
  Vue.component(AppTextField.name, AppTextField)
}
