var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AppTable",attrs:{"role":"table"}},[_vm._t("prepend"),_c('div',{staticClass:"AppTable__header"},_vm._l((_vm.headers),function(header){return _c('div',_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(header.sortable !== false ? { class: 'app-ripple' } : false),expression:"header.sortable !== false ? { class: 'app-ripple' } : false"}],key:header.value,staticClass:"AppTable__cell AppTable__cell--header",style:({ flex: header.flex || '1 0 0' }),on:{"click":function($event){header.sortable !== false && _vm.toggleSort(header.value)}}},'div',_vm.headerBind(header),false),[_c('div',{staticClass:"AppTable__header-text"},[_vm._t('header.' + header.value,function(){return [_vm._v(" "+_vm._s(header.text)+" ")]},{"header":header})],2),(header.sortable !== false)?_c('span',{staticClass:"AppTable__sort-icon",class:{
          'AppTable__sort-icon--active': _vm.sort.prop === header.value,
          'AppTable__sort-icon--desc': _vm.sort.prop === header.value && _vm.sort.desc === true,
        },domProps:{"textContent":_vm._s('↓')}}):_vm._e()])}),0),_vm._l((_vm.sortedItems),function(item){return _c(_vm.rowTo != null ? 'router-link' : 'div',_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.$listeners['click:row'] != null || _vm.rowTo != null ? { class: 'app-ripple' } : false),expression:"$listeners['click:row'] != null || rowTo != null ? { class: 'app-ripple' } : false"}],key:item[item.id],tag:"component",staticClass:"AppTable__row",style:({ background: _vm.resolveItemFunction(_vm.rowBackground, item) }),on:{"click":function($event){return _vm.$emit('click:row', item)}}},'component',_vm.rowBind(item),false),_vm._l((_vm.headers),function(header){return _c(header.to != null ? 'router-link' : 'div',_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(header.click != null || header.to != null ? { class: 'app-ripple' } : false),expression:"header.click != null || header.to != null ? { class: 'app-ripple' } : false"}],key:header.value,tag:"component",staticClass:"AppTable__cell AppTable__cell--item",style:({
        flex: header.flex || '1 0 0',
        whitespace: header.nowrap ? 'nowrap' : null,
      }),on:{"click":function($event){header.click != null &&
        (header.click(item, $event), $event.stopPropagation(), $event.preventDefault())}},nativeOn:{"click":function($event){return $event.stopPropagation()}}},'component',_vm.itemBind(header, item),false),[_c('v-tooltip',{attrs:{"disabled":!_vm.getTooltip(header, item),"bottom":"","nudge-top":"12","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',_vm._g(_vm._b({staticClass:"AppTable__cell-contents"},'div',attrs,false),on),[_vm._t('item.' + header.value,function(){return [_c('span',{staticClass:"AppTable__item-text",domProps:{"textContent":_vm._s(_vm.displayValue(header, item))}})]},{"item":item,"header":header,"value":item[header.value],"displayValue":_vm.displayValue(header, item),"hover":hover})],2)]}}],null,true)})]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.getTooltip(header, item))}})])],1)}),1)}),_vm._t("append")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }