<!-- The only purpose of this component is for dynamic usage with router-link in this scenario:
<component
  :is="to ? 'router-link' : 'AppDiv'"
  @click.native="onClick"
/>
The problem is that 'router-link' requires .native event listeners but 'div' does not,
that is why 'AppDiv' can be useful.
-->

<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppDiv',
}
</script>
