import { vuetifyIcons, themes } from '@hexway/shared-front'
import Vue from 'vue'

import SvgAdd from '../assets/icons/add.svg?inline'
import SvgArrowLeft from '../assets/icons/arrow-left.svg?inline'
import SvgArrowRight from '../assets/icons/arrow-right.svg?inline'
import SvgBlock from '../assets/icons/block.svg?inline'
import SvgCalendar from '../assets/icons/calendar.svg?inline'
import SvgCatProfile from '../assets/icons/cat-profile.svg?inline'
import SvgCheck from '../assets/icons/check.svg?inline'
import SvgChevronDown from '../assets/icons/chevron-down.svg?inline'
import SvgChevronLeft from '../assets/icons/chevron-left.svg?inline'
import SvgChevronRight from '../assets/icons/chevron-right.svg?inline'
import SvgChevronUp from '../assets/icons/chevron-up.svg?inline'
import SvgCog from '../assets/icons/cog.svg?inline'
import SvgCopy from '../assets/icons/copy.svg?inline'
import SvgCrown from '../assets/icons/crown.svg?inline'
import SvgFolder from '../assets/icons/folder.svg?inline'
import SvgFolderOpen from '../assets/icons/folder-open.svg?inline'
import SvgFolderOpenOutline from '../assets/icons/folder-open-outline.svg?inline'
import SvgFolderOutline from '../assets/icons/folder-outline.svg?inline'
import SvgDashboard from '../assets/icons/dashboard.svg?inline'
import SvgDrag from '../assets/icons/drag.svg?inline'
import SvgEdit from '../assets/icons/edit.svg?inline'
import SvgExit from '../assets/icons/exit.svg?inline'
import SvgEye from '../assets/icons/eye.svg?inline'
import SvgEyeClose from '../assets/icons/eye-close.svg?inline'
import SvgFilterList from '../assets/icons/filter-list.svg?inline'
import SvgHappyFile from '../assets/icons/happy-file.svg?inline'
import SvhHourglass from '../assets/icons/hourglass.svg?inline'
import SvgJira from '../assets/icons/jira.svg?inline'
import SvgKey from '../assets/icons/key.svg?inline'
import SvgLeft from '../assets/icons/left.svg?inline'
import SvgListView from '../assets/icons/list-view.svg?inline'
import SvgLock from '../assets/icons/lock.svg?inline'
import SvgLogoColorApiary from '../assets/icons/logo-color-apiary.svg?inline'
import SvgLogoColorHive from '../assets/icons/logo-color-hive.svg?inline'
import SvgLogoColorJira from '../assets/icons/logo-color-jira.svg?inline'
import SvgLogout from '../assets/icons/logout.svg?inline'
import SvgPentests from '../assets/icons/pentests.svg?inline'
import SvgPinPinned from '../assets/icons/pin-pinned.svg?inline'
import SvgPinUnpinned from '../assets/icons/pin-unpinned.svg?inline'
import SvgRemove from '../assets/icons/remove.svg?inline'
import SvgSad from '../assets/icons/sad.svg?inline'
import SvgShield from '../assets/icons/shield.svg?inline'
import SvgShieldOff from '../assets/icons/shield-off.svg?inline'
import SvgSortArrowDown from '../assets/icons/sort-arrow-down.svg?inline'
import SvgSortArrowUp from '../assets/icons/sort-arrow-up.svg?inline'
import SvgTableView from '../assets/icons/table-view.svg?inline'
import SvgTrashCan from '../assets/icons/trash-can.svg?inline'
import SvgConnectionStatusHigh from '../assets/icons/connection-status-high-icon.svg?inline'
import SvgConnectionStatusLow from '../assets/icons/connection-status-low-icon.svg?inline'
import SvgConnectionStatusMedium from '../assets/icons/connection-status-medium-icon.svg?inline'
import SvgConnectionStatusNull from '../assets/icons/connection-status-null-icon.svg?inline'
import SvgConnectionStatusGood from '../assets/icons/connection-status.svg?inline'
import SvgWrench from '../assets/icons/wrench.svg?inline'
import SvgContactSupport from '../assets/icons/contact-support.svg?inline'
import SvgArrowForwardRight from '../assets/icons/arrow-forward-right.svg?inline'
import SvgCopyValueIcon from '../assets/icons/copy-values-icon.svg?inline'

import SvgIntegrationJira from '../assets/icons/integration-jira.svg?inline'
import SvgIntegrationSlack from '../assets/icons/integration-slack.svg?inline'

let Vuetify
if (process.env.NODE_ENV === 'test') {
  Vuetify = require('vuetify')
} else {
  Vuetify = require('vuetify/lib').default
}

const iconComponent = (icon, { preserveColor = false, fillCurrentColor = true } = {}) => ({
  components: { icon },
  render(h) {
    return h(
      icon,
      {
        style: preserveColor
          ? {}
          : fillCurrentColor ? { fill: 'currentColor' } : [],
        class: 'SvgIcon' + (preserveColor ? '' : ' SvgIcon--monochrome'),
      },
    )
  },
})

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    themes,
    // uncomment to test theming:
    // themes: R.tap(console.log.bind(console), R.mergeDeepRight(themes, {
    //   light: {
    //     primary: '#7d7d04',
    //     lightPrimary: '#7d7d04',
    //     darkPrimary: '#ffc0cb',
    //   },
    //   dark: {
    //     primary: '#ffc0cb',
    //     lightPrimary: '#7d7d04',
    //     darkPrimary: '#ffc0cb',
    //   },
    // })),
  },
  icons: {
    values: {
      add: { component: iconComponent(SvgAdd) },
      'arrow-down': { component: iconComponent(SvgSortArrowDown) },
      'arrow-forward-right': { component: iconComponent(SvgArrowForwardRight, { fillCurrentColor: false }) },
      'arrow-left': { component: iconComponent(SvgArrowLeft) },
      'arrow-right': { component: iconComponent(SvgArrowRight) },
      block: { component: iconComponent(SvgBlock, { fillCurrentColor: false }) },
      'chevron-down': { component: iconComponent(SvgChevronDown) },
      'chevron-left': { component: iconComponent(SvgChevronLeft) },
      'chevron-right': { component: iconComponent(SvgChevronRight) },
      'chevron-up': { component: iconComponent(SvgChevronUp) },
      calendar: { component: iconComponent(SvgCalendar) },
      'cat-profile': { component: iconComponent(SvgCatProfile) },
      check: { component: iconComponent(SvgCheck, { fillCurrentColor: false }) },
      cog: { component: iconComponent(SvgCog) },
      'connection-status-high': { component: iconComponent(SvgConnectionStatusHigh) },
      'connection-status-low': { component: iconComponent(SvgConnectionStatusLow) },
      'connection-status-medium': { component: iconComponent(SvgConnectionStatusMedium) },
      'connection-status-null': { component: iconComponent(SvgConnectionStatusNull) },
      'connection-status-good': { component: iconComponent(SvgConnectionStatusGood, { preserveColor: true }) },
      'contact-support': { component: iconComponent(SvgContactSupport, { fillCurrentColor: false }) },
      copy: { component: iconComponent(SvgCopy) },
      'copy-value': { component: iconComponent(SvgCopyValueIcon) },
      crown: { component: iconComponent(SvgCrown) },
      folder: { component: iconComponent(SvgFolder) },
      'folder-open': { component: iconComponent(SvgFolderOpen) },
      'folder-open-outline': { component: iconComponent(SvgFolderOpenOutline) },
      'folder-outline': { component: iconComponent(SvgFolderOutline) },
      dashboard: { component: iconComponent(SvgDashboard) },
      drag: { component: iconComponent(SvgDrag) },
      edit: { component: iconComponent(SvgEdit) },
      exit: { component: iconComponent(SvgExit) },
      eye: { component: iconComponent(SvgEye) },
      'eye-close': { component: iconComponent(SvgEyeClose) },
      'filter-list': { component: iconComponent(SvgFilterList) },
      'happy-file': { component: iconComponent(SvgHappyFile) },
      hourglass: { component: iconComponent(SvhHourglass, { fillCurrentColor: false }) },
      jira: { component: iconComponent(SvgJira) },
      key: { component: iconComponent(SvgKey) },
      left: { component: iconComponent(SvgLeft) },
      'list-view': { component: iconComponent(SvgListView) },
      lock: { component: iconComponent(SvgLock) },
      'logo-color-apiary': { component: iconComponent(SvgLogoColorApiary, { preserveColor: true }) },
      'logo-color-hive': { component: iconComponent(SvgLogoColorHive, { preserveColor: true }) },
      'logo-color-jira': { component: iconComponent(SvgLogoColorJira, { preserveColor: true }) },
      logout: { component: iconComponent(SvgLogout) },
      pentests: { component: iconComponent(SvgPentests, { fillCurrentColor: false }) },
      'pin-pinned': { component: iconComponent(SvgPinPinned, { fillCurrentColor: false }) },
      'pin-unpinned': { component: iconComponent(SvgPinUnpinned, { fillCurrentColor: false }) },
      remove: { component: iconComponent(SvgRemove) },
      sad: { component: iconComponent(SvgSad) },
      shield: { component: iconComponent(SvgShield, { fillCurrentColor: false }) },
      'shield-off': { component: iconComponent(SvgShieldOff, { fillCurrentColor: false }) },
      'table-view': { component: iconComponent(SvgTableView) },
      'trash-can': { component: iconComponent(SvgTrashCan) },
      wrench: { component: iconComponent(SvgWrench, { fillCurrentColor: false }) },

      // NB! affects data-table sort icon
      sort: { component: iconComponent(SvgSortArrowUp) },

      // these are a bit different: they have fixed custom colors
      'integration-jira': { component: iconComponent(SvgIntegrationJira, { preserveColor: true }) },
      'integration-slack': { component: iconComponent(SvgIntegrationSlack, { preserveColor: true }) },

      ...vuetifyIcons,
    },
  },
})
