const initState = () => ({
  portal: false,
})

const mutations = {
  setPortal: (state, portal) => {
    state.portal = !!portal
  },
}

export default {
  namespaced: true,
  state: initState,
  mutations,
}
