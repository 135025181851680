import { normalize } from 'normalizr'
import Vue from 'vue'

import { BotUserService } from '../../api'
import { handleError } from '../../helpers'
import { BotUser } from '../schema'
import i18n from '../../i18n'

const initState = () => ({
  byId: {}, // Object<UUID, BotUser>
  order: null, // Array<UUID>
})

export default {
  namespaced: true,

  state: initState,

  getters: {
    current: state =>
      state.current && state.byId[state.current],
    get: ({ byId }) =>
      botId =>
        byId[botId] || null,
    list: ({ order }, { get }) =>
      order && order.map(get).filter(Boolean),
  },

  mutations: {
    reset: state => Object.assign(state, initState()),

    setOne: (state, { bot }) => {
      const {
        result: id,
        entities: { bots },
      } = normalize(bot, BotUser)
      Vue.set(state.byId, id, { ...state.byId[id], ...bots[id] })
    },

    setList: (state, { bots }) => {
      const {
        result: order,
        entities: { bots: byId },
      } = normalize(bots.map(bot => bot), [BotUser])
      state.byId = byId
      state.order = order
    },

    remove: (state, { botId }) => {
      while (state.order.includes(botId)) state.order.splice(state.order.indexOf(botId), 1)
      Vue.delete(state.byId, botId)
    },
  },

  actions: {
    getList: ({ commit }) => BotUserService.botUserList()
      .then((bots) => commit('setList', { bots }))
      .catch(e => handleError({ commit }, e)),

    register: ({ commit, dispatch }, { bot: { role, userLogin }, showTokenDialog = true }) =>
      BotUserService.botUserRegistration({ body: { role, userLogin } })
        .then(async ({ token }) => {
          await Promise.all([
            dispatch('getList'),
            showTokenDialog && dispatch('_showTokenDialog', { token }),
          ])
          return token
        })
        .catch(e => handleError({ commit }, e)),

    regenerateToken: ({ commit, dispatch }, { botId, showTokenDialog = true }) =>
      BotUserService.botUserPatchToken({ userId: botId })
        .then(({ token }) => Promise.all([
          dispatch('getList'),
          showTokenDialog && dispatch('_showTokenDialog', { token }),
        ]).then(() => token))
        .catch(e => handleError({ commit }, e)),

    delete: ({ commit }, { botId }) =>
      BotUserService.botUserDelete({ userId: botId })
        .then(() => commit('remove', { botId }))
        .catch(e => handleError({ commit }, e)),

    _showTokenDialog: ({ dispatch }, { token }) =>
      dispatch('confirm/openDialog', {
        title: i18n.t('user.TokenReady'),
        subtitle: i18n.t('user.SaveTokenM'),
        dataToCopy: token,
        consentLabel: i18n.t('user.GotIt'),
        rejectLabel: null,
      }, { root: true }),
  },
}
