import axiosLib from 'axios'
import axiosRetry from 'axios-retry'

import * as api from '../gen'
import { AxiosResponse } from './constants'

export const API_BASE = process.env.VUE_APP_SERVER_URL || '/api/'

const axiosInstance = axiosLib.create({
  baseURL: API_BASE,
  withCredentials: true,
})

// hacky hacks to propagate original axios response through the disasters of swagger-axios-codegen
const axiosRequest = axiosInstance.request
axiosInstance.request = function() {
  const promise = axiosRequest.apply(axiosInstance, arguments)
  return promise.then(response => {
    if (response.data) {
      try {
        response.data[AxiosResponse] = response
      } catch (_) {}
    }
    return response
  })
}

// export const registerInterceptors = (store) => {
//   axiosInstance.interceptors.response.use(undefined, (error) => {
//     if (error.response && error.response.status === 461) {
//       store.commit('license/setLicenseDialog', { isOpen: true, featureName: error.response.data?.detail })
//     }
//
//     // const errorData = {
//     //   ...(error?.response?.data || {}),
//     //   originalError: error,
//     // }
//     return Promise.reject(error)
//   })
// }

axiosRetry(axiosInstance, {
  retryDelay: axiosRetry.exponentialDelay, // https://developers.google.com/analytics/devguides/reporting/core/v3/errors#backoff
  retries: 7, // number of retries
  retryCondition: (error) => {
    // if retry condition is not specified, by default idempotent requests are retried
    return error?.response?.status === 425
  },
})

api.serviceOptions.axios = axiosInstance

export * from '../gen'
export { axiosInstance }
