import { Model } from '@vuex-orm/core'
import * as R from 'ramda'

import {
  CARD_TYPE,
  EMPTY_SET,
  ISSUE_FIELD_TYPE,
  SCORE,
  SCORE_HIGH,
  SCORE_MEDIUM,
  SCORE_LOW,
} from '../../constants'
import {
  unorderedPlainObjectHash,
  objectToIssueFilterQuery,
  mergeIssueFilters,
  mergeIssueFiltersAdditive,
  handleError,
} from '../../helpers'
import { withCancelToken } from '../cancellableActions'

const emptyScoreCounters = (defaultValue = null) => ({
  [SCORE_HIGH]: defaultValue,
  [SCORE_MEDIUM]: defaultValue,
  [SCORE_LOW]: defaultValue,
})

export class IssueCounter extends Model {
  static entity = 'issueCounter'
  static primaryKey = 'hash'

  static fields() {
    return {
      hash: this.string(null).nullable(),

      status: this.attr({}),
      totalScore: this.attr(emptyScoreCounters()),
      probabilityScore: this.attr(emptyScoreCounters()),
      criticalityScore: this.attr(emptyScoreCounters()),
      overdue: this.attr({ true: null, false: null, 'no-sla': null }),
      isCompleted: this.attr({ true: null, false: null }),
      ips: this.attr({}),
      hostnames: this.attr({}),
      ports: this.attr({}),

      customFields: this.attr({}),

      total: this.attr(null).nullable(),
    }
  }

  static calculateHash(projectId, filter, additiveFilters = false) {
    const { state } = IssueCounter.store()
    const { entities: { issue: { $filter: { [projectId]: projectFilter } } } } = state

    // const rv = unorderedPlainObjectHash({ filters: projectFilter, additive: additiveFilters })
    // console.warn(`Hash for ${JSON.stringify(Array.from(arguments))} is ${rv}`)
    // return rv
    return unorderedPlainObjectHash({ filters: projectFilter, additive: additiveFilters })
  }

  static get predefinedFieldNames() {
    return R.pipe(
      R.keys,
      R.without(['customFields']),
    )(this.fields())
  }

  static getOrDefault(projectId, filter, additiveFilters = false) {
    const hash = IssueCounter.calculateHash(projectId, filter, additiveFilters)
    const existingCounters = IssueCounter.find(hash)
    return existingCounters || new IssueCounter({ hash })
  }

  static async ensureIssueStatusLookup({ projectId }) {
    const { getters, dispatch } = IssueCounter.store()
    let statusLookup = getters['$issueStatus/getLookup'](projectId)
    if (!statusLookup) {
      await dispatch('$issueStatus/getList', { projectId })
      statusLookup = getters['$issueStatus/getLookup'](projectId)
    }
    return statusLookup
  }

  static async setCounter({
    projectId,
    filters, // Object with filters
    fieldName, // 'status', 'totalScore', 'probabilityScore', 'criticalityScore', 'total', etc.
    fieldValue, // depends on `fieldName`, like 'new_issue' for 'status' or `const.SCORE_HIGH` for 'totalScore'
    counter = 0, // counter value
    additive = true,
  }) {
    const hash = IssueCounter.calculateHash(projectId, filters, additive)
    const counters = IssueCounter.getOrDefault(projectId, filters, additive)

    let updatePath = null
    if (fieldName !== 'total') {
      updatePath = IssueCounter.predefinedFieldNames.includes(fieldName)
        ? [fieldName]
        : ['customFields', fieldName]
    }
    const updateCounterField = R.pipe(
      R.defaultTo({}),
      R.mergeLeft({ [fieldValue]: counter }),
    )
    const update = R.ifElse(
      () => R.equals(fieldName, 'total'),
      R.assoc('total', counter),
      R.over(R.lensPath(updatePath), updateCounterField),
    )

    await IssueCounter.insertOrUpdate({
      data: R.pipe(
        update,
        R.over(R.lensProp('hash'), R.defaultTo(hash)),
        // R.tap(counterToUpdate => console.warn(`IssueCounter.setCounter(${
        //   JSON.stringify(arguments[0], null, 2)
        // }) with ${
        //   JSON.stringify(counterToUpdate, null, 2)
        // }`)),
      )(counters),
    })
  }
}

export const IssueCounterModule = {
  actions: {
    async $countCommon(ctx, {
      projectId,
      filter = null,
      additive = true,
      cancellable = true,
    }) {
      const { dispatch, rootState, rootGetters } = ctx

      // 1. ensure project settings are loaded
      const statusLookup = await IssueCounter.ensureIssueStatusLookup({ projectId })

      // some helpers to "facet search" the query
      const currentFilters = filter ?? rootState.entities.issue.$filter[projectId]
      const filterableFields = rootGetters['issueSchema/filterableFields'](projectId)
      const getQuery =
        additive
          ? (fieldName, fieldValue) => ({
            query: objectToIssueFilterQuery(mergeIssueFiltersAdditive(currentFilters, {
              [fieldName]: String(fieldValue),
            })),
          })
          : (fieldName, fieldValue) => {
            const filter = mergeIssueFilters(currentFilters, {
              [fieldName]: String(fieldValue),
            })
            return filter === EMPTY_SET
              ? EMPTY_SET
              : { query: objectToIssueFilterQuery(filter) }
          }

      // 2. some helpers to commit server response
      const extractValue = R.path(['value', 'query'])
      const commitCounter = (counter, fieldName, fieldValue = null) =>
        IssueCounter.setCounter({
          projectId,
          filters: currentFilters,
          fieldName,
          fieldValue,
          counter,
          additive,
        })
      const getOnSuccessCb = (fieldName, fieldValue = null) =>
        cardTemplate =>
          commitCounter(extractValue(cardTemplate), fieldName, fieldValue)

      // 3. setup helper to fetch card drafts (for counters) with cancellation support
      const getDraft = (query, operationId) => {
        // We always want to get new cancel token even for EMPTY_SET
        // at least to cancel the previous request with this operationId
        return withCancelToken(
          cancelToken => query === EMPTY_SET
            ? Promise.resolve({ value: { query: 0 } })
            : dispatch('entities/dashboardCard/$validate', {
              dashboardId: projectId,
              cardType: CARD_TYPE.counter,
              query,
              cancelToken: cancellable ? cancelToken : undefined,
            }, { root: true }),
          operationId,
        )
      }

      const promises = [
        // 4. Fire a request for current filters
        getDraft({ query: objectToIssueFilterQuery(currentFilters) }, 'IssueCounter-$countCommon-total')
          .then(getOnSuccessCb('total')),
        // 5. Fire a request for each issue status in the lookup
        ...Object.keys(statusLookup)
          .map(status =>
            getDraft(
              getQuery('status', status),
              `IssueCounter-$countCommon-status-${status}`,
            )
              .then(getOnSuccessCb('status', status))),
        // 6. fire a request for each score filter
        // XPROD here is a cartesian product [SCORE-FIELD X SCORE]
        ...R.xprod(['totalScore', 'criticalityScore', 'probabilityScore'], SCORE)
          .map(([scoreFieldName, score]) =>
            getDraft(
              getQuery(scoreFieldName, score.value),
              `IssueCounter-$countCommon-${scoreFieldName}-${score.value}`,
            )
              .then(getOnSuccessCb(scoreFieldName, score.value))),
        // 7. fire a request for overdue = true/false/no-sla
        ...['true', 'false', 'no-sla']
          .map(overdue => getDraft(
            getQuery('overdue', overdue),
            `IssueCounter-$countCommon-overdue-${overdue}`,
          )
            .then(getOnSuccessCb('overdue', overdue))),
        // 8. fire a request for isCompleted = true/false
        ...[true, false]
          .map(isCompleted => getDraft(
            getQuery('isCompleted', isCompleted),
            `IssueCounter-$countCommon-isCompleted-${isCompleted}`,
          )
            .then(getOnSuccessCb('isCompleted', isCompleted))),
        // 9. fire a request for each enum and boolean field
        ...R.pipe(
          // scores are disabled until task#1935
          R.filter(field =>
            !['totalScore', 'probabilityScore', 'criticalityScore']
              .includes(field.name)),
          R.map(field => {
            const optValues = field.type === ISSUE_FIELD_TYPE.BOOLEAN
              ? ['true', 'false']
              : R.map(R.prop('value'), field.allowedValues)
            return optValues.map(optValue =>
              getDraft(
                getQuery(field.name, optValue),
                `IssueCounter-$countCommon-${field.name}-${optValue}`,
              )
                // tmp to test regardless of errors
                // .catch(() => ({ value: { query: 42 } }))
                .then(getOnSuccessCb(field.name, optValue)),
            )
          }),
          R.unnest,
        )(filterableFields),
      ]

      try {
        await Promise.all(promises)
      } catch (e) {
        return handleError(ctx, e)
      }
    },

    async $countIp(ctx, { projectId, ip, filter = null, additive = true }) {
      const { rootState, dispatch } = ctx

      // 1. some helpers to "facet search" the query
      const currentFilters = filter ?? rootState.entities.issue.$filter[projectId]
      const getQuery =
        additive
          ? () => ({
            query: objectToIssueFilterQuery(mergeIssueFiltersAdditive(currentFilters, {
              ips: ip,
            })),
          })
          : () => {
            const filter = mergeIssueFilters(currentFilters, {
              ips: ip,
            })
            return filter === EMPTY_SET
              ? EMPTY_SET
              : { query: objectToIssueFilterQuery(filter) }
          }

      // 2. some helpers to commit server response
      const extractValue = R.path(['value', 'query'])
      const commitCounter = counter =>
        IssueCounter.setCounter({
          projectId,
          filters: currentFilters,
          fieldName: 'ips',
          fieldValue: ip,
          counter,
          additive,
        })
      const onSuccess = cardTemplate =>
        commitCounter(extractValue(cardTemplate))

      // 3. setup helper to fetch card drafts (for counter) with cancellation support
      const getDraft = (query) => {
        // We always want to get new cancel token even for EMPTY_SET
        // at least to cancel the previous request with this operationId
        return withCancelToken(
          cancelToken =>
            query === EMPTY_SET
              ? Promise.resolve({ value: { query: 0 } })
              : dispatch('entities/dashboardCard/$validate', {
                dashboardId: projectId,
                cardType: CARD_TYPE.counter,
                query,
                cancelToken,
              }, { root: true }),
          `IssueCounter-$countIp-${ip}`,
        )
      }

      // 4. get the counter value
      return getDraft(getQuery())
        .then(onSuccess)
        .catch(e => handleError(ctx, e))
    },

    async $countHostname(ctx, { projectId, hostname, filter = null, additive = true }) {
      const { rootState, dispatch } = ctx

      // 1. some helpers to "facet search" the query
      const currentFilters = filter ?? rootState.entities.issue.$filter[projectId]
      const getQuery =
        additive
          ? () => ({
            query: objectToIssueFilterQuery(mergeIssueFiltersAdditive(currentFilters, {
              hostnames: hostname,
            })),
          })
          : () => {
            const filter = mergeIssueFilters(currentFilters, {
              hostnames: hostname,
            })
            return filter === EMPTY_SET
              ? EMPTY_SET
              : { query: objectToIssueFilterQuery(filter) }
          }

      // 2. some helpers to commit server response
      const extractValue = R.path(['value', 'query'])
      const commitCounter = counter =>
        IssueCounter.setCounter({
          projectId,
          filters: currentFilters,
          fieldName: 'hostnames',
          fieldValue: hostname,
          counter,
          additive,
        })
      const onSuccess = cardTemplate =>
        commitCounter(extractValue(cardTemplate))

      // 3. setup helper to fetch card drafts (for counter) with cancellation support
      const getDraft = (query) => {
        // We always want to get new cancel token even for EMPTY_SET
        // at least to cancel the previous request with this operationId
        return withCancelToken(
          cancelToken =>
            query === EMPTY_SET
              ? Promise.resolve({ value: { query: 0 } })
              : dispatch('entities/dashboardCard/$validate', {
                dashboardId: projectId,
                cardType: CARD_TYPE.counter,
                query,
                cancelToken,
              }, { root: true }),
          `IssueCounter-$countHostname-${hostname}`,
        )
      }

      // 4. get the counter value
      return getDraft(getQuery())
        .then(onSuccess)
        .catch(e => handleError(ctx, e))
    },

    async $countPort(ctx, { projectId, port, filter = null, additive = true }) {
      const { rootState, dispatch } = ctx

      // 1. some helpers to "facet search" the query
      const currentFilters = filter ?? rootState.entities.issue.$filter[projectId]
      const getQuery =
        additive
          ? () => ({
            query: objectToIssueFilterQuery(mergeIssueFiltersAdditive(currentFilters, {
              ports: port,
            })),
          })
          : () => {
            const filter = mergeIssueFilters(currentFilters, {
              ports: port,
            })
            return filter === EMPTY_SET
              ? EMPTY_SET
              : { query: objectToIssueFilterQuery(filter) }
          }

      // 2. some helpers to commit server response
      const extractValue = R.path(['value', 'query'])
      const commitCounter = counter =>
        IssueCounter.setCounter({
          projectId,
          filters: currentFilters,
          fieldName: 'ports',
          fieldValue: port,
          counter,
          additive,
        })
      const onSuccess = cardTemplate =>
        commitCounter(extractValue(cardTemplate))

      // 3. setup helper to fetch card drafts (for counter) with cancellation support
      const getDraft = (query) => {
        // We always want to get new cancel token even for EMPTY_SET
        // at least to cancel the previous request with this operationId
        return withCancelToken(
          cancelToken =>
            query === EMPTY_SET
              ? Promise.resolve({ value: { query: 0 } })
              : dispatch('entities/dashboardCard/$validate', {
                dashboardId: projectId,
                cardType: CARD_TYPE.counter,
                query,
                cancelToken,
              }, { root: true }),
          `IssueCounter-$countPort-${port}`,
        )
      }

      // 4. get the counter value
      return getDraft(getQuery())
        .then(onSuccess)
        .catch(e => handleError(ctx, e))
    },
  },
}

export default IssueCounter
