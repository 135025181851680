<template>
  <canvas
    class="AppQrCode"
    :height="width"
    :width="width"
    :style="{
      width: `${width}px`,
      height: `${width}px`,
    }"
  />
</template>

<script>
import QrCode from 'qrcode'

export default {
  name: 'AppQrCode',

  props: {
    value: { type: String, required: true },
    sm: { type: Boolean, default: false },

    // lib options: https://www.npmjs.com/package/qrcode#qr-code-options
    errorCorrectionLevel: {
      type: String,
      default: 'L', // lib default: 'M' (medium), set higher if hard to scan
      validator: v => ['L', 'M', 'Q', 'H'].includes(v),
    },
    margin: {
      type: Number,
      default: 0, // lib default: 4
      validator: v => v >= 0,
    },
  },

  computed: {
    width() {
      const { sm } = this
      return sm ? 69 : 122 // from figma
    },

    options() {
      return {
        errorCorrectionLevel: this.errorCorrectionLevel,
        margin: this.margin,
        width: this.width,
      }
    },

    redrawDeepWatch() {
      const { value, options } = this
      return { value, options }
    },
  },

  watch: {
    redrawDeepWatch: {
      handler: 'genQr',
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async genQr() {
      await this.$nextTick()
      try {
        await QrCode.toCanvas(this.$el, this.value, this.options)
      } catch (err) {
        this.$store.commit('$snackbar/setMessage', { message: 'Error creating QR-code :(' })
        console.error('Error creating QR-code', err)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.AppCodeField
  transition: all 200ms ease
</style>
