import { Model } from '@vuex-orm/core'

export class SlaSettings extends Model {
  static entity = 'slaSettings'
  static primaryKey = '_id'

  static fields() {
    return {
      _id: this.string(null).nullable(),
      SLADaysDefault: this.number(null).nullable(),
      SLADaysInfo: this.number(null).nullable(),
      SLADaysLow: this.number(null).nullable(),
      SLADaysMedium: this.number(null).nullable(),
      SLADaysHigh: this.number(null).nullable(),
      SLADaysCritical: this.number(null).nullable(),
    }
  }
}
export default SlaSettings
