import axios from 'axios'
import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

export const sentry = process.env.NODE_ENV === 'test'
  ? Promise.resolve({ captureException: console.error.bind(console) })
  : axios
    .get('/ext/sentry.json')
    .then(response => {
      const { dsn, tags } = response.data

      if (!dsn) {
        throw new Error('"dsn" is not specified in sentry.json')
      }

      const vueIntegration = new VueIntegration({
        Vue,
        attachProps: true,
        logError: true,
      })
      Sentry.init({
        dsn,
        integrations: [vueIntegration],
        debug: process.env.NODE_ENV === 'development',
        release: `frigate@${process.env.VUE_APP_VERSION || 'unknown'}`,
      })
      if (tags) {
        Object
          .entries(tags)
          .forEach(([tag, value]) => Sentry.setTag(tag, value))
      }
      return Sentry
    })
    .catch(e => {
      console.warn('Sentry is not available, reason:\n', e)
      return null // the promise is resolved with null if sentry is unavailable
    })
export default sentry
