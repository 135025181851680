<template>
  <v-dialog
    class="Auth"
    :value="isOwnRoute"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    persistent
    :max-width="!register && demoUsers && demoUsers.length ? 800 : 586"
  >
    <v-card class="Auth__card">
      <aside
        v-show="$vuetify.breakpoint.smAndUp"
        class="Auth__banner secondary"
      />

      <form
        v-if="view === VIEW.userDataForm"
        class="Auth__user-data-form"
        @submit.prevent="validateAndSubmit()"
      >
        <v-img
          :src="'/public/logotypes/logo.svg'"
          :width="200"
          :height="55"
          class="my-4"
        />

        <template v-if="register">
          <AppTextField
            key="first-name"
            v-model="form.firstName"
            :label="$t('user.FirstName')"
            filled
            name="first-name"
            :disabled="submitting"
            :error-messages="getErrors('firstName')"
            margins-with-hidden-details="mb-6"
          />
          <AppTextField
            key="last-name"
            v-model="form.lastName"
            :label="$t('user.LastName')"
            filled
            name="last-name"
            :disabled="submitting"
            :error-messages="getErrors('lastName')"
            margins-with-hidden-details="mb-6"
          />
          <AppTextField
            key="email"
            v-model="form.userEmail"
            :label="$t('user.Email')"
            filled
            type="email"
            name="email"
            :error-messages="getErrors('userEmail')"
            margins-with-hidden-details="mb-6"
          />
        </template>

        <AppTextField
          key="login"
          v-model="form.userLogin"
          :label="authType === AUTH.LDAP ? `${$t('user.LdapLogin')}` : `${$t('user.Login')}`"
          filled
          type="text"
          name="login"
          :disabled="submitting"
          :error-messages="getErrors('userLogin')"
          margins-with-hidden-details="mb-6"
        />

        <AppTextField
          key="password"
          v-model="form.userPassword"
          :label="$t('user.Password')"
          filled
          :type="previewPassword ? 'text': 'password'"
          name="password"
          :disabled="submitting"
          :error-messages="getErrors('userPassword')"
          margins-with-hidden-details="mb-10"
          margins-with-details="mb-2"
        >
          <template #append>
            <v-btn
              icon
              @click="previewPassword = !previewPassword"
            >
              <v-icon
                v-text="previewPassword ? '$eye-close' : '$eye'"
              />
            </v-btn>
          </template>
        </AppTextField>

        <div class="Auth__fieldset">
          <AppSelect
            v-if="!register && appLanguages.length > 1"
            class="Auth__language"
            :value="appLanguage"
            dense
            type="text"
            autocomplete="off"
            :items="appLanguages"
            item-value="name"
            item-text="displayName"
            :error-messages="[]"
            :disabled="submitting"
            @change="setLanguage"
          >
            <template #item="{ item: language }">
              {{ language.displayName }}
            </template>
            <template #selection="{ item: language }">
              {{ language.displayName }}
            </template>
          </AppSelect>
        </div>

        <section class="Auth__actions">
          <!-- form actions -->
          <v-btn
            type="submit"
            depressed
            color="primary"
            class="Auth__submit-btn"
            :loading="submitting"
          >
            {{ register ? `${$t('user.Register')}` : `${$t('user.SignIn')}` }}
          </v-btn>
          <v-btn
            v-show="authType !== AUTH.LDAP && !isRegisterFormActive"
            text
            :to="{ name: register ? 'Auth' : 'Register', query: { next } }"
            :disabled="submitting"
            class="Auth__toggle-register-btn ml-4"
            @click.native.stop
          >
            {{ register ? `${$t('user.SignIn')}` : `${$t('user.Register')}` }}
          </v-btn>
        </section>
      </form>

      <form
        v-else-if="view === VIEW.mfaTokenForm"
        class="Auth__mfa-token-form"
      >
        <h1 class="Auth__title-2">
          {{ $t('user.SecurityCode') }}
        </h1>

        <p class="Auth__paragraph">
          {{ $t('user.CheckMfaM') }}
        </p>

        <div
          class="d-flex justify-center"
          style="margin-top: 51px; margin-bottom: 138px"
        >
          <AppCodeField
            ref="codeField"
            class="flex-shrink-1 flex-grow-0"
            :value="form.mfaToken"
            :disabled="submitting"
            :loading="submitting"
            hide-details
            @input="onMfaTokenInput"
          />
        </div>

        <a
          href="#"
          class="Auth__link text-center"
          @click.prevent="view = VIEW.userDataForm"
        >
          {{ $t('user.SignIn') }}
        </a>
      </form>

      <AuthDemoUsers
        v-if="!register"
        class="Auth__demo-users"
        @click:user="(user) => {
          form.userLogin = user.userLogin;
          form.userPassword = user.userPassword;
        }"
      />

      <MfaSetupDialog
        :value="mfaSetupDialog"
        :user-login="form.userLogin"
        :user-password="form.userPassword"
        v-bind="mfaSetupDialogState"
        @success="$mfaPromiseResolve()"
        @input="!$event && (mfaSetupDialog = false, $mfaPromiseReject())"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { required, requiredIf, email } from 'vuelidate/lib/validators'

import { AUTH } from '../constants'
import { pushRoute, replaceRoute } from '../helpers'

import AuthDemoUsers from '../components/AuthDemoUsers'
import MfaSetupDialog from '../components/MfaSetupDialog'

const OWN_ROUTES = ['Auth', 'Register']

// Possible views of the window
export const VIEW = Object.freeze({
  // Form with login and password (+ name fields for registration)
  userDataForm: 'userDataForm',
  // Form to submit one-use code (optional 2nd step when signing in)
  mfaTokenForm: 'mfaTokenForm',
})

const defaultForm = () => ({
  firstName: '',
  lastName: '',
  userEmail: '',
  userLogin: '',
  userPassword: '',
  mfaToken: '',
})

const isRegisterForm = function() { return this.register }

const formValidation = {
  userEmail: { email },
  userLogin: { required },
  userPassword: { required },
  firstName: { required: requiredIf(isRegisterForm) },
  lastName: { required: requiredIf(isRegisterForm) },
}

export default {
  name: 'Auth',

  components: {
    AuthDemoUsers,
    MfaSetupDialog,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('layout.Welcome')),
    }
  },

  props: {
    register: { type: Boolean, default: false },
    next: { type: String, default: '/' },
  },

  data: () => ({
    AUTH,
    VIEW,

    form: defaultForm(),
    view: VIEW.userDataForm,
    recommendedAppsTab: null,
    formValid: true,
    previewPassword: false,
    submitting: false,
    mfaSetupDialog: false,
    mfaSetupDialogState: {
      base32: null,
      otpauthUrl: null,
    },
  }),

  validations: {
    form: formValidation,
  },

  computed: {
    validationErrors() {
      return {
        'userLogin-required': this.$t('user.validationErrors.UserLogin'),
        'userEmail-email': this.$t('user.validationErrors.UserEmail'),
        'userPassword-required': this.$t('user.validationErrors.UserPassword'),
        'firstName-required': this.$t('user.validationErrors.FirstName'),
        'lastName-required': this.$t('user.validationErrors.LastName'),
      }
    },
    demoUsers() { return this.$store.state.ext.demoUsers },

    isOwnRoute() {
      if (this.$route.name === 'Register' && this.isRegisterFormActive) return false

      return OWN_ROUTES.includes(this.$route.name)
    },

    currentUser() { return this.$store.getters['user/current'] },

    isRegisterFormActive() {
      const envIntegration = this.$store.getters['ext/REGISTRATION_ENABLED']?.toString()?.toLowerCase()

      // fixme: WTF? Why named integration? Logic seems to be inverse
      if (envIntegration && (envIntegration === 'off' || envIntegration === 'false')) return true
      return false
    },

    registerForm() {
      const { userLogin, userEmail, userPassword, firstName, lastName } = this.form
      return {
        userLogin,
        userPassword,
        userEmail: userEmail || null,
        firstName,
        lastName,
      }
    },
    signInForm() {
      const { form, view } = this
      const { userLogin, userPassword, mfaToken } = form
      const signInForm = { userLogin, userPassword }
      if (view === VIEW.mfaTokenForm) signInForm.mfaToken = mfaToken
      return signInForm
    },

    firstError() {
      const fields = ['firstName', 'lastName', 'userEmail', 'userLogin', 'userPassword']
      for (const field of fields) {
        const errors = this.getErrors(field)
        if (errors.length) return errors[0]
      }
      return null
    },

    authType() { return this.$store.state.service.serverInfo?.authenticationMethod },

    appLanguages() {
      return this.$store.getters['ext/GET']?.product.languages || []
    },
    appLanguage() {
      const selectedLocale = this.$store.state.ext.selectedLocale
      return this.$store.state.ext.environment.product?.languages.find(lang => lang.name === selectedLocale)
    },
  },

  watch: {
    $route(route, previousRoute) {
      // `<keep-alive />`, another route
      if (!this.isOwnRoute) return
      this.view = VIEW.userDataForm

      this.$store.dispatch('service/getServerInfo')

      if (!previousRoute || !OWN_ROUTES.includes(previousRoute.name)) {
        // preserve form when toggling /auth <-> /register,
        // reset to default form otherwise
        this.form = defaultForm()
      }

      this.previewPassword = false
      this.$nextTick(() => {
        // reset validation and clear snackbar on route change
        this.$v && this.$v.$reset()
        this.$store.commit('$snackbar/hide')
      })
    },

    // if we're already logged in, then immediately go to `next`
    currentUser: {
      handler(currentUser) {
        if (!this.isOwnRoute) return
        if (currentUser) {
          const route = { path: this.next }
          if (currentUser?.passwordChangeRequired) route.query = { changePassword: true }
          pushRoute(this.$router, route)
        }
      },
      immediate: true,
    },

    // if LDAP redirect /register -> /auth
    authType: {
      handler(authType) {
        if (!this.isOwnRoute) return
        if (authType !== AUTH.LDAP) return
        if (this.$route.name !== 'Register') return
        replaceRoute(this.$router, {
          name: 'Auth',
          query: { next: this.next },
        })
      },
      immediate: true,
    },

    // reset mfaToken on view change
    view() { this.form.mfaToken = '' },
  },

  created() {
    this.$store.dispatch('ext/getDemoUsers')
    this.$store.dispatch('service/getServerInfo')

    this.$mfaPromiseResolve = null
    this.$mfaPromiseReject = null
  },

  methods: {

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          this.validationErrors[`${field}-${k}`] ||
          `${this.$t('user.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async validateAndSubmit() {
      const { register } = this

      this.$v.$touch() // highlight errors
      try {
        this.submitting = true
        if (!this.$v.$error) {
          this.$store.commit('$snackbar/setMessage', { message: null })
          if (register) {
            await this.doRegister()
            await this.onRegistered()
          } else {
            const { requestMfaToken, base32, otpauthUrl } = await this.doSignIn()
            if (requestMfaToken) {
              if (!base32 || !otpauthUrl) this.view = VIEW.mfaTokenForm
              else {
                this.mfaSetupDialogState = { base32, otpauthUrl }
                this.mfaSetupDialog = true
                await new Promise((resolve, reject) => {
                  this.$mfaPromiseResolve = resolve
                  this.$mfaPromiseReject = reject
                })
                this.mfaSetupDialogState = false
              }
            }
          }
        } else {
          this.$store.commit('$snackbar/setMessage', {
            message: this.firstError || this.$t('user.fixErrorsM'),
          })
        }
      } catch (e) {
        console.error(e)

        if (this.view === VIEW.mfaTokenForm) {
          this.form.mfaToken = ''
          this.$nextTick(() => this.$refs.codeField.focus())
        }
      } finally {
        this.submitting = false
      }
    },

    async doSignIn() {
      const { $store, signInForm: form } = this
      return await $store.dispatch('user/signIn', form)
    },

    async doRegister() {
      const { $store, registerForm: form } = this
      await $store.dispatch('user/register', { form, signIn: false })
    },

    onMfaTokenInput(mfaToken) {
      this.form.mfaToken = mfaToken || ''
      if (mfaToken) this.validateAndSubmit()
    },

    async onRegistered() {
      const { $store, $router, next, form: { userLogin, userPassword } } = this
      await $router.push({
        name: 'Auth',
        query: { next },
      })

      await this.$nextTick()
      this.form.userLogin = userLogin
      this.form.userPassword = userPassword

      await this.$nextTick()
      $store.commit('$snackbar/setMessage', {
        message: this.$t('user.SuccessfullyRegisteredM'),
      })
    },

    setLanguage(lang) {
      this.$store.dispatch('ext/getLanguage', { locale: lang })
    },
  },
}
</script>

<style lang="sass" scoped>
  .Auth
    &__card
      display: flex
      flex-direction: row

    &__banner
      display: block
      flex: 0 0 136px
      border-top-right-radius: 0 !important
      background-image: url('../assets/backgrounds/triangles-down.png')
      background-repeat: repeat

    &__user-data-form
      flex-grow: 1
      padding: 64px 72px 80px

    &__fieldset
      border: none
      margin-top: 16px
      display: flex
      justify-content: space-between

    &__language
      max-width: 150px

    &__mfa-token-form
      flex-grow: 1
      padding: 187px 78px 62px
      text-align: center

    &__title
      margin-bottom: 48px

      font-weight: 500
      font-size: 36px
      line-height: 44px

    &__title-2
      margin-bottom: 18px

      font-weight: 500
      font-size: 20px
      line-height: 130%
      letter-spacing: 0.015em

    &__paragraph
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.25px
      color: #333

    &__link
      text-decoration: none
      font-size: 13px
      line-height: 24px

      &:hover, &:active, &:focus
        text-decoration: underline

    &__actions
      padding-top: 40px

    &__demo-users
      border-left: 1px solid #ccc
      border-bottom-left-radius: 0 !important
</style>
