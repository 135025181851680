import { Model } from '@vuex-orm/core'

import { Issue, IssueComment } from './_models'

export class IssueExportRecord extends Model {
  static entity = 'issueExportRecord'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.string(null).nullable(),
      integrationExists: this.boolean(null).nullable(),
      integrationID: this.string(null).nullable(),
      integrationType: this.string(null).nullable(),
      metaData: this.attr({}),
      created: this.string(null).nullable(),

      issueId: this.string(null).nullable(), // client-side
      issue: this.belongsTo(Issue, 'issueId'),

      comments: this.hasMany(IssueComment, 'exportRecordID'),
    }
  }
}

export default IssueExportRecord
