import { Model } from '@vuex-orm/core'
import * as R from 'ramda'

import { DashboardService, axiosInstance as axios } from '../../api'
import { handleError } from '../../helpers'
import { DashboardCard } from './_models'
import i18n from '../../i18n'

export class Dashboard extends Model {
  static GLOBAL_ID = 'global'

  static entity = 'dashboard'
  static primaryKey = 'id'

  static fields() {
    return {
      // Dashboard don't have id per se, we construct it on the client:
      // * global dashboard uses GLOBAL_ID ('global')
      // * per-project dashboards use project id
      id: this.string(null).nullable(),
      cards: this.hasMany(DashboardCard, 'dashboardId'),
      metaData: this.attr({}),
    }
  }

  get projectId() {
    return this.id === Dashboard.GLOBAL_ID
      ? null
      : this.id
  }

  get orderedCards() {
    return R.sortWith([
      R.ascend(R.path(['metaData', 'position'])),
      R.ascend(R.prop('id')),
    ])(this.cards)
  }
}

export const DashboardModule = {
  actions: {
    // in card actions: id is either:
    // * Dashboard.GLOBAL_ID (global aka cross-project dashboard)
    // * or project uuid (in-project dashboard)

    async $get(ctx, { id = Dashboard.GLOBAL_ID, reload = true } = {}) {
      if (!reload && Dashboard.query().whereId(Dashboard.GLOBAL_ID).exists()) return
      try {
        const dashboard = id === Dashboard.GLOBAL_ID
          ? await DashboardService.dashboardGet()
          : await DashboardService.projectDashboardGet({ projectId: id })
        dashboard.id = id
        const cardIds = new Set(dashboard.cards.map(c => c.id))
        await DashboardCard.delete(c => c.dashboardId === id && !cardIds.has(c.id))
        await Dashboard.insert({ data: dashboard })
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedLoadingDashboardM'))
      }
    },

    // works OK only with checklist cards in projects,
    // will probably change in future or will be removed altogether
    async $getForProjectWithValues(ctx, { projectId, ids = undefined, types = undefined }) {
      try {
        const dashboard = await axios.get(`/projects/${projectId}/dashboard/cards/`, {
          params: {
            ids,
            types,
          },
        })
        dashboard.id = projectId
        await Dashboard.insert({ data: dashboard })
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedLoadingProjectDashboardM'))
      }
    },

    async $update(ctx, { id = Dashboard.GLOBAL_ID, dashboard, reloadDashboard = true }) {
      const { dispatch } = ctx
      if (dashboard?.id && dashboard.id !== id) throw new Error(i18n.t('dashboard.ErrorDashboardIdMismatchM'))
      const dashboardPayload = R.omit(['id'], dashboard)
      try {
        if (id === Dashboard.GLOBAL_ID) {
          await DashboardService.dashboardPatch({ body: dashboardPayload })
        } else {
          await DashboardService.projectDashboardPatch({ projectId: id, body: dashboardPayload })
        }
        if (reloadDashboard) {
          await dispatch('$get', { id, reload: true })
        }
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedSavingDashboardM'))
      }
    },
  },
}

export default Dashboard
