<template>
  <v-app
    class="DefaultLayout"
    :class="{ 'DefaultLayout--disable': ['Auth', 'Register'].includes($route.name) }"
  >
    <!--router-view name="appBar" /-->

    <!-- dark right drawer for issue filters, etc. -->
    <v-navigation-drawer
      v-click-outside="onClickOutsideDrawer"
      class="DefaultLayout__app-drawer"
      :value="!!$route.meta.appDrawer || notificationDrawer"
      :mini-variant="miniDrawer && !notificationDrawer"
      :mini-variant-width="64"
      :app="$vuetify.breakpoint.lgAndUp || (!notificationDrawer && miniDrawer)"
      :fixed="$vuetify.breakpoint.mdAndDown && !(!notificationDrawer && miniDrawer)"
      :class="{ 'DefaultLayout__app-drawer--mini': !notificationDrawer && miniDrawer }"
      right
      :stateless="!notificationDrawer"
      color="darkBackground"
      width="360"
      dark
      @input="notificationDrawer && !$event && (notificationDrawer = false)"
    >
      <router-view
        name="appDrawer"
      />
    </v-navigation-drawer>

    <TheNavigationDrawer :project-id="$route.params.projectId || null" />

    <!-- dark left drawer for settings and navigation -->
    <!--v-navigation-drawer
      v-show="!$route.meta.public"
      v-model="settingsDrawer"
      class="DefaultLayout__settings-drawer"
      :app="$vuetify.breakpoint.lgAndUp"
      :fixed="$vuetify.breakpoint.mdAndDown"
      left
      color="darkBackground"
      width="260"
      dark
    >
      <router-view name="settingsDrawer" />
    </v-navigation-drawer-->

    <!-- white right drawer for notifications and notifications settings -->
    <v-navigation-drawer
      v-model="settingsDrawer"
      class="DefaultLayout__notification-drawer"
      :app="$vuetify.breakpoint.lgAndUp && settingsDrawer"
      right
      width="550"
      fixed
      temporary
      :hide-overlay="true"
    >
      <NotificationDrawer />
    </v-navigation-drawer>

    <router-view name="outsideMain" />

    <v-main
      class="DefaultLayout__content"
    >
      <LicenseStatusBar
        v-if="licenseExpiresSoonOrHasExpired && showLicenseStatusBar"
        :start-date="currentLicense.startDate"
        :end-date="currentLicense.endDate"
        @close="close"
      />

      <!-- keep `IssueList` alive on `$route` change;
        it is required to restore previous scroll position -->
      <!-- also keep 'Auth' so that modal can remove
        its backdrop on being deactivated -->
      <keep-alive
        :include="[
          'IssueList',
          'CardIssueList',
          'ProjectIssueList',
          'ProjectCardIssueList',
          'Auth',
        ]"
      >
        <router-view />
      </keep-alive>

      <TheSnackbar />
      <TheAjaxBar
        class="DefaultLayout__loader"
        :class="{
          'DefaultLayout__loader--push-top':
            ['Auth', 'Register'].includes($route.name) ||
            $store.state.defaultLayout.pushAjaxLoaderTop,
        }"
      />
    </v-main>

    <DialogRoot />

    <LicenseDialog />
  </v-app>
</template>

<script>
import { TheSnackbar } from '@hexway/shared-front'
import moment from 'moment'

import DialogRoot from '../components/DialogRoot'
import LicenseStatusBar from '../components/LicenseStatusBar'
import LicenseDialog from '../components/LicenseDialog'
import NotificationDrawer from '../components/NotificationDrawer'
import TheAjaxBar from '../components/TheAjaxBar'
import TheNavigationDrawer from '../components/TheNavigationDrawer'

export default {
  name: 'DefaultLayout',

  components: {
    DialogRoot,
    NotificationDrawer,
    TheAjaxBar,
    TheNavigationDrawer,
    TheSnackbar,
    LicenseStatusBar,
    LicenseDialog,
  },

  data() {
    return {
      showLicenseStatusBar: localStorage.getItem('DefaultLayout/showLicenseStatusBar') !== 'false',
    }
  },

  computed: {
    currentLicense() { return this.$store.state.license.current },

    currentUser() { return this.$store.getters['user/current'] },

    licenseHasExpired() {
      return this.$store.getters['license/isExpired']
    },

    licenseExpiresSoon() {
      return this.$store.getters['license/expiresSoon']
    },

    licenseExpiresSoonOrHasExpired() {
      const { licenseHasExpired, licenseExpiresSoon } = this
      return licenseHasExpired || licenseExpiresSoon
    },

    miniDrawer: {
      get() { return this.$store.state.appDrawer.miniDrawer },
      set(miniDrawer) {
        this.$store.commit('appDrawer/setMiniDrawer', miniDrawer)
      },
    },

    settingsDrawer: {
      get() { return this.$store.state.appDrawer.settingsDrawer },
      set(settingsDrawer) {
        this.$store.commit('appDrawer/setSettingsDrawer', settingsDrawer)
      },
    },

    notificationDrawer: {
      get() { return this.$store.state.appDrawer.notificationDrawer },
      set(notificationDrawer) {
        this.$store.commit('appDrawer/setNotificationDrawer', notificationDrawer)
      },
    },
  },

  watch: {
    '$route.path': {
      handler() {
        if (this.currentUser) {
          this.fetchLicense()
          // this.$store.dispatch('ext/getEnvironment')
        }
      },
      immediate: true,
    },

    // bugfix-4062
    // currentUser: {
    //   handler(user) {
    //     if (user) this.fetchProjects()
    //   },
    //   immediate: true,
    // },

    '$vuetify.breakpoint.lgAndUp': {
      handler(lgAndUp) {
        this.miniDrawer = !!this.$route.meta.appDrawer && !lgAndUp
      },
      immediate: true,
    },
    '$vuetify.breakpoint.mdAndUp': {
      handler(mdAndUp) {
        // adjust `scroll-padding-top` for better scrolling, mainly for cypress
        // NB! may not work on Safari https://webkit.org/b/179379
        // https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-padding-top
        document.body.parentElement.style.scrollPaddingTop =
          mdAndUp ? '64px' : '56px' // size of application bar
      },
      immediate: true,
    },

    '$route.name'() { this.notificationDrawer = false },

    licenseExpiresSoonOrHasExpired(expires, wasAboutToExpire) {
      if (wasAboutToExpire === false && expires === true) {
        this.showLicenseStatusBar = true
      }
    },

    showLicenseStatusBar(show) {
      localStorage.setItem('DefaultLayout/showLicenseStatusBar', JSON.stringify(!!show))
    },
  },

  created() {
    this.$store.dispatch('ext/getEnvironment')
  },

  methods: {
    onClickOutsideDrawer() {
      if (this.$vuetify.breakpoint.mdAndDown && !this.miniDrawer) {
        this.miniDrawer = true
      }
    },

    // bugfix-4062
    // fetchProjects() {
    //   return Project.dispatch('$getList', { reload: false })
    // },

    fetchLicense() {
      return this.$store.dispatch('license/get')
    },

    close() {
      localStorage.setItem('LicenseStatusBar', JSON.stringify({ showLicenseStatusBar: false }))
      this.showLicenseStatusBar = false
    },

    moment: function() {
      return moment()
    },
  },
}
</script>

<style lang="sass" scoped>
  @import '../scss/variables'

  .DefaultLayout
    &__content
      background: #fafafc
      position: relative

    & &__loader
      position: fixed
      top: 56px
      left: 0
      right: 0
      z-index: 5

      @media #{map-get($display-breakpoints, 'md-and-up')}
        top: 64px

      &--push-top
        top: 0 !important
        z-index: 201

  .DefaultLayout__app-drawer
    box-shadow: -3px 4px 4px rgba(0, 0, 0, .12), -4px 0 4px rgba(0, 0, 0, .05)

    &--mini
      &, & ::v-deep > *
        overflow: hidden
</style>
