import { Model } from '@vuex-orm/core'
import moment from 'moment'

import { SlaService } from '../../../gen'
import { SCORE_INFO, SCORE_LOW, SCORE_HIGH, SCORE_MEDIUM, SCORE_CRITICAL } from '../../constants'
import { handleError } from '../../helpers'
import { SlaSettings } from './_models'
import i18n from '../../i18n'

export class SlaConfig extends Model {
  static GLOBAL_ID = 'global' // fake id for global config
  // Note: per-project configs use project id as an identifier
  // Note: these are client-side ids only

  static entity = 'slaConfig'
  static primaryKey = '_id'

  static fields() {
    return {
      _id: this.string(null).nullable(),
      actual: this.belongsTo(SlaSettings, 'actualId'),
      default: this.belongsTo(SlaSettings, 'defaultId'),
    }
  }

  get actualId() { return `actual:${this._id}` }
  get defaultId() { return `default:${this._id}` }

  get SLADaysDefault() { return this.actual.SLADaysDefault ?? this.default.SLADaysDefault }
  get SLADaysInfo() { return this.actual.SLADaysInfo ?? this.default.SLADaysInfo }
  get SLADaysLow() { return this.actual.SLADaysLow ?? this.default.SLADaysLow }
  get SLADaysMedium() { return this.actual.SLADaysMedium ?? this.default.SLADaysMedium }
  get SLADaysHigh() { return this.actual.SLADaysHigh ?? this.default.SLADaysHigh }
  get SLADaysCritical() { return this.actual.SLADaysCritical ?? this.default.SLADaysCritical }

  static getGlobal() { return this.getters('$global') }

  getDateForScore(score) {
    const days = (() => {
      if (score == null) return this.SLADaysDefault
      if (score === SCORE_INFO) return this.SLADaysInfo
      if (score === SCORE_LOW) return this.SLADaysLow
      if (score === SCORE_MEDIUM) return this.SLADaysMedium
      if (score === SCORE_HIGH) return this.SLADaysHigh
      if (score === SCORE_CRITICAL) return this.SLADaysCritical
      console.warn('cannot infer SLA date for invalid score: ' + score)
      return null
    })()
    if (days == null || days <= 0) return null
    return moment().add(days - 1, 'days').format('YYYY-MM-DD')
  }
}

export const SlaConfigModule = {
  getters: {
    $global: () => SlaConfig.query().withAll().find(SlaConfig.GLOBAL_ID),
  },

  actions: {
    $getGlobal: async (ctx, { reload = true } = {}) => {
      const { getters } = ctx
      if (!reload && getters.$global != null) return getters.$global
      try {
        const config = await SlaService.getGlobalSlaSetting()
        config._id = SlaConfig.GLOBAL_ID
        config.actual._id = `actual:${config._id}`
        config.default._id = `default:${config._id}`
        await SlaConfig.insert({ data: config })
        return getters.$global
      } catch (e) {
        await handleError(ctx, e, i18n.t('sla.ErrorFetchingGlobalSlaSettings'))
      }
    },

    $setGlobal: async (ctx, { slaSettings, recalculateSLAForIssues, errorSnackbarArgs }) => {
      const { dispatch } = ctx
      try {
        await SlaService.postGlobalSlaSetting({
          body: { ...slaSettings, recalculateSLAForIssues },
        })
        await dispatch('$getGlobal', { reload: true })
      } catch (e) {
        await handleError(ctx, e, i18n.t('sla.ErrorSettingGlobalSlaSettings'), errorSnackbarArgs)
      }
    },

    $getForProject: async (ctx, { projectId, reload = true }) => {
      if (!reload && SlaConfig.find(projectId) != null) return SlaConfig.query().withAll().find(projectId)
      try {
        const config = await SlaService.getProjectSlaSetting({ projectId })
        config._id = projectId
        config.actual._id = `actual:${config._id}`
        config.default._id = `default:${config._id}`
        await SlaConfig.insert({ data: config })
        return SlaConfig.find(projectId)
      } catch (e) {
        await handleError(ctx, e, i18n.t('sla.ErrorFetchingProjectSlaSettings'))
      }
    },

    $setForProject: async (ctx, { projectId, slaSettings, recalculateSLAForIssues, errorSnackbarArgs }) => {
      const { dispatch } = ctx
      try {
        await SlaService.postProjectSlaSetting({
          projectId,
          body: { ...slaSettings, recalculateSLAForIssues },
        })
        await dispatch('$getForProject', { projectId, reload: true })
      } catch (e) {
        await handleError(ctx, e, i18n.t('sla.ErrorFailedSettingProjectSlaSettings'), errorSnackbarArgs)
      }
    },

    $resetForProject: async (ctx, { projectId, errorSnackbarArgs }) => {
      const { dispatch } = ctx
      try {
        await SlaService.deleteProjectSlaSetting({ projectId })
        await dispatch('$getForProject', { projectId, reload: true })
      } catch (e) {
        await handleError(ctx, e, i18n.t('sla.ErrorFailedResettingProjectSlaSettings'), errorSnackbarArgs)
      }
    },
  },
}

export default SlaConfig
