import { NotificationsService } from '../../api'
import { NOTIFICATIONS_POLL_INTERVAL } from '../../constants'
import { handleError, notifyLocal } from '../../helpers'
import i18n from '../../i18n'

const initState = () => ({
  // Array<Notification>
  notifications: null,
})

const getters = {
  anyUnread: ({ notifications }) =>
    notifications &&
    notifications.length > 0 &&
    notifications.some(n => !n.read),

  unreadCount: ({ notifications }) =>
    notifications &&
    notifications.filter(n => !n.read).length,
}

const mutations = {
  reset: (state) => Object.assign(state, initState()),

  setList: (state, notifications) => {
    state.notifications = notifications
  },

  setRead: (state, notificationId) => {
    const notification = state.notifications
      .find(({ id }) => id === notificationId)
    if (!notification) throw new Error(`Notification ${notificationId} not found!`)
    notification.read = true
  },
}

const actions = {
  getList: ({ commit, rootGetters }) =>
    rootGetters['user/current'] != null
      ? NotificationsService.notificationsListGet()
        .then(notifications => commit('setList', notifications))
        .catch(error => handleError({ commit }, error, i18n.t('notification.ErrorFetchingNotificationsM')))
      : Promise.resolve(commit('setList', [])),

  markRead: ({ commit }, { notificationId }) =>
    NotificationsService.notificationMarkRead({ notificationId })
      .then(() => commit('setRead', notificationId))
      .catch(error => handleError({ commit }, error, i18n.t('notification.ErrorProcessingNotificationM'))),
}

export const watch = (store, notificationModule = 'notification') => {
  const unwatchUnread = store.watch(
    (_, getters) => getters[`${notificationModule}/anyUnread`],
    anyUnread => anyUnread && notifyLocal(i18n.t('notification.NewNotificationsApiaryM')),
    { immediate: true },
  )

  let pollIntervalId = setInterval(
    () =>
      pollIntervalId != null &&
        store.getters['user/current'] != null &&
        store.dispatch(`${notificationModule}/getList`),
    NOTIFICATIONS_POLL_INTERVAL,
  )
  const stopPolling = () => {
    clearInterval(pollIntervalId)
    pollIntervalId = null
  }

  return () => {
    unwatchUnread()
    stopPolling()
  }
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
