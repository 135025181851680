<template>
  <v-dialog
    v-model="isOpen"
    class="LicenseDialog"
    max-width="434"
  >
    <v-sheet
      class="LicenseDialog__card"
      color="white"
    >
      <div class="LicenseDialog__title">
        <template v-if="!state.countable && !errorMessage">
          {{ $t('license.FeatureUnavailableM') }}
        </template>

        <template v-else>
          {{ $t('license.YouReachedM') }}
          <template v-if="featureName">
            {{ $t('license.limitOf', { featureName: $t(featureName) }) }}
          </template>
          <template v-else>
            {{ $t('license.limits') }}
          </template>
        </template>
      </div>

      <div class="LicenseDialog__body">
        <template v-if="errorMessage">
          {{ errorMessage }}.
        </template>
        <template v-if="featureName">
          <template v-if="state.countable">
            {{ $t('license.AlreadyHaveMaximumM', { featureName: $t(featureName) }) }}
          </template>
          <template v-else>
            {{ $t('license.UpgradeToUseM', { featureName: $t(featureName) }) }}
          </template>
        </template>
        <br>
        {{ $t('license.EmailAtM') }}<a :href="`mailto:${$t('layout.emailContact')}`">{{ $t('layout.emailContact') }}</a>
      </div>

      <div class="LicenseDialog__footer">
        <v-btn
          color="primary"
          block
          depressed
          href="https://hexway.io/pricing/#contact-us"
          target="_blank"
        >
          {{ $t('license.ContactUsToExpand') }}
        </v-btn>
        <v-btn
          text
          tile
          plain
          color="primary"
          class="mt-1"
          @click="isOpen = false"
        >
          {{ $t('license.NotNow') }}
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: 'LicenseDialog',

  computed: {
    state() {
      return this.$store.state.license.limitDialogState
    },

    isOpen: {
      get() { return this.state.isOpen },
      set(isOpen) {
        this.$store.commit('license/setLicenseDialog', { isOpen })
      },
    },

    featureName() {
      return this.$store.state.license.limitDialogState.featureName
    },

    errorMessage() {
      return this.$store.state.license.limitDialogState.errorMessage
    },
  },
}
</script>

<style lang="sass" scoped>
.LicenseDialog
  &__card
    padding: 24px 61px
    text-align: center

  &__title
    margin-bottom: 16px
    font-size: 20px
    font-weight: 500

  &__subtitle
    margin: 16px 0 39px

  &__body
    font-size: 13px

  &__footer
    display: grid
    margin-top: 26px
</style>
