// some common fields for UI
export default {
  namespaced: true,

  state: () => ({
    pushAjaxLoaderTop: false,
    disableGlobalShortcuts: false,
  }),

  mutations: {
    setPushAjaxLoaderTop: (state, value) => {
      state.pushAjaxLoaderTop = !!value
    },
    setDisableGlobalShortcuts: (state, value) => {
      state.disableGlobalShortcuts = !!value
    },
  },
}
