import i18n from '../../i18n'

const RESOLVE = Symbol('resolve confirm dialog handle')
let idSource = Number.MIN_SAFE_INTEGER

const initState = () => ({
  dialogs: [], // Array<DialogState>
})

const getters = {}

const mutations = {
  reset: state => Object.assign(state, initState()),

  pushState: (state, { dialogState }) =>
    state.dialogs.push(dialogState),

  removeState: (state, { dialogState }) => {
    state.dialogs = state.dialogs.filter(d => d.id !== dialogState.id)
  },

  closeDialog: (_, { dialogState }) => {
    dialogState.isOpen = false
  },
}

const actions = {
  // Opens a confirmation dialog and returns Promise<Boolean>.
  // It resolves with `true` if the user has agreed
  // and resolves with `false` otherwise.
  // This promise is pending while the confirmation dialog is open
  // and is never expected to be rejected (unless programming error was made :-/ ).
  openDialog: (
    { commit },
    {
      title = i18n.t('layout.AreYouSureQ'),
      subtitle = i18n.t('layout.ConfirmIntentionsM'),
      consentLabel = i18n.t('layout.Agree'),
      consentProps = { color: 'primary', depressed: true }, // props for v-btn
      rejectLabel = i18n.t('layout.Cancel'),
      rejectProps = { color: 'primary', outlined: true }, // props for v-btn
      // customOnRejectBtnClick: (event, doReject) => {
      //   if (foo === bar) alert('Wee-eee!')
      //   doReject()
      // }
      customOnRejectBtnClick = null, // optional function performed only when button clicked, but not on closing by overlay click / esc
      maxWidth = 466,
      dataToCopy = null,
    } = {}) => {
    const dialogState = {
      id: ++idSource,
      title,
      subtitle,
      consentLabel,
      consentProps,
      rejectLabel,
      rejectProps,
      customOnRejectBtnClick,
      maxWidth,
      dataToCopy,
      isOpen: true,
    }
    const promise = new Promise((resolve) => { dialogState[RESOLVE] = resolve })
    commit('pushState', { dialogState })
    return promise
  },

  consent: ({ commit }, { dialogState }) => {
    dialogState[RESOLVE](true)
    commit('closeDialog', { dialogState })
    setTimeout(() => commit('removeState', { dialogState }), 1000)
  },

  reject: ({ commit }, { dialogState }) => {
    dialogState[RESOLVE](false)
    commit('closeDialog', { dialogState })
    setTimeout(() => commit('removeState', { dialogState }), 1000)
  },
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
