import Vue from 'vue'
import { registerCommonComponents } from '@hexway/shared-front'
import '@hexway/shared-front/src/scss/styles.sass'

// import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import './plugins/vuelidate'
import './plugins/i18n'
import './plugins/portalVue'
import './plugins/sentry'
import './plugins/vueMeta'
import './plugins/vuejsStorage'
import App from './App.vue'
import * as api from './api'
import getRouter from './router'
import getStore from './store'
import { registerI18n } from './i18n'
import registerAppComponents from './registerAppComponents'

Vue.config.productionTip = false
registerCommonComponents(Vue)
registerAppComponents(Vue)

const i18n = registerI18n()
const store = getStore({ i18n })
const router = getRouter({ store, api })

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
