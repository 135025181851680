import { Model } from '@vuex-orm/core'
import download from 'downloadjs'
import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import Vue from 'vue'

import {
  ProjectService,
  IssueService,
  axiosInstance as axios,
  EnumExportCSVMetaRequestSchemaExportType as EnumExportType,
  ExportCSVDelimiters,
  ExportCSVDialects,
  ExportCSVEncodings,
  ExportCSVQuoting,
  ExportService,
} from '../../api'
import {
  AxiosResponse,
  CARD_TYPE,
  EMPTY_SET,
  PROJECT_PERMISSION_LEVEL,
} from '../../constants'
import { handleError, splitValues, extractFilenameFromContentDisposition } from '../../helpers'
import { withCancelToken } from '../cancellableActions'
import { DashboardCard, IssueStatusChange, IssueExportRecord, SlaConfig, Project } from './_models'
import i18n from '../../i18n'

export class Issue extends Model {
  static entity = 'issue'
  static primaryKey = 'id'

  static state() {
    return {
      // Object<UUID|null, Maybe<Object>>
      // e.g.: { '0000-...': { totalScore: "1,2", ... }, '1111-...': {}, 'null': {} }
      // currently active filters for projects
      // project#0000... has 1 active filter
      // project#1111... has none
      // cross-project list (null) has none
      $filter: {},

      // Object<UUID|null, int>
      // key: Project.id, value: Number of issues in project
      $total: {},

      // visible issue order with client-side ordering
      $visibleOrder: [],
    }
  }

  static fields() {
    return {
      id: this.string(null).nullable(),
      name: this.string(''),
      projectID: this.string(null).nullable(),

      sla: this.string(null).nullable(),
      slaManualSet: this.string(null).nullable(),
      created: this.string(null).nullable(),
      completed: this.string(null).nullable(),

      status: this.string(null).nullable(),
      criticalityScore: this.number(null).nullable(),
      probabilityScore: this.number(null).nullable(),
      totalScore: this.number(null).nullable(),
      cvssScore: this.number(null).nullable(),
      cvssVector: this.string(null).nullable(),
      ips: this.attr([]),
      hostnames: this.attr([]),
      ports: this.attr([]),
      paths: this.attr([]),
      optionals: this.attr([]),
      protocols: this.attr([]),

      attachments: this.attr([]),

      data: this.attr({}),

      project: this.belongsTo(Project, 'projectID'),
      exportRecords: this.hasMany(IssueExportRecord, 'issueId'),
      statusHistory: this.hasMany(IssueStatusChange, 'issueId'),

      _searchBy: this.string(''),
    }
  }

  static get hardcodedFields() {
    return R.pipe(
      R.keys,
      R.without(['project', 'data', 'statusHistory']),
    )(Issue.fields())
  }

  static prepareIssue(issue, { mergeWithExisting = false } = {}) {
    const existingIssue = mergeWithExisting
      ? Issue.find(issue.id)
      : null
    const mergedIssue = {
      ...existingIssue,
      ...R.pick(this.hardcodedFields, issue),
      data: {
        ...existingIssue?.data,
        ...R.omit(this.hardcodedFields, issue),
      },
    }
    mergedIssue._searchBy = R.pipe(
      R.pick(['name', 'status', 'description']),
      R.values,
      R.map(R.toLower),
      R.filter(Boolean),
      R.join(' '),
    )(mergedIssue)
    return mergedIssue
  }

  canBeEditedByCurrentUser() {
    const store = this.$store()

    const currentUser = store.getters['user/current']
    if (!currentUser) return null

    const isAdmin = currentUser.isAdmin
    if (isAdmin) return true

    const project = this.project || Project.find(this.projectID)
    if (!project) return null

    const isOwnerOrEditor = [
      PROJECT_PERMISSION_LEVEL.OWNER,
      PROJECT_PERMISSION_LEVEL.EDITOR,
    ].map(p => p.value).includes(project.permission)
    if (isOwnerOrEditor) return true

    const isLimitedMember = project.permission === PROJECT_PERMISSION_LEVEL.LIMITED.value
    if (!isLimitedMember) return false

    // NB! NOT working, reason: currentUserAssignment.level contains just 'LIMITED', not 'EDIT'/'READ'
    // TODO: check if we can get this info from back-end, while being an assignee (ATM seems not)
    // const assigneesPerms = store.getters['permission/getProjectAssignees'](this.id)
    // if (!assigneesPerms) return null
    // const currentUserAssignment = assigneesPerms.find(p => p.user.id === currentUser.id)
    // return currentUserAssignment != null && currentUserAssignment.level === ISSUE_PERMISSION_LEVEL.EDIT.value

    // FALLBACK: probably user is an editor assignee - so let him try to edit issue for back-end to check perms
    return true
  }

  get projectName() {
    return this.project && this.project.name
  }

  getInlineRoute(baseRoute = {}) {
    return R.pipe(
      R.assocPath(['query', 'issueId'], this.id),
      R.pick(['name', 'params', 'query']),
    )(baseRoute)
  }

  get slaConfig() {
    const { projectID: projectId } = this
    return SlaConfig.query().withAll().find(projectId)
  }
}

export const IssueModule = {
  actions: {
    async $getList(ctx, { projectId = null, filter = null }) {
      projectId = projectId || null

      await Issue.commit(({ $filter }) => {
        Vue.set($filter, String(projectId), R.clone(filter))
      })

      try {
        const issues = await withCancelToken((cancelToken) => {
          if (filter === EMPTY_SET) return []
          if (projectId != null) {
            return filter == null
              ? ProjectService.projectIssuesGet({ projectId }, { cancelToken })
              : axios.get(`/projects/${projectId}/issues/filter`, {
                headers: { 'Content-Type': 'application/json' },
                params: filter,
                cancelToken,
              })
                .then(resp => resp.data)
          }
          return IssueService.issuesByFilter({
            limit: -1,
            // formats are different per-project and cross-project :(
            body: R.pipe(
              R.defaultTo({}),
              R.map(splitValues),
              RA.renameKeys({ project: 'projectID' }),
              R.evolve({
                totalScore: R.map(x => parseInt(x, 10)),
                criticalityScore: R.map(x => parseInt(x, 10)),
                probabilityScore: R.map(x => parseInt(x, 10)),
              }),
            )(filter),
          })
            .then(resp => resp.items)
        }, { action: 'issue-$getList', projectId, filter })
        const keepIssueIds = new Set(issues.map(R.prop('id')))
        await Issue.delete(issue => !keepIssueIds.has(issue.id))
        // await IssueExportRecord.deleteAll()
        await Issue.insertOrUpdate({
          data: issues.map(issue =>
            Issue.prepareIssue(issue, { mergeWithExisting: true })),
        })
        await Issue.commit(({ $filter, $total }) => {
          Vue.set($filter, String(projectId), R.clone(filter))
          if (filter == null) Vue.set($total, String(projectId), issues.length)
        })
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedLoadingIssuesM'))
      }
    },

    // TODO: it is used only in DashboardCardTypeCounter,
    //   figure out whether we need it anymore or not.
    async $getTotal(ctx, { projectId = null, reload = true }) {
      const { rootState } = ctx
      projectId = projectId || null

      if (!reload && rootState.entities.issue.$total[projectId] != null) return

      const validatePayload = {
        cardType: CARD_TYPE.counter,
        query: { query: '' },
      }
      if (projectId != null) validatePayload.dashboardId = projectId

      try {
        // TODO: with pagination there is a better way to get counter (at least cross-project)
        //   if we need it at all.
        const draft = await withCancelToken(
          (cancelToken) =>
            DashboardCard.dispatch('$validate', { ...validatePayload, cancelToken }),
          `issue-$getTotal-${projectId}`,
        )
        await Issue.commit(({ $total }) => {
          Vue.set($total, String(projectId), draft.value.query)
        })
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedFetchingIssuesCountM'))
      }
    },

    async $getDetails(ctx, { issueId, reload = true }) {
      if (!reload && Issue.query().whereId(issueId).exists()) return
      try {
        const issue = await withCancelToken(
          (cancelToken) => IssueService.issueGet({ issueId }, { cancelToken }),
          `issue-$getOne-${issueId}`,
        )
        // await IssueExportRecord.delete(rec => rec.issueId === issueId)
        await Issue.insert({ data: Issue.prepareIssue(issue) })
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedFetchingIssueM'))
      }
    },

    async $update(ctx, { issue: { id: issueId, status, sla }, slaRecalculate }) {
      try {
        const updatedIssue = await IssueService.issuePatch({
          issueId,
          body: { status, sla, slaRecalculate },
        })
        // await IssueExportRecord.delete(rec => rec.issueId === issueId)
        await Issue.insert({ data: Issue.prepareIssue(updatedIssue) })
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedSavingIssueM'))
      }
    },

    // Note: not used anywhere
    async $delete(ctx, { issueId }) {
      try {
        await IssueService.issueDelete({ issueId })
        await Issue.delete(issueId)
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedDeletingIssueM'))
      }
    },

    async $getExportMeta(ctx, { projectId, exportType = EnumExportType.CSV, filterQuery = null }) {
      try {
        return await ExportService.issuesExportMeta({
          body: {
            projectID: projectId || undefined,
            exportType,
            filterQuery: filterQuery || undefined,
          },
        })
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedToGetExportMetaInformationM'))
      }
    },

    async $export(ctx, {
      projectId = null,
      exportType = EnumExportType.CSV,
      dialect = ExportCSVDialects.EXCEL,
      filterQuery = null,
      delimiter = ExportCSVDelimiters.COMMA,
      doubleQuote = '"',
      encoding = ExportCSVEncodings['UTF-8'],
      escapeChar = '"',
      lineTerminator = null,
      quoteChar = '"',
      quotingFields = ExportCSVQuoting.QUOTE_MINIMAL,
      issues = null, // list of ids
    }) {
      // const doubleEscape = R.pipe(
      //   x => String(x),
      //   JSON.stringify.bind(JSON),
      //   R.drop(1),
      //   R.dropLast(1),
      // )

      try {
        // const response = await axios.post('/issues/export/', R.reject(
        //   x => x === '' || x == null,
        //   {
        //     projectID: projectId,
        //     exportType,
        //     dialect,
        //     filterQuery: filterQuery,
        //     doubleQuote,
        //
        //     // ...R.map(R.when(R.is(String), doubleEscape))({
        //     //   delimiter,
        //     //   encoding,
        //     //   escapeChar,
        //     //   lineTerminator,
        //     //   quoteChar,
        //     //   quotingFields,
        //     // }),
        //     delimiter,
        //     encoding,
        //     escapeChar,
        //     lineTerminator,
        //     quoteChar,
        //     quotingFields,
        //   },
        // ), {
        //   headers: { 'Content-Type': 'multipart/form-data' },
        //   responseType: 'blob',
        // })
        const blob = await ExportService.issuesExport({
          body: R.reject(
            x => x === '' || x == null,
            {
              projectID: projectId,
              exportType,
              dialect,
              filterQuery: filterQuery,
              issues: issues?.length ? issues : undefined,
              doubleQuote,

              // ...R.map(R.when(R.is(String), doubleEscape))({
              //   delimiter,
              //   encoding,
              //   escapeChar,
              //   lineTerminator,
              //   quoteChar,
              //   quotingFields,
              // }),
              delimiter,
              encoding,
              escapeChar,
              lineTerminator,
              quoteChar,
              quotingFields,
            },
          ),
        }, { responseType: 'blob' })
        download(
          blob,
          extractFilenameFromContentDisposition(blob[AxiosResponse].headers['content-disposition']),
          blob[AxiosResponse].headers['content-type'],
        )
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedToGetExportMetaInformationM'))
      }
    },
  },
}

export default Issue
