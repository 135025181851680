import Vue from 'vue'
import VueRouter from 'vue-router'

import { reportError } from '../helpers'
import routes from './routes'

Vue.use(VueRouter)

const getRouter = ({
  store, // Vuex store
  api, // import * as api from '@/api'
}) => {
  const routeIsPublic = route =>
    !!(route.meta && route.meta.public)
  const currentUser = () =>
    store && store.getters['user/current']
  const authRoute = (route = {}) =>
    ({ ...route, name: 'Auth' })

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes({ store }),
  })

  router.beforeEach((to, from, next) => {
    if (!routeIsPublic(to) && currentUser() == null) {
      next(authRoute({ query: { next: to.fullPath } }))
    } else next()
  })

  // axios interceptor: redirect to auth on 401 (and reset Vuex store)
  const axios = api.serviceOptions.axios
  axios.interceptors.response.use(
    undefined,
    error => {
      if (!error.response || error.response.status !== 401) {
        // no 401 - default behaviour
        return Promise.reject(error)
      }
      if (routeIsPublic(router.currentRoute)) {
        // public route (auth or register) - default behaviour
        return Promise.reject(error)
      }

      // 401 response, private route: reset store, redirect to `Auth`
      store.commit('user/logOut')
      return store.dispatch('reset')
        .catch(e => reportError(e)) // convert store errors to success
        .then(() => {
          const next = router.currentRoute.fullPath
          const nextRoute = authRoute({ query: { next } })
          return router.replace(nextRoute)
        })
        .catch(e => reportError(e)) // convert route errors to success
        .then(() => Promise.reject(error)) // reject with original axios error
    },
  )

  return router
}

export default getRouter
