<!-- Component for entering codes, business-logic agnostic,
helps entering multi-character codes into the set of tiny inputs for each character.
Used for MFA codes. -->

<template>
  <v-input
    class="AppCodeField"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="item in items">
      <v-text-field
        v-if="item.type === ITEM_TYPE.char"
        ref="charInputs"
        :key="item.key"
        v-model="item.model"
        class="AppCodeField__char-input"
        hide-details
        maxlength="2"
        outlined
        :autofocus="item.key === 'char-0' && !item.value"
        height="40"
        color="primary"
        dense
        :placeholder="placeholder.charAt(item.charIx)"
        :disabled="$attrs.disabled"
        @keyup.8="/* Backspace */ item.charIx > 0 && goToCharInput(item.charIx - 1)"
        @keyup.46="/* Delete */item.charIx < codeLength - 1 && goToCharInput(item.charIx + 1)"
        @focus="$refs.charInputs[item.charIx].$el.querySelector('input[type=text]').select()"
        @keydown.native.left="goToCharInput(Math.max(0, item.charIx - 1))"
        @keydown.native.right="goToCharInput(Math.min(codeLength - 1, item.charIx + 1))"
      />
      <span
        v-else-if="item.type === ITEM_TYPE.delim"
        :key="item.key"
        class="AppCodeField__delim"
        v-text="'–'"
      />
    </template>
  </v-input>
</template>

<script>
import * as R from 'ramda'

const ITEM_TYPE = Object.freeze({
  char: 'char',
  delim: 'delim',
})

export default {
  name: 'AppCodeField',

  inheritAttrs: false,

  props: {
    // final code value, v-model (:value + @input)
    value: { type: String, default: '' },
    // Length of the code. NB! Delimiters don't count! '123-456' should provide 6, not 7.
    codeLength: { type: Number, default: 6 },
    // Indices of delimiter dashes
    // Example for '0123-4567-89XY' is [4, 9]:
    //                  4    9
    delimIndices: { type: Array, default: () => [3] },
    // Allows control whether delimiters should be included in the model,
    // most likely empty string, space character or '-' are relevant options.
    // It does not affect how they are displayed, only the emitted value.
    modelDelimCharacter: { type: String, default: '' },

    // placeholder for individual cells altogether, without delimiter
    placeholder: { type: String, default: '000000' },
  },

  data() {
    return {
      ITEM_TYPE,
      model: [...this.value.replaceAll(this.modelDelimCharacter, '')],
    }
  },

  computed: {
    items() {
      const { codeLength, delimIndices, model } = this
      const self = this

      const res = []
      for (const ix of R.range(0, codeLength)) {
        res.push({
          charIx: ix,
          key: `char-${ix}`,
          type: ITEM_TYPE.char,
          get model() { return model[ix] },
          set model(char) {
            const newModel = [...self.model]
            while (newModel.length - 1 < ix) newModel.push('')
            newModel[ix] = R.last(char)

            self.model = newModel

            self.$emit('partial-input', self.modelWithDelimiters)
            if (char.length && ix < codeLength - 1) self.goToCharInput(ix + 1)
            if (newModel.filter(Boolean).length >= codeLength && ix >= codeLength - 1) self.onLastCharInput()
          },
        })
      }
      for (const delimIx of R.sortBy(R.identity, delimIndices)) {
        res.splice(delimIx, 0, {
          key: `delim-${delimIx}`,
          type: ITEM_TYPE.delim,
        })
      }
      return res
    },

    modelWithDelimiters() {
      const { model, delimIndices, modelDelimCharacter } = this
      const copy = [...model]

      for (const delimIx of R.sortBy(R.identity, delimIndices)) {
        copy.splice(delimIx, 0, modelDelimCharacter)
      }
      return copy.join('')
    },
  },

  watch: {
    value() { this.model = [...this.value.replaceAll(this.modelDelimCharacter, '')] },
  },

  methods: {
    // NB! for public usage
    focus() {
      this.goToCharInput(0)
    },

    async onLastCharInput() {
      await this.$nextTick()
      this.$emit('input', this.modelWithDelimiters)
    },

    async goToCharInput(ix) {
      await this.$nextTick()
      const nextInput = this.$refs.charInputs[ix]
      nextInput.focus()
      await this.$nextTick()
      nextInput.$el.querySelector('input[type=text]').select()
    },
  },
}
</script>

<style lang="sass" scoped>
.AppCodeField
  &__char-input
    max-width: 40px
    flex: 0 0 40px

    & + &
      margin-left: 8px

  &__delim
    margin: 0 7px
</style>
