import { Model } from '@vuex-orm/core'
import * as R from 'ramda'

import { EnumDashboardCardSchemaCardType } from '../../../gen'
import { DashboardService } from '../../api'
import { handleError } from '../../helpers'
import { Dashboard } from './_models'
import i18n from '../../i18n'

export class DashboardCard extends Model {
  static entity = 'dashboardCard'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.number(null).nullable(),
      name: this.string(''),
      cardType: this.string(EnumDashboardCardSchemaCardType.counter),
      query: this.attr({ query: '' }),
      value: this.attr(null).nullable(),
      metaData: this.attr({}),

      // client-side
      dashboardId: this.string(null).nullable(),
    }
  }
}

export const DashboardCardModule = {
  actions: {
    // in card actions: dashboardId is either:
    // * Dashboard.GLOBAL_ID (global aka cross-project dashboard)
    // * or project uuid (in-project dashboard)

    async $getDetails(ctx, { dashboardId = Dashboard.GLOBAL_ID, cardId }) {
      try {
        const card = dashboardId === Dashboard.GLOBAL_ID
          ? await DashboardService.dashboardCardGet({ cardId })
          : await DashboardService.projectDashboardCardGet({ projectId: dashboardId, cardId })
        card.dashboardId = dashboardId
        await DashboardCard.insert({ data: card })
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedFetchingDashboardCardM'))
      }
    },

    async $create(ctx, { dashboardId = Dashboard.GLOBAL_ID, card, commitChanges = true }) {
      if (card.dashboardId && card.dashboardId !== dashboardId) {
        throw new Error(i18n.t('dashboard.ErrorDashboardIdCardMismatchM'))
      }
      try {
        const cardPayload = R.omit(['$id', 'id', 'dashboardId', 'value'], card)
        const createdCard = dashboardId === Dashboard.GLOBAL_ID
          ? await DashboardService.dashboardCardsCreate({ body: cardPayload })
          : await DashboardService.projectDashboardCardsCreate({ projectId: dashboardId, body: cardPayload })
        if (commitChanges) {
          createdCard.dashboardId = dashboardId
          await DashboardCard.insert({ data: createdCard })
          return DashboardCard.find(createdCard.id)
        }
        return createdCard
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedCreatingDashboardCardM'))
      }
    },

    async $update(ctx, { dashboardId = Dashboard.GLOBAL_ID, cardId, card, commitChanges = true }) {
      if (card.dashboardId && card.dashboardId !== dashboardId) {
        throw new Error(i18n.t('dashboard.ErrorDashboardIdCardMismatchM'))
      }
      if (card.id && card.id !== cardId) {
        throw new Error(i18n.t('dashboard.ErrorCardIdMismatchM'))
      }
      try {
        const cardPayload = R.omit(['$id', 'id', 'dashboardId', 'value'], card)
        const updatedCard = dashboardId === Dashboard.GLOBAL_ID
          ? await DashboardService.dashboardCardPatch({ cardId, body: cardPayload })
          : await DashboardService.projectDashboardCardPatch({ projectId: dashboardId, cardId, body: cardPayload })
        if (commitChanges) {
          updatedCard.dashboardId = dashboardId
          await DashboardCard.insert({ data: updatedCard })
          return DashboardCard.find(updatedCard.id)
        }
        return updatedCard
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedUpdatingDashboardCardM'))
      }
    },

    async $delete(ctx, { dashboardId = Dashboard.GLOBAL_ID, cardId, commitChanges = true }) {
      try {
        await (dashboardId === Dashboard.GLOBAL_ID
          ? DashboardService.dashboardCardDelete({ cardId })
          : DashboardService.projectDashboardCardDelete({ projectId: dashboardId, cardId }))
        if (commitChanges) await DashboardCard.delete(cardId)
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedRemovingDashboardCardM'))
      }
    },

    // Computes card value by `cardType` + `query`.
    // Doesn't commit anything to the store (except for errors),
    // it's an action just to keep all requests in store actions.
    async $validate(
      ctx,
      { dashboardId = Dashboard.GLOBAL_ID, cardType, query, cancelToken = undefined },
    ) {
      const cardPayload = { cardType, query }
      try {
        const draft = await (
          dashboardId === Dashboard.GLOBAL_ID
            ? DashboardService.dashboardCardValidate(
              { body: cardPayload },
              { cancelToken },
            )
            : DashboardService.projectDashboardCardValidate(
              { projectId: dashboardId, body: cardPayload },
              { cancelToken },
            )
        )
        return new DashboardCard(draft)
      } catch (e) {
        await handleError(ctx, e, i18n.t('dashboard.ErrorFailedFetchingDashboardCardDraftM'))
      }
    },
  },
}

export default DashboardCard
