<template>
  <v-dialog
    v-model="model"
    class="MfaSetupDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="588"
  >
    <v-card class="MfaSetupDialog__card">
      <aside
        v-show="$vuetify.breakpoint.smAndUp"
        class="MfaSetupDialog__banner secondary"
      />

      <div
        v-if="view === VIEW.recommendedApps"
        :key="VIEW.recommendedApps"
        class="MfaSetupDialog__recommended-apps"
      >
        <h1
          class="MfaSetupDialog__title MfaSetupDialog__title--secondary-bg"
          style="padding: 46px 62px 25px"
          v-text="$t('user.AuthenticatorApps')"
        />
        <div style="padding: 0 62px; background: #F5F5F9">
          <v-tabs
            v-model="recommendedAppsTab"
            background-color="#F5F5F9"
            centered
            fixed-tabs
          >
            <v-tab
              class="MfaSetupDialog__tab"
              style="margin-right: 11px"
            >
              {{ $t('user.AppStore') }}
            </v-tab>
            <v-tab
              class="MfaSetupDialog__tab"
              style="margin-left: 11px"
            >
              {{ $t('user.GooglePlay') }}
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="recommendedAppsTab">
          <v-tab-item
            v-for="(linkProp, ix) in ['appStoreLink', 'playStoreLink']"
            :key="ix"
            class="MfaSetupDialog__tab-item"
          >
            <a
              v-for="app in AUTHENTICATOR_APPS"
              :key="app.name"
              v-ripple="{ class: 'blue--text text--lighten-3' }"
              :href="app[linkProp]"
              class="MfaSetupDialog__app-link"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <img
                :alt="$t('layout.AltAppLogo', { appName: app.name })"
                :src="app.logo"
              >
              <strong
                class="mx-3"
                v-text="app.name"
              />
              <AppQrCode
                :value="app[linkProp]"
                sm
              />
            </a>
          </v-tab-item>
        </v-tabs-items>

        <div
          class="d-flex justify-center"
          style="padding: 0 80px"
        >
          <v-btn
            block
            outlined
            color="primary"
            @click="view = VIEW.scanQr"
          >
            {{ $t('user.Back') }}
          </v-btn>
        </div>
      </div>

      <div
        v-else-if="view === VIEW.scanQr"
        :key="view.scanQr"
        class="MfaSetupDialog__scan-qr"
      >
        <h1
          class="MfaSetupDialog__title mb-4"
          v-text="$t('user.ScanQrToEnableCodeM')"
        />

        <i18n
          tag="p"
          path="user.InstallAppM"
          class="MfaSetupDialog__text"
        >
          <a
            href="#"
            @click.prevent="view = VIEW.recommendedApps"
          >
            {{ $t('user.severalFreeApps') }}
          </a>
        </i18n>

        <div
          class="d-flex flex-column align-center mt-6 mb-8"
          @submit="submit"
        >
          <AppQrCode
            v-if="otpauthUrl"
            :value="otpauthUrl"
          />

          <em
            class="mt-2 text--secondary"
            v-text="$t('user.Secret') + base32"
          />
        </div>

        <h1
          class="MfaSetupDialog__title mb-4"
          v-text="$t('user.enterCodeToSignInM')"
        />

        <div class="d-flex justify-center mb-8">
          <AppCodeField
            ref="codeField"
            :value="mfaToken"
            class="flex-grow-0 flex-shrink-1"
            :code-length="6"
            hide-details
            :disabled="progress"
            @partial-input="mfaToken = $event"
            @input="mfaToken = $event; !progress && submit()"
          />
        </div>

        <div
          class="d-flex justify-center"
          style="margin: 0 44px"
        >
          <v-btn
            color="primary"
            depressed
            :disabled="mfaToken.length < 6 || progress"
            class="flex-grow-1 mr-3"
            @click="submit"
          >
            {{ userId ? $t('user.Save') : $t('user.SignIn') }}
          </v-btn>
          <v-btn
            outlined
            color="primary"
            :disabled="progress"
            @click="$emit('input', false)"
          >
            {{ userId ? $t('user.Cancel') : $t('user.Back') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
const VIEW = Object.freeze({
  // Scree with TOTP as QR to connect authenticator & enter one use MFA-password
  scanQr: 'scanQr',

  // Screen with App Store/Google Play authenticator apps
  recommendedApps: 'recommendedApps',
})

const AUTHENTICATOR_APPS = Object.freeze([
  Object.freeze({
    name: 'Microsoft Authenticator',
    logo: require('../assets/images/logo-microsoft-authenticator-56.png'),
    appStoreLink: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
  }),
  Object.freeze({
    name: 'Google Authenticator',
    logo: require('../assets/images/logo-google-authenticator-56.png'),
    appStoreLink: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  }),
  Object.freeze({
    name: 'LastPass Authenticator',
    logo: require('../assets/images/logo-lastpass-authenticator-56.png'),
    appStoreLink: 'https://apps.apple.com/us/app/lastpass-authenticator/id1079110004',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.lastpass.authenticator',
  }),
])

export default {
  name: 'MfaSetupDialog',

  props: {
    // v-model, dialog open state
    value: { type: Boolean, default: undefined },

    // TOTP setup URL for QR code and base32 secret for manual connection.
    // See also `UserMFASchema` and store action 'user/setMfa'
    otpauthUrl: { type: String, default: '' },
    base32: { type: String, default: '' },

    // user ID to complete MFA setup when user is logged in
    userId: { type: String, default: null },

    userLogin: { type: String, default: null },
    userPassword: { type: String, default: null },
  },

  data() {
    return {
      VIEW,
      AUTHENTICATOR_APPS,

      model: this.value, // dialog open state
      view: VIEW.scanQr, // different views of the model: QR or apps
      recommendedAppsTab: null, // switch between App Store / Google Play
      mfaToken: '', // user-entered one use code to finish MFA setup
      progress: false,
    }
  },

  watch: {
    value: [
      function(v) { if (v !== this.model) this.model = v },
      'resetState',
    ],
    model(m) { this.$emit('input', m) },
  },

  methods: {
    async submit() {
      const { $store, userId, userLogin, userPassword, mfaToken } = this
      this.progress = true
      try {
        if (userId) {
          await $store.dispatch('user/confirmMfa', { userId, mfaToken })
        } else if (userLogin != null && userPassword) {
          const { requestMfaToken } = await $store.dispatch('user/signIn', { userLogin, userPassword, mfaToken })
          if (requestMfaToken) {
            this.mfaToken = ''
            this.$nextTick(() => this.$refs.codeField.focus())
            return this.$store.commit('$snackbar/setMessage', {
              message: this.$t('user.ProvideCodeM'),
            })
          }
        } else {
          throw new Error(this.$t('user.userIdRequiredM'))
        }
      } catch (e) {
        this.mfaToken = ''
        this.$refs.codeField.focus()
      } finally {
        this.progress = false
      }
      this.$emit('success')
    },

    resetState() {
      this.view = VIEW.scanQr
      this.mfaToken = ''
      this.recommendedAppsTab = null
    },
  },
}
</script>

<style lang="sass" scoped>
.MfaSetupDialog
  &__card
    display: flex
    flex-direction: row

  &__banner
    display: block
    flex: 0 0 136px
    border-top-right-radius: 0 !important
    background-image: url('../assets/backgrounds/triangles-down.png')
    background-repeat: repeat

  &__recommended-apps, &__scan-qr
    flex: 1
    border-top-left-radius: 0 !important
    border-bottom-left-radius: 0 !important

  &__recommended-apps
    padding-bottom: 64px

  &__title
    font-weight: 500
    font-size: 20px
    line-height: 130%
    letter-spacing: 0.015em
    text-align: center

    &--secondary-bg
      background: #F5F5F9

  &__text
    text-align: center

  &__tab
    font-weight: 500
    font-size: 16px
    line-height: 20px
    letter-spacing: 0.015em
    // color: #3C3A52

  &__tab-item
    padding: 32px 0 20px

  &__app-link
    margin: 4px 0
    padding: 12px 80px
    display: flex
    align-items: center
    justify-content: space-between

    font-weight: 500
    font-size: 16px
    line-height: 20px
    letter-spacing: 0.015em
    color: #3C3A52
    text-decoration: none

  &__scan-qr
    padding: 46px 36px 77px
</style>
