const initState = () => ({
  miniDrawer: false,
  settingsDrawer: false,
  notificationDrawer: false,
})

export default {
  namespaced: true,

  state: initState,

  mutations: {
    reset: state => Object.assign(state, initState()),

    setMiniDrawer: (state, miniDrawer) => {
      state.miniDrawer = !!miniDrawer
    },

    setSettingsDrawer: (state, settingsDrawer) => {
      state.settingsDrawer = !!settingsDrawer
      if (settingsDrawer) state.notificationDrawer = false
    },

    setNotificationDrawer: (state, notificationDrawer) => {
      state.notificationDrawer = !!notificationDrawer
      if (notificationDrawer) {
        state.settingsDrawer = false
        state.miniDrawer = true
      }
    },
  },
}
