<!-- appearance/behaviour specific to frigate -->
<template>
  <MarkdownViewer
    ref="markdownViewer"
    class="AppMarkdownViewer"
    :source="source"
    :create-vue-instance="createVueInstance"
    :copy-btn="copyBtn"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  >
    <!-- copyToClipboard appearance & behaviour -->
    <template #copy="{ cls, style, codeText }">
      <v-btn
        class="AppMarkdownViewer__copy-btn"
        :class="cls"
        :style="style"
        icon
        color="primary"
        @click="copyToClipboard(codeText)"
      >
        <v-icon
          size="16"
          v-text="'$copy'"
        />
      </v-btn>
    </template>
  </MarkdownViewer>
</template>

<script>
import copyToClipboard from 'copy-to-clipboard'
import Vue from 'vue'

import vuetify from '../plugins/vuetify'
const MarkdownViewer = () => import(
  /* webpackChunkName: "markdown-viewer" */
  /* webpackPrefetch: true */
  '../components/MarkdownViewer'
)

export default {
  name: 'AppMarkdownViewer',

  components: {
    MarkdownViewer,
  },

  inheritAttrs: false,

  model: {
    prop: 'source',
    event: 'input',
  },

  props: {
    source: { type: String, default: '' },
    copyBtn: { type: Boolean, default: true },
  },

  methods: {
    createVueInstance(options = {}) {
      // to use `v-icon` in slots
      return new Vue({ vuetify, ...options })
    },

    copyToClipboard(text) {
      copyToClipboard(text)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('layout.Copied'),
      })
    },

    async _depsResolved() {
      await MarkdownViewer()
      await this.$nextTick()
      await this.$refs.markdownViewer._depsResolved()
      await this.$nextTick()
    },
  },
}
</script>
