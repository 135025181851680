const initState = () => ({
  testConfigRes: null,
  testConfigError: null,
})

const mutations = {
  reset: state => Object.assign(state, initState()),

  setTestConfigRes: (state, testConfigRes) => {
    state.testConfigRes = testConfigRes
    state.testConfigError = null
  },

  setTestConfigError: (state, testConfigError) => {
    state.testConfigError = testConfigError
    state.testConfigRes = null
  },
}

export default {
  namespaced: true,
  state: initState,
  mutations,
}
