import { normalize } from 'normalizr'
import * as R from 'ramda'
import Vue from 'vue'

import { UserService, SessionService } from '../../api'
import { USER_STATE } from '../../constants'
import { handleError, reportError } from '../../helpers'
import { User } from '../schema'

const defaultState = () => ({
  current: null, // Maybe<UUID>
  byId: {}, // Object<UUID, User>
  order: null, // Array<UUID>
})

const localStorageKey = k => `vuex/user/${k}`

// init state, seek current user in localStorage
const initState = () => {
  const lsCurrentUser = localStorage.getItem(localStorageKey('current'))
  if (!lsCurrentUser) return defaultState()

  let user = null
  try {
    user = JSON.parse(lsCurrentUser)
  } catch (e) {
    reportError(e)
  }

  return user && user.id
    ? { current: user.id, byId: { [user.id]: user }, order: null }
    : defaultState()
}

const clearLocalStorage = () => {
  localStorage.removeItem(localStorageKey('current'))
}

export const watch = store => {
  // subscribe to mutations to sync `current` user to `localStorage`
  const unsubscribe = store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'user/setCurrent':
        localStorage.setItem(
          localStorageKey('current'),
          JSON.stringify(state.user.byId[state.user.current]),
        )
        break

      case 'user/logOut':
        localStorage.removeItem(localStorageKey('current'))
        break
    }
  })
  return () => {
    unsubscribe()
  }
}

export default {
  namespaced: true,

  state: initState,

  getters: {
    current: state =>
      state.current && state.byId[state.current],
    get: ({ byId }) =>
      userId =>
        byId[userId] || null,
    list: ({ order }, { get }) =>
      order && order.map(get).filter(Boolean),
    active: (_, { list }) =>
      list && list.filter(user =>
        user.state === USER_STATE.ACTIVE.value),
  },

  mutations: {
    reset: state => {
      Object.assign(state, initState())
      clearLocalStorage()
    },

    setOne: (state, { user }) => {
      const {
        result: id,
        entities: { users },
      } = normalize(user, User)
      Vue.set(state.byId, id, { ...state.byId[id], ...users[id] })
    },

    setList: (state, { users }) => {
      const {
        result: order,
        entities: { users: byId },
      } = normalize(users.map(user => user), [User])
      state.byId = byId
      state.order = order
    },

    setCurrent: (state, { user }) => {
      const {
        result: id,
        entities: { users },
      } = normalize(user, User)
      Vue.set(state.byId, id, users[id])
      state.current = id
    },

    setMfaConfirmed: (state, { userId, mfaConfirmed }) => {
      Vue.set(state.byId[userId], 'mfaConfirmed', mfaConfirmed)
    },

    logOut: state => {
      state.current = null
    },
  },

  actions: {
    getList: ({ commit }) =>
      UserService.usersGet()
        .then((users) => commit('setList', { users }))
        .catch(e => handleError({ commit }, e)),

    // details response have some more fields
    getDetails: ({ commit }, { userId }) =>
      UserService.userUserIdGet({ userId })
        .then((user) => commit('setOne', { user }))
        .catch(e => handleError({ commit }, e)),

    signIn: ({ dispatch, commit }, { userLogin, userPassword, mfaToken = '' }) => {
      const form = { userLogin, userPassword }
      if (mfaToken) form.mfaToken = mfaToken

      const maybeLogoutAttempt = mfaToken
        ? Promise.resolve()
        // Log out first: "AlreadyLoggedIn, User with this credentials is already logged in."
        : dispatch('logOut', { force: true }).catch(() => { /* pass */ })

      return maybeLogoutAttempt
        .then(() => SessionService.sessionPost({ body: form }))
        .then(user => {
          if (user?.id) {
            // No MFA flow, just set user
            commit('setCurrent', { user })
            return { requestMfaToken: false, base32: null, otpauthUrl: null }
          } else if (user?.base32 && user?.otpauthUrl) {
            // MFA is required but user has not added secret to his authenticator yet,
            // user has to add secret (scan QR) and then provide `mfaToken`
            const { base32, otpauthUrl } = user
            return { requestMfaToken: true, base32, otpauthUrl }
          } else {
            // MFA is set up, user has to provide `mfaToken`
            return { requestMfaToken: true, base32: null, otpauthUrl: null }
          }
        })
        .catch(e => handleError({ commit }, e))
    },

    register: ({ commit, dispatch }, { form, signIn = true }) =>
      UserService.userRegistration({ body: form })
        .then(async ({ id: createdUserId }) => {
          if (signIn) await dispatch('signIn', form)
          return createdUserId
        })
        .catch(e => handleError({ commit }, e)),

    update: ({ commit }, { user }) =>
      UserService.usersPatch({ userId: user.id, body: R.omit(['id', 'userLogin', 'registered'], user) })
        .then(() => user.id)
        .catch(e => handleError({ commit }, e)),

    // setup MFA stage 1: generate TOTP secret
    enableMfa: ({ commit }, { userId }) =>
      UserService.usersMfaPost({ userId })
        .catch(e => handleError({ commit }, e)),

    // setup MFA stage 2: confirm that your authenticator has been set up correctly
    confirmMfa: ({ commit }, { userId, mfaToken }) =>
      UserService.usersMfaConfirmPost({ userId, body: { mfaToken } })
        .then(() => commit('setMfaConfirmed', { userId, mfaConfirmed: true }))
        .catch(e => handleError({ commit }, e)),

    disableMfa: ({ commit }, { userId }) =>
      UserService.usersMfaDelete({ userId })
        .then(() => commit('setMfaConfirmed', { userId, mfaConfirmed: false }))
        .catch(e => handleError({ commit }, e)),

    // delete: ({ commit }, { userId }) =>
    //   UserService.usersDelete({ userId })
    //     .catch(e => handleError({ commit }, e)),

    logOut: ({ getters, commit, dispatch }, { force = false } = {}) =>
      (force || getters.current
        ? SessionService.sessionDelete()
        : Promise.resolve(undefined))

        .catch(e => {
          reportError(e)
        })
        .then(() => {
          commit('logOut')
          return dispatch('reset', null, { root: true })
        }),
  },
}
