export default {
  inheritAttrs: false,

  props: {
    marginsWithHiddenDetails: { type: String, default: 'mb-4' },
    marginsWithDetails: { type: String, default: '' },
    hint: { type: String, default: '' },
    hideDetails: { type: Boolean, default: false },
    transparentBorders: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessages: { type: [String, Array], default: () => [] },
  },

  computed: {
    shouldHideDetails() {
      const { hideDetails, hint, error, errorMessages } = this
      return hideDetails || (!hint.length && !error && !errorMessages.length)
    },

    marginsClasses() {
      const { marginsWithDetails, marginsWithHiddenDetails, shouldHideDetails } = this
      return shouldHideDetails
        ? { [marginsWithHiddenDetails]: true }
        : { [marginsWithDetails]: true }
    },

    stateClasses() {
      const { disabled } = this.$attrs
      const { transparentBorders } = this
      return {
        [`${this.$options.name}--disabled`]: !!disabled,
        [`${this.$options.name}--transparent-borders`]: !!transparentBorders,
      }
    },
  },
}
