import { Model } from '@vuex-orm/core'

import { EnumIssueStatusChangeEntrySchemaSource } from '../../../gen'
import { IssueService } from '../../api'
import { handleError } from '../../helpers'
import { withCancelToken } from '../cancellableActions'
import i18n from '../../i18n'

export class IssueStatusChange extends Model {
  static entity = 'issueStatusChange'
  static primaryKey = 'uid'

  static fields() {
    return {
      uid: this.uid(),
      issueId: this.string(null).nullable(),
      author: this.string(null).nullable(),
      datetime: this.string(null).nullable(),
      newValue: this.string(null).nullable(),
      oldValue: this.string(null).nullable(),
      source: this.string(EnumIssueStatusChangeEntrySchemaSource.local).nullable(),
    }
  }
}

export const IssueStatusChangeModule = {
  actions: {
    async $get(ctx, { issueId, reload = true }) {
      if (!reload && IssueStatusChange.query().where('issueId', issueId).exists()) return
      try {
        const statusChanges = await withCancelToken(
          (cancelToken) => IssueService.issueStatusChanges({ issueId }, { cancelToken }),
          `issueStatusChange-$get-${issueId}`,
        )
        statusChanges.forEach(rec => { rec.issueId = issueId })
        IssueStatusChange.query().where('issueId', issueId).deleteAll()
        await IssueStatusChange.insert({ data: statusChanges })
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedFetchingIssueStatusHistoryM'))
      }
    },
  },
}

export default IssueStatusChange
