import { schema } from 'normalizr'

export const User = new schema.Entity('users')
export const BotUser = new schema.Entity('bots')
export const Project = new schema.Entity('projects')
export const Card = new schema.Entity('cards')
export const Dashboard = new schema.Entity('dashboards', {
  cards: [Card],
})
export const Issue = new schema.Entity('issues')
