import * as R from 'ramda'
import Vue from 'vue'

import { ProjectService, IssueService } from '../../api'
import { PROJECT_PERMISSION_LEVEL, ISSUE_PERMISSION_LEVEL } from '../../constants'
import { handleError } from '../../helpers'

// PermissionId => ProjectId
// const extractProjectId = R.takeWhile(char => char !== ':')

// ProjectId => RawProjectPermission => { permission: ProjectPermission, user: User }
const normalizeProjectPermission = (projectId, permission) => {
  const user = permission.user
  const copy = R.omit(['user'], permission)
  copy.projectId = projectId
  copy.userId = user.id
  copy.id = `${projectId}:${copy.userId}`
  return { permission: copy, user }
}

// ProjectId => IssueId => RawIssuePermission => { permission: IssuePermission, user: User }
const normalizeIssuePermission = (projectId, issueId, permission) => {
  const user = permission.user
  const copy = R.omit(['user'], permission)
  copy.projectId = projectId
  copy.issueId = issueId
  copy.userId = user.id
  copy.id = `${projectId}:${issueId}:${copy.userId}`
  return { permission: copy, user }
}

const initState = () => ({
  // permissions come in two flavors: (1) for projects and (2) for issues

  project: { // (1) project permissions
    order: {}, // Object<ProjectId, Array<ProjectPermissionId>>
    byId: {}, // Object<ProjectPermissionId, ProjectPermission>
    // additional lookup to find assignee permissions for issue
    byIssueId: {}, // Object<IssueId, Array<ProjectPermissionId>>
  },

  issue: { // (2) issue permissions
    order: {}, // Object<IssueId, Array<IssuePermissionId>>
    byId: {}, // Object<IssuePermissionId, IssuePermission>
  },
})

const getters = {
  getProjectPermission: ({ project: { byId } }, _getters, _rootState, { 'user/get': getUser }) =>
    projectPermissionId => {
      const permission = byId[projectPermissionId] || null
      const user = permission && getUser(permission.userId)
      return permission && user &&
        R.assoc('user', user, permission)
    },

  forProject: ({ project: { order } }, { getProjectPermission }) =>
    projectId =>
      R.unless(R.isNil, R.pipe(
        R.map(getProjectPermission),
        R.sortWith([
          R.ascend(perm => PROJECT_PERMISSION_LEVEL[perm.level]?.sort ?? Infinity),
          R.ascend(perm => perm.user.userLogin),
        ]),
      ))(order[projectId] ?? null),

  // Returns whether user has at least provided permission level (boolean) or not.
  // Returns `null` when permissions were not fetched yet.
  // Usage example:
  // $store.getters['permission/checkUserLevel'](projectId, userId, PROJECT_PERMISSION_LEVEL.EDITOR)
  checkUserLevel: (_state, { forProject }) =>
    (projectId, userId, minPermissionLevel) => {
      if (!minPermissionLevel || minPermissionLevel.weight == null) {
        throw new TypeError('Expected item from PROJECT_PERMISSION_LEVEL, got: ' + minPermissionLevel)
      }
      const perms = forProject(projectId)
      if (!perms) return null
      const userPerm = perms.find(perm => perm.userId === userId)
      return userPerm != null && PROJECT_PERMISSION_LEVEL[userPerm.level].weight >= minPermissionLevel.weight
    },

  getProjectAssignees: ({ project: { byIssueId } }, { getProjectPermission }) =>
    issueId =>
      byIssueId[issueId]?.map?.(getProjectPermission) ?? null,

  getIssuePermission: ({ issue: { byId } }, _getters, _rootState, { 'user/get': getUser }) =>
    issuePermissionId => {
      const permission = byId[issuePermissionId] || null
      return permission &&
        R.assoc('user', getUser(permission.userId), permission)
    },

  forIssue: ({ issue: { order } }, { getIssuePermission }) =>
    projectId =>
      R.unless(R.isNil, R.pipe(
        R.map(getIssuePermission),
        R.sortWith([
          R.ascend(perm => ISSUE_PERMISSION_LEVEL[perm.level]?.sort ?? Infinity),
          R.ascend(perm => perm.user.userLogin),
        ]),
      ))(order[projectId] ?? null),
}

const mutations = {
  reset: state => Object.assign(state, initState()),

  setProjectPermission: (state, { projectPermission, updateOrder = true }) => {
    if (!projectPermission.id) {
      throw new Error('use action permission/_setProjectPermission')
    }

    Vue.set(state.project.byId, projectPermission.id, projectPermission)
    projectPermission.issues.forEach(issueId =>
      mutations.setProjectAssignee(state, { issueId, projectPermissionId: projectPermission.id }))

    if (updateOrder) {
      const neighbors = state.project.order[projectPermission.projectId]
      if (neighbors && !neighbors.includes(projectPermission.id)) {
        neighbors.push(projectPermission.id)
      }
    }
  },

  setProjectPermissions: (state, { projectId, projectPermissions }) => {
    const ids = projectPermissions.map(p => p.id)
    if (!ids.every(Boolean)) throw new Error('use action permission/_setProjectPermissions')

    // fixme: this may mess up storage
    //   if we are ever going to work with multiple projects simultaneously
    state.project.byIssueId = {}

    projectPermissions.forEach(projectPermission =>
      mutations.setProjectPermission(state, { projectPermission, updateOrder: false }))
    Vue.set(state.project.order, projectId, ids)
  },

  removeProjectPermission: ({ project: { order, byId, byIssueId } }, { projectId, projectPermissionId }) => {
    const projectPerms = order[projectId]
    if (projectPerms?.includes?.(projectPermissionId)) {
      order[projectId] = projectPerms.filter(id => id !== projectPermissionId)
    }
    const perm = byId[projectPermissionId]
    Vue.delete(byId, projectPermissionId)
    if (perm) {
      perm.issues.forEach(issueId => {
        mutations.removeProjectAssignee({ project: { order, byId, byIssueId } }, { issueId, projectPermissionId: perm.id })
      })
    }
  },

  setProjectAssignee: ({ project: { byIssueId } }, { issueId, projectPermissionId }) => {
    if (R.has(issueId, byIssueId)) {
      const permissionIds = byIssueId[issueId]
      if (!permissionIds.includes(projectPermissionId)) {
        permissionIds.push(projectPermissionId)
      }
    } else {
      Vue.set(byIssueId, issueId, [projectPermissionId])
    }
  },

  removeProjectAssignee: ({ project: { byIssueId = null } }, { issueId, projectPermissionId }) => {
    if (R.has(issueId, byIssueId)) {
      const permissionIds = byIssueId[issueId]
      while (permissionIds.includes(projectPermissionId)) {
        permissionIds.splice(permissionIds.indexOf(projectPermissionId), 1)
      }
    }
  },

  setIssuePermission: (state, { issuePermission, updateOrder = true }) => {
    if (!issuePermission.id) {
      throw new Error('use action permission/_setIssuePermission')
    }

    Vue.set(state.issue.byId, issuePermission.id, issuePermission)

    if (updateOrder) {
      const neighbors = state.issue.order[issuePermission.issueId]
      if (neighbors && !neighbors.includes(issuePermission.id)) {
        neighbors.push(issuePermission.id)
      }
    }
  },

  setIssuePermissions: (state, { issueId, issuePermissions }) => {
    const ids = issuePermissions.map(perm => perm.id)
    if (!ids.every(Boolean)) throw new Error('use action permission/_setIssuePermissions')
    issuePermissions.forEach(issuePermission =>
      mutations.setIssuePermission(state, { issuePermission, updateOrder: false }))
    Vue.set(state.issue.order, issueId, ids)
  },

  removeIssuePermission: ({ issue: { order, byId } }, { issueId, issuePermissionId }) => {
    const issuePermIds = order[issueId]
    if (issuePermIds?.includes?.(issuePermissionId)) {
      order[issueId] = issuePermIds.filter(id => id !== issuePermissionId)
    }
    Vue.delete(byId, issuePermissionId)
  },
}

const actions = {
  getProjectPermissions: ({ state, commit, dispatch }, { projectId, reload = true }) =>
    !reload && state.project.order[projectId] != null
      ? Promise.resolve(undefined)
      : ProjectService.projectUsersGet({ projectId })
        .then(projectPermissions =>
          dispatch('_setProjectPermissions', { projectId, projectPermissions }))
        .catch(e => handleError({ commit }, e)),

  createProjectPermission: (
    { commit, dispatch },
    { projectId, userId: userID, level },
  ) =>
    ProjectService.projectUsersPost({
      projectId,
      body: { userID, level },
    })
      // empty response on created: reload all project permissions
      .then(() => dispatch('getProjectPermissions', { projectId }))
      .catch(e => handleError({ commit }, e)),

  updateProjectPermission: (
    { commit, dispatch },
    { projectId, userId: userID, level },
  ) =>
    ProjectService.projectUsersPatch({
      projectId,
      body: { userID, level },
    })
      // empty response on updated: reload all project permissions
      .then(() => dispatch('getProjectPermissions', { projectId }))
      .catch(e => handleError({ commit }, e)),

  deleteProjectPermission: (
    { commit },
    { projectId, userId },
  ) =>
    ProjectService.projectUsersDelete({
      projectId,
      userId,
    })
      .then(() => commit('removeProjectPermission', {
        projectId,
        projectPermissionId: `${projectId}:${userId}`,
      }))
      .catch(e => handleError({ commit }, e)),

  // delete project permission for current user
  // should be available for everyone (except for project owner)
  leaveProject: (
    {
      dispatch,
      rootState: { user: { current: userId } },
    },
    { projectId, reloadProjects = true },
  ) =>
    dispatch('deleteProjectPermission', { projectId, userId })
      .then(() =>
        reloadProjects &&
        dispatch('entities/project/$getList', { reload: true }, { root: true })),

  getIssuePermissions: ({ commit, dispatch }, { projectId, issueId }) =>
    IssueService.issuePermissionsGet({ issueId })
      .then(issuePermissions =>
        dispatch('_setIssuePermissions', { projectId, issueId, issuePermissions }))
      .catch(e => handleError({ commit }, e)),

  createIssuePermission: (
    { commit, dispatch },
    { projectId, issueId, userId: userID, level },
  ) =>
    IssueService.issuePermissionsPost({
      issueId,
      body: { userID, level },
    })
      // empty response on created: reload all issue permissions
      .then(() => dispatch('getIssuePermissions', { projectId, issueId }))
      .catch(e => handleError({ commit }, e)),

  updateIssuePermission: (
    { commit, dispatch },
    { projectId, issueId, userId: userID, level },
  ) =>
    IssueService.issuePermissionsPatch({
      issueId,
      body: { userID, level },
    })
      // empty response on updated: reload all issue permissions
      .then(() => dispatch('getIssuePermissions', { projectId, issueId }))
      .catch(e => handleError({ commit }, e)),

  changeIssuePermissions: (
    { commit, dispatch },
    { projectId, issueIds, userId: userID, level },
  ) =>
    IssueService.issuesPermissionsBulkChange({
      projectId,
      body: { userID, level, issues: issueIds },
    })
      // empty response on created: reload all issue permissions
      .then(() => issueIds.map(issueId => dispatch('getIssuePermissions', { projectId, issueId })))
      .catch(e => handleError({ commit }, e)),

  deleteIssuePermissions: (
    { commit },
    { projectId, issueIds, userId },
  ) =>
    IssueService.issuesPermissionsBulkDelete({
      projectId,
      body: {
        issues: issueIds,
        userID: userId,
      },
    })
      .then(() => issueIds.forEach(issueId => commit('removeIssuePermission', {
        issueId,
        issuePermissionId: `${projectId}:${issueId}:${userId}`,
      })))
      .catch(e => handleError({ commit }, e)),

  // data-committing mutations
  _setProjectPermission: ({ commit }, { projectPermission: rawPermission, projectId }) => {
    const { user, permission } = normalizeProjectPermission(projectId, rawPermission)
    commit('user/setOne', { user }, { root: true })
    commit('setProjectPermission', { projectPermission: permission })
  },

  _setProjectPermissions: ({ commit }, { projectPermissions: rawPermissions, projectId }) => {
    const projectPermissions = []
    const users = []

    rawPermissions.forEach(rawPermission => {
      const { permission, user } = normalizeProjectPermission(projectId, rawPermission)
      projectPermissions.push(permission)
      users.push(user)
    })

    users.forEach(user =>
      commit('user/setOne', { user }, { root: true }))
    commit('setProjectPermissions', { projectId, projectPermissions })
  },

  _setIssuePermission: ({ commit }, { issuePermission: rawPermission, projectId, issueId }) => {
    const { user, permission } = normalizeIssuePermission(projectId, issueId, rawPermission)
    commit('user/setOne', { user }, { root: true })
    commit('setIssuePermission', { issuePermission: permission })
  },

  _setIssuePermissions: ({ commit }, { issuePermissions: rawPermissions, projectId, issueId }) => {
    const issuePermissions = []
    const users = []

    rawPermissions.forEach(rawPermission => {
      const { permission, user } = normalizeIssuePermission(projectId, issueId, rawPermission)
      issuePermissions.push(permission)
      users.push(user)
    })

    users.forEach(user =>
      commit('user/setOne', { user }, { root: true }))
    commit('setIssuePermissions', { issueId, issuePermissions })
  },
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
