import { Model } from '@vuex-orm/core'
import URI from 'urijs'

import {
  EnumJiraCommentSchemaStatus,
  EnumJiraCommentSchemaType,
  IntegrationService,
} from '../../api'
import { handleError } from '../../helpers'
import { IssueExportRecord } from './_models'
import i18n from '../../i18n'

export class IssueComment extends Model {
  static entity = 'issueComment'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.string(null).nullable(),
      type: this.string(EnumJiraCommentSchemaType.LOCAL),
      body: this.string(null).nullable(),
      author: this.string(null).nullable(),

      status: this.string(EnumJiraCommentSchemaStatus.CREATED),
      deleted: this.string(null).nullable(),
      created: this.string(null).nullable(),
      updated: this.string(null).nullable(),

      exportRecordID: this.string(null).nullable(),
      externalID: this.string(null).nullable(),
      localUserID: this.string(null).nullable(),

      exportRecord: this.belongsTo(IssueExportRecord, 'exportRecordID'),
    }
  }

  get isLocal() {
    return this.type === EnumJiraCommentSchemaType.LOCAL
  }

  get avatarIcon() {
    return this.isLocal
      ? '$logo-color-apiary'
      : '$logo-color-jira'
  }

  get avatarColor() {
    return this.isLocal ? '#41415A' : '#E6F2FD'
  }

  get externalHref() {
    const { exportRecord, externalID } = this
    if (!this.exportRecord?.metaData?.jiraIssueUrl) return null
    return URI(exportRecord.metaData.jiraIssueUrl)
      .addQuery('focusedCommentId', externalID)
      .toString()
  }
}

export const IssueCommentModule = {
  actions: {
    async $getForExportRecord(ctx, { exportRecordId, reload = true }) {
      if (!reload && IssueComment.query().where('exportRecordID', exportRecordId).exists()) return

      try {
        const comments = await IntegrationService.getJiraComments({ exportRecordId })
        await IssueComment.insertOrUpdate({ data: comments })
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedFetchingExternalCommentsM'))
      }
    },

    async $create(ctx, { exportRecordId, issueComment: { body } }) {
      try {
        const createdComment = await IntegrationService.postJiraComment({
          exportRecordId,
          body: { body },
        })
        await IssueComment.insert({ data: createdComment })
        return createdComment.id
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedPostingNewCommentM'))
      }
    },

    async $update(ctx, { exportRecordId, issueComment: { id: issueCommentId, body } }) {
      try {
        const updatedComment = await IntegrationService.patchJiraComment({
          exportRecordId,
          jiraCommentId: issueCommentId,
          body: { body },
        })
        await IssueComment.insertOrUpdate({ data: updatedComment })
        return updatedComment.id
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedUpdatingCommentM'))
      }
    },

    async $delete(ctx, { exportRecordId, issueCommentId }) {
      try {
        await IntegrationService.deleteJiraComment({
          exportRecordId,
          jiraCommentId: issueCommentId,
        })
        await IssueComment.delete(issueCommentId)
      } catch (e) {
        await handleError(ctx, e, i18n.t('issue.ErrorFailedDeletingCommentM'))
      }
    },
  },
}

export default IssueComment
