import * as R from 'ramda'
import moment from 'moment'
import Vue from 'vue'

import { IssueService } from '../../api'
import { CHAT_MESSAGE_STATUS as STATUS, SERVER_TYPE } from '../../constants'
import { handleError } from '../../helpers'

let idSeq = 0

const makeMessage = (projectUuid, issueUuid, rawMessage) =>
  ({
    ...rawMessage,
    projectUuid,
    issueUuid,
    isClientOnly: rawMessage.id.startsWith('fake-'),
  })

// Array<Message> -> ProjectUuid -> IssueUuid -> {
//   messages: Object<MessageUuid, Message>,
//   replies: Object<MessageUuid, Array<MessageUuid>>,
// }
const normalizeMessages = (messages, projectUuid, issueUuid) => {
  const res = { messages: {}, replies: {} }
  for (const rawMessage of messages) {
    const message = makeMessage(projectUuid, issueUuid, rawMessage)
    messages[message.id] = message
    const rawChildren = message.children
    const { messages: childMessages, replies: childReplies } =
      normalizeMessages(rawChildren, projectUuid, issueUuid)
    res.messages = {
      ...res.messages,
      ...childMessages,
      [message.id]: message,
    }
    res.replies = {
      ...res.replies,
      ...childReplies,
      [message.id]: Object.values(childMessages).map(m => m.id),
    }
  }
  return res
}

// Array<Message> -> Array<MessageUuid>
const getMessagesOrder =
  R.pipe(
    R.sortWith([
      // 1. by time
      R.ascend(message => moment(message.timestamp).valueOf()),
      // 2. by uuid to ensure sort results for simultaneous messages
      R.ascend(message => message.id),
    ]),
    R.map(R.prop('id')),
  )

const initState = () => ({
  // messages are stored in the mapping
  messages: {}, // Object<MessageUuid, Message>

  // tracking which messages (values) belong to which issues (keys)
  issueMessages: {}, // Object<IssueUuid, Array<MessageUuid>>

  // tracking which messages (values) are replies to which parent messages (keys)
  replies: {}, // Object<MessageUuid, Array<MessageUuid>>
})

const getters = {
  // Reverse map of `state.replies`
  // Object<MessageUuid, MessageUuid>
  reverseReplies: ({ replies }) =>
    Object
      .entries(replies)
      .reduce((res, [parentUuid, childrenUuids]) => {
        childrenUuids.forEach(id => { res[id] = parentUuid })
        return res
      }, {}),

  // get one message by its UUID
  get: ({ messages }, { reverseReplies }) =>
    messageUuid => {
      const message = messages[messageUuid]
      return message
        ? { ...message, parentUuid: reverseReplies[message.id] ?? null }
        : null
    },

  // gets a list of messages for an issue with the given UUID
  list: ({ issueMessages }, { get: getMessage }) =>
    issueUuid =>
      issueMessages[issueUuid]?.map?.(id => getMessage(id)) ?? null,
}

const mutations = {
  // wipes module state
  reset: state => Object.assign(state, initState()),

  // adds a message to the module state; optionally (`updateIssue`)
  // adds the message's UUID to the list of messages for `issueUuid`
  setOne: (
    state,
    { projectUuid, issueUuid, message: rawMessage, updateIssue = true },
  ) => {
    const { messages, replies } =
      normalizeMessages([rawMessage], projectUuid, issueUuid)
    state.messages = {
      ...state.messages,
      ...messages,
    }
    state.replies = {
      ...state.replies,
      ...replies,
    }
    if (updateIssue) {
      const addMessageIds = getMessagesOrder(Object.values(messages))
      const order = state.issueMessages[issueUuid]
      Vue.set(
        state.issueMessages,
        issueUuid,
        order
          ? R.uniq([...order, ...addMessageIds])
          : addMessageIds,
      )
    }
  },

  // adds messages to the module state;
  // overwrites the list of messages for `issueUuid`
  setList: (state, { projectUuid, issueUuid, messages: rawMessages }) => {
    const { messages, replies } =
      normalizeMessages(rawMessages, projectUuid, issueUuid)
    state.messages = {
      ...state.messages,
      ...messages,
    }
    state.replies = {
      ...state.replies,
      ...replies,
    }
    const clientOnlyMessages = state.issueMessages[issueUuid]
      ?.map?.(id => state.messages[id])
      ?.filter?.(m => m.isClientOnly)
    const ids = getMessagesOrder([
      ...Object.values(messages),
      ...(clientOnlyMessages || []),
    ])
    Vue.set(state.issueMessages, issueUuid, ids)
  },

  removeOne: ({ messages, issueMessages }, { messageUuid, issueUuid }) => {
    const order = issueMessages[issueUuid]
    if (order) issueMessages[issueUuid] = order.filter(id => id !== messageUuid)
    Vue.delete(messages, messageUuid)
  },
}

const actions = {
  // fetches all messages for the issue with the given UUID
  getForIssue: ({ commit }, { projectUuid, issueUuid }) =>
    IssueService.getChatMessages({ issueId: issueUuid })
      .then(messages =>
        commit('setList', { projectUuid, issueUuid, messages }))
      .catch(e => handleError({ commit }, e)),

  // creates a message for an issue with the give UUID;
  // `parentUuid` is an optional UUID of another message (for replies)
  create: (
    { commit, dispatch, rootGetters, state },
    { projectUuid, issueUuid, text, parentUuid = null },
  ) => {
    // creating a temporary message
    const tempId = ++idSeq
    const tempUuid = `fake-${tempId}`

    const author = rootGetters['user/current']
    const authorName = [author.firstName, author.lastName]
      .map(s => s?.trim?.())
      .filter(Boolean)
      .join(' ') || author.userLogin

    const newMessage = {
      id: tempUuid,
      author: authorName,
      timestamp: moment().toISOString(),
      text,
      status: STATUS.clientNew,
      sourceType: SERVER_TYPE.frigate,
      children: [],
    }

    // setting a temporary message
    commit('setOne', { projectUuid, issueUuid, message: newMessage })

    // submitting message
    return IssueService.postChatMessage({
      issueId: issueUuid,
      body: {
        parentID: parentUuid ?? undefined, // null can be rejected sometimes
        text,
      },
    })
      .then(message => {
        // on success remove tmp pending message (in `clientNew` status)
        commit('removeOne', { issueUuid, messageUuid: newMessage.id })
        commit('setOne', { projectUuid, issueUuid, message })
        if (parentUuid) {
          return dispatch('getForIssue', {
            projectUuid,
            issueUuid,
          })
        }
      })
      .catch(error => {
        // on failure set tmp pending message to `failed` status
        commit('setOne', {
          projectUuid,
          issueUuid,
          message: {
            ...state.messages[newMessage.id],
            status: STATUS.failed,
          },
        })
        return handleError({ commit }, error)
      })
  },
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
