<template>
  <v-menu
    v-model="isOpen"
    :close-on-content-click="false"
    min-width="290px"
    class="AppDatePicker"
    :class="{ 'AppDatePicker--disabled': disabled }"
  >
    <template #activator="{ on: menuOn, attrs: menuAttrs }">
      <AppTextField
        class="AppDatePicker__text-field"
        :value="value"
        :label="label"
        :filled="filled"
        :outlined="outlined"
        :dense="dense"
        :name="name"
        :error-messages="errorMessages"
        :class="textFieldClassComputed"
        :style="textFieldStyle"
        readonly
        :disabled="disabled"
        prepend-inner-icon="$calendar"
        v-bind="menuAttrs"
        v-on="{ ...$listeners, ...menuOn }"
        @click.native="!disabled && ! readonly && (isOpen = true)"
      />
    </template>
    <v-date-picker
      v-model="model"
      @input="isOpen = false"
    />
  </v-menu>
</template>

<script>
import moment from 'moment'

import { expandVueClasses } from '../helpers'

export default {
  name: 'AppDatePicker',

  props: {
    value: {
      type: String,
      default: null,
      validator: date => date === null || moment(date).isValid(),
    },
    errorMessages: { type: Array, default: () => [] },

    label: { type: String, default: null },
    name: { type: String, default: null },
    filled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },

    textFieldClass: { type: [String, Array, Object], default: '' },
    textFieldStyle: { type: [String, Object], default: '' },
  },

  data: () => ({
    isOpen: false,
  }),

  computed: {
    model: {
      get() { return this.value && moment(this.value).format('YYYY-MM-DD') },
      set(value) {
        this.$emit('input', value)
        return value
      },
    },

    textFieldClassComputed() {
      const { textFieldClass, disabled } = this
      const fromProps = expandVueClasses(textFieldClass)
      return {
        ...fromProps,
        'AppDatePicker__text-field--disabled': disabled,
      }
    },
  },

  methods: {
    // NB! public method, accessed from outside
    open() { this.isOpen = true },
  },
}
</script>

<style lang="sass" scoped>
.AppDatePicker
  &__text-field
    ::v-deep
      :not(.v-text-field__details)
        cursor: pointer
      .v-text-field__details,
      .v-text-field__details *
        cursor: default
      .v-input__slot
        padding: 0 12px !important

.AppDatePicker__text-field--disabled
  &, ::v-deep *
    cursor: default !important
</style>
