<template>
  <div class="TheNavigationDrawerProjectSelector">
    <div
      v-ripple="{ class: 'app-ripple' }"
      class="TheNavigationDrawerProjectSelector__activator"
      tabindex="0"
      role="button"
      @click="menuIsOpen = !menuIsOpen"
      @keydown.space="menuIsOpen = !menuIsOpen"
      @keydown.enter="menuIsOpen = !menuIsOpen"
    >
      <div class="TheNavigationDrawerProjectSelector__label">
        {{ $t('layout.Project') }}
      </div>
      <v-row
        no-gutters
        class="align-center justify-space-between flex-nowrap"
      >
        <div class="TheNavigationDrawerProjectSelector__project-name">
          <span
            class="text-truncate"
            v-text="projectId ? (currentProject && currentProject.name) : $t('layout.SelectProject')"
          />
        </div>
        <v-icon
          class="TheNavigationDrawerProjectSelector__menu-icon flex-shrink-0"
          :style="menuIsOpen ? { transform: 'rotate(180deg)' } : {}"
          v-text="'mdi-menu-down'"
        />
      </v-row>
    </div>
    <v-expand-transition>
      <div v-show="menuIsOpen">
        <v-list class="TheNavigationDrawerProjectSelector__menu-list">
          <v-list-item
            key="all-projects"
            :to="{ name: 'Projects' }"
            :ripple="{ class: 'app-ripple' }"
            color="primary"
            class="TheNavigationDrawerProjectSelector__menu-item TheNavigationDrawerProjectSelector__menu-item--project"
          >
            <v-list-item-title>{{ $t('layout.AllProjects') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="project in projects"
            :key="project.id"
            :to="getSwitchToProjectRoute(project.id)"
            :ripple="{ class: 'app-ripple' }"
            class="TheNavigationDrawerProjectSelector__menu-item"
          >
            <v-list-item-title class="TheNavigationDrawerProjectSelector__menu-project-name">
              {{ project.name }}
            </v-list-item-title>
            <v-list-item-icon v-if="projectId === project.id">
              <v-icon
                color="primary"
                v-text="'mdi-check'"
              />
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import Project from '../store/orm/project'

export default {
  name: 'TheNavigationDrawerProjectSelector',

  props: {
    projectId: { type: String, default: null },
  },

  data() {
    return {
      menuIsOpen: false,
    }
  },

  computed: {
    currentProject() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },

    projects() {
      return Project.all()
    },
  },

  watch: {
    '$route.path'() { this.menuIsOpen = false },
  },

  methods: {
    getSwitchToProjectRoute(projectId) {
      // task#3447 - switching projects should preserve page whenever possible,
      // plus we omit query/hash parts of the route, because they are often project-specific
      if (this.$route.params?.projectId) {
        return { name: this.$route.name, params: { projectId } }
      } else {
        return { name: 'Project', params: { projectId } }
      }
    },
  },
}
</script>

<style lang="sass">
.TheNavigationDrawerProjectSelector
  $self: &

  min-height: 64px
  overflow: hidden

  &__activator
    padding: 13px 12px 5px 20px
    height: 64px
    border-bottom: 2px solid #E6E6F2
    outline: none !important

    &:hover, &:active, &:focus
      #{$self}__project-name, #{$self}__menu-icon
        color: var(--v-primary-base)

  &__label
    user-select: none
    font-weight: 400
    font-size: 12px
    line-height: 18px
    letter-spacing: 0.005em
    color: #A09EB9

  &__project-name
    font-weight: 500
    font-size: 18px
    line-height: 28px
    letter-spacing: 0.005em

    overflow: hidden
    text-overflow: ellipsis
    user-select: none
    transition: all 150ms ease-out

  &__menu-list
    overflow: hidden auto
    max-height: 40vh
    border-bottom: 2px solid #E6E6F2 !important
    background: #EDEEF5 !important
    padding: 8px 0

  &__menu-item
    font-weight: 500
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.005em
    padding-right: 12px !important

    &--project
      color: var(--v-primary-base) !important

    & + &
      margin-top: 8px

  &__menu-project-name
    white-space: normal
</style>
