var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"DefaultLayout",class:{ 'DefaultLayout--disable': ['Auth', 'Register'].includes(_vm.$route.name) }},[_c('v-navigation-drawer',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideDrawer),expression:"onClickOutsideDrawer"}],staticClass:"DefaultLayout__app-drawer",class:{ 'DefaultLayout__app-drawer--mini': !_vm.notificationDrawer && _vm.miniDrawer },attrs:{"value":!!_vm.$route.meta.appDrawer || _vm.notificationDrawer,"mini-variant":_vm.miniDrawer && !_vm.notificationDrawer,"mini-variant-width":64,"app":_vm.$vuetify.breakpoint.lgAndUp || (!_vm.notificationDrawer && _vm.miniDrawer),"fixed":_vm.$vuetify.breakpoint.mdAndDown && !(!_vm.notificationDrawer && _vm.miniDrawer),"right":"","stateless":!_vm.notificationDrawer,"color":"darkBackground","width":"360","dark":""},on:{"input":function($event){_vm.notificationDrawer && !$event && (_vm.notificationDrawer = false)}}},[_c('router-view',{attrs:{"name":"appDrawer"}})],1),_c('TheNavigationDrawer',{attrs:{"project-id":_vm.$route.params.projectId || null}}),_c('v-navigation-drawer',{staticClass:"DefaultLayout__notification-drawer",attrs:{"app":_vm.$vuetify.breakpoint.lgAndUp && _vm.settingsDrawer,"right":"","width":"550","fixed":"","temporary":"","hide-overlay":true},model:{value:(_vm.settingsDrawer),callback:function ($$v) {_vm.settingsDrawer=$$v},expression:"settingsDrawer"}},[_c('NotificationDrawer')],1),_c('router-view',{attrs:{"name":"outsideMain"}}),_c('v-main',{staticClass:"DefaultLayout__content"},[(_vm.licenseExpiresSoonOrHasExpired && _vm.showLicenseStatusBar)?_c('LicenseStatusBar',{attrs:{"start-date":_vm.currentLicense.startDate,"end-date":_vm.currentLicense.endDate},on:{"close":_vm.close}}):_vm._e(),_c('keep-alive',{attrs:{"include":[
        'IssueList',
        'CardIssueList',
        'ProjectIssueList',
        'ProjectCardIssueList',
        'Auth',
      ]}},[_c('router-view')],1),_c('TheSnackbar'),_c('TheAjaxBar',{staticClass:"DefaultLayout__loader",class:{
        'DefaultLayout__loader--push-top':
          ['Auth', 'Register'].includes(_vm.$route.name) ||
          _vm.$store.state.defaultLayout.pushAjaxLoaderTop,
      }})],1),_c('DialogRoot'),_c('LicenseDialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }