<template>
  <v-pagination
    class="AppPagination"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'AppPagination',
  inheritAttrs: false,
}
</script>

<style lang="sass" scoped>
  .AppPagination

    ::v-deep .v-pagination__item,
    ::v-deep .v-pagination__navigation,
    ::v-deep .v-pagination__more
      width: 36px
      height: 36px
      border-radius: 4px
      background: white
      box-shadow: none
      color: #8B90A0
      margin: 0
      outline: none
      font-size: 16px
      font-weight: 500
      align-items: center

      &:not(.v-pagination__more):hover,
      &.v-pagination__item--active
        background: #F8F8FD !important

      .v-icon
        color: #8B90A0

    ::v-deep .v-pagination__item--active
      color: #1066E6
</style>
