<template>
  <v-switch
    class="AppSwitch"
    :class="{ 'AppSwitch--reversed': reversed }"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'AppSwitch',

  inheritAttrs: false,

  model: {
    prop: 'inputValue',
    event: 'change',
  },

  props: {
    reversed: { type: Boolean, default: true },
  },
}
</script>

<style lang="sass" scoped>
.AppSwitch
  &--reversed ::v-deep .v-input--selection-controls__input
    order: 1
    margin-left: 16px
    margin-right: 8px
</style>
