// Integration with external apps (Jira, Slack, etc.)

import * as R from 'ramda'
import Vue from 'vue'

import { IntegrationService } from '../../api'
import { INTEGRATION, INTEGRATION_STATE, JIRA } from '../../constants'
import { handleError, mapErrorMessage } from '../../helpers'
import i18n from '../../i18n'

// const integrationMeta = {
//   project: {
//     self: 'https://dhd-integration.atlassian.net/rest/api/2/project/10001',
//     id: '10001',
//     key: 'PMT',
//     name: 'prj-mgmt-test',
//     avatarUrls: {
//       '48x48': 'https://dhd-integration.atlassian.net/secure/projectavatar?pid=10001&avatarId=10419',
//       '24x24': 'https://dhd-integration.atlassian.net/secure/projectavatar?size=small&s=small&pid=10001&avatarId=10419',
//       '16x16': 'https://dhd-integration.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10001&avatarId=10419',
//       '32x32': 'https://dhd-integration.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10001&avatarId=10419',
//     },
//     issuetypes: [
//       {
//         self: 'https://dhd-integration.atlassian.net/rest/api/2/issuetype/10001',
//         id: '10001',
//         description: 'A small, distinct piece of work.',
//         iconUrl: 'https://dhd-integration.atlassian.net/secure/viewavatar?size=medium&avatarId=10318&avatarType=issuetype',
//         name: 'Task',
//         untranslatedName: 'Task',
//         subtask: false,
//       },
//       {
//         self: 'https://dhd-integration.atlassian.net/rest/api/2/issuetype/10002',
//         id: '10002',
//         description: "A small piece of work that's part of a larger task.",
//         iconUrl: 'https://dhd-integration.atlassian.net/secure/viewavatar?size=medium&avatarId=10316&avatarType=issuetype',
//         name: 'Sub-task',
//         untranslatedName: 'Sub-task',
//         subtask: true,
//       },
//     ],
//   },
//   issue: {
//     self: 'https://dhd-integration.atlassian.net/rest/api/2/issuetype/10001',
//     id: '10001',
//     description: 'A small, distinct piece of work.',
//     iconUrl: 'https://dhd-integration.atlassian.net/secure/viewavatar?size=medium&avatarId=10318&avatarType=issuetype',
//     name: 'Task',
//     untranslatedName: 'Task',
//     subtask: false,
//     expand: 'fields',
//     fields: {
//       summary: {
//         required: true,
//         schema: { type: 'string', system: 'summary' },
//         name: 'Summary',
//         key: 'summary',
//         hasDefaultValue: false,
//         operations: ['set'],
//       },
//       issuetype: {
//         required: true,
//         schema: { type: 'issuetype', system: 'issuetype' },
//         name: 'Issue Type',
//         key: 'issuetype',
//         hasDefaultValue: false,
//         operations: [],
//         allowedValues: [{
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/issuetype/10001',
//           id: '10001',
//           description: 'A small, distinct piece of work.',
//           iconUrl: 'https://dhd-integration.atlassian.net/secure/viewavatar?size=medium&avatarId=10318&avatarType=issuetype',
//           name: 'Task',
//           subtask: false,
//           avatarId: 10318,
//         }],
//       },
//       attachment: {
//         required: false,
//         schema: {
//           type: 'array',
//           items: 'attachment',
//           system: 'attachment',
//         },
//         name: 'Attachment',
//         key: 'attachment',
//         hasDefaultValue: false,
//         operations: ['set'],
//       },
//       duedate: {
//         required: false,
//         schema: { type: 'date', system: 'duedate' },
//         name: 'Due date',
//         key: 'duedate',
//         hasDefaultValue: false,
//         operations: ['set'],
//       },
//       description: {
//         required: false,
//         schema: { type: 'string', system: 'description' },
//         name: 'Description',
//         key: 'description',
//         hasDefaultValue: false,
//         operations: ['set'],
//       },
//       project: {
//         required: true,
//         schema: { type: 'project', system: 'project' },
//         name: 'Project',
//         key: 'project',
//         hasDefaultValue: false,
//         operations: ['set'],
//         allowedValues: [{
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/project/10000',
//           id: '10000',
//           key: 'TTT',
//           name: 'task-track-test',
//           projectTypeKey: 'business',
//           simplified: false,
//           avatarUrls: {
//             '48x48': 'https://dhd-integration.atlassian.net/secure/projectavatar?pid=10000&avatarId=10407',
//             '24x24': 'https://dhd-integration.atlassian.net/secure/projectavatar?size=small&s=small&pid=10000&avatarId=10407',
//             '16x16': 'https://dhd-integration.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10000&avatarId=10407',
//             '32x32': 'https://dhd-integration.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10000&avatarId=10407',
//           },
//         }],
//       },
//       reporter: {
//         required: true,
//         schema: { type: 'user', system: 'reporter' },
//         name: 'Reporter',
//         key: 'reporter',
//         autoCompleteUrl: 'https://dhd-integration.atlassian.net/rest/api/2/user/search?query=',
//         hasDefaultValue: true,
//         operations: ['set'],
//       },
//       assignee: {
//         required: false,
//         schema: { type: 'user', system: 'assignee' },
//         name: 'Assignee',
//         key: 'assignee',
//         autoCompleteUrl: 'https://dhd-integration.atlassian.net/rest/api/2/user/assignable/search?project=TTT&query=',
//         hasDefaultValue: false,
//         operations: ['set'],
//       },
//       priority: {
//         required: false,
//         schema: { type: 'priority', system: 'priority' },
//         name: 'Priority',
//         key: 'priority',
//         hasDefaultValue: true,
//         operations: ['set'],
//         allowedValues: [{
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/priority/1',
//           iconUrl: 'https://dhd-integration.atlassian.net/images/icons/priorities/highest.svg',
//           name: 'Highest',
//           id: '1',
//         },
//         {
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/priority/2',
//           iconUrl: 'https://dhd-integration.atlassian.net/images/icons/priorities/high.svg',
//           name: 'High',
//           id: '2',
//         },
//         {
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/priority/3',
//           iconUrl: 'https://dhd-integration.atlassian.net/images/icons/priorities/medium.svg',
//           name: 'Medium',
//           id: '3',
//         },
//         {
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/priority/4',
//           iconUrl: 'https://dhd-integration.atlassian.net/images/icons/priorities/low.svg',
//           name: 'Low',
//           id: '4',
//         },
//         {
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/priority/5',
//           iconUrl: 'https://dhd-integration.atlassian.net/images/icons/priorities/lowest.svg',
//           name: 'Lowest',
//           id: '5',
//         }],
//         defaultValue: {
//           self: 'https://dhd-integration.atlassian.net/rest/api/2/priority/3',
//           iconUrl: 'https://dhd-integration.atlassian.net/images/icons/priorities/medium.svg',
//           name: 'Medium',
//           id: '3',
//         },
//       },
//       labels: {
//         required: false,
//         schema: { type: 'array', items: 'string', system: 'labels' },
//         name: 'Labels',
//         key: 'labels',
//         autoCompleteUrl: 'https://dhd-integration.atlassian.net/rest/api/1.0/labels/suggest?query=',
//         hasDefaultValue: false,
//         operations: ['add', 'set', 'remove'],
//       },
//     },
//   },
// }

// Turns integrations response:
// { JIRA: [<Integration 1>, <Integration 2>], SLACK: [<Integration 3>] }
// Into a flat list:
// [<Integration 1>, <Integration 2>, <Integration 3>]
// Also adds `integrationCode` property ('JIRA', 'SLACK', etc.)
const unpackGroupedIntegrations = groupedIntegrations =>
  Object
    .keys(INTEGRATION)
    .reduce((integrations, code) => {
      if (groupedIntegrations[code]) {
        integrations.push(...groupedIntegrations[code]
          .map(integration => ({
            ...integration,
            integrationCode: code,
          })))
      } else {
        console.warn(`No ${code} integrations`)
      }
      return integrations
    }, [])

const initState = () => ({
  // frigate-side integrations themselves
  integrations: {}, // Object<IntegrationId, Integration>
  // some meta like jira issue types and project details
  integrationMeta: {}, // Object<IntegrationId, IntegrationMetadata>
  // mapping from projects to their integrations
  projectIntegrations: {}, // Object<ProjectId, IntegrationId[]>
  // integration servers
  integrationServers: {}, // Object<IntegrationServerId, IntegrationServer>
})

const getters = {
  // get one integration by id, also adds `meta` param
  get: ({ integrations, integrationMeta }) =>
    integrationId => {
      const integration = integrations[integrationId]
      const meta = integrationMeta[integrationId] ?? null
      if (integration == null) return null
      return {
        ...integration,
        integrationType: INTEGRATION[integration.integrationCode],
        isDraft: integration.state === INTEGRATION_STATE.CREATED,
        meta,
      }
    },

  // get all integrations for the project with the given `projectId`
  forProject: ({ projectIntegrations }, { get }) =>
    projectId =>
      projectIntegrations[projectId]?.map?.(id => get(id)),

  integrationServers: ({ integrationServers }) => integrationServers,

  getIntegrationServer: ({ integrationServers }) =>
    serverId =>
      integrationServers[serverId] || null,
}

const mutations = {
  // resets module state to the initial values
  reset: state => Object.assign(state, initState()),

  // stores a single integration
  setIntegration: (
    { integrations: byId, projectIntegrations },
    { integration, projectId = null },
  ) => {
    Vue.set(byId, integration.id, integration)

    if (projectId != null) {
      const order = projectIntegrations[projectId]
      if (order) {
        if (!order.includes(integration.id)) order.push(integration.id)
      } else {
        Vue.set(projectIntegrations, projectId, [integration.id])
      }
    }
  },

  // set `integrations` for the project with the given `projectId`
  setIntegrations: (
    { integrations: byId, projectIntegrations },
    { projectId, integrations },
  ) => {
    integrations.forEach(integration =>
      Vue.set(byId, integration.id, integration))
    Vue.set(projectIntegrations, projectId, integrations.map(i => i.id))
  },

  setMeta: ({ integrationMeta }, { integrationId, meta }) => {
    Vue.set(integrationMeta, integrationId, meta)
  },

  removeIntegration: (
    { integrations: byId, integrationMeta, projectIntegrations },
    { integrationId, projectId = null },
  ) => {
    if (projectId && projectIntegrations[projectId]?.includes?.(integrationId)) {
      projectIntegrations[projectId] = projectIntegrations[projectId]
        .filter(id => id !== integrationId)
    } else {
      Object.entries(projectIntegrations)
        .filter(([_, integrationIds]) =>
          integrationIds.includes(integrationId))
        .forEach(([projectId, integrationIds]) => {
          projectIntegrations[projectId] = integrationIds
            .filter(id => id !== integrationId)
        })
    }

    Vue.delete(byId, integrationId)
    Vue.delete(integrationMeta, integrationId)
  },

  setIntegrationServers: ({ integrationServers }, { servers }) => {
    servers.forEach(server => {
      Vue.set(integrationServers, server.id, server)
    })
  },

  setIntegrationServer: ({ integrationServers }, { server }) => {
    Vue.set(integrationServers, server.id, server)
  },

  deleteIntegrationServer: ({ integrationServers }, { serverId }) => {
    Vue.delete(integrationServers, serverId)
  },

  setIntegrationServerDetails: ({ integrationServers }, { serverId, details }) => {
    Vue.set(integrationServers[serverId], 'details', details)
  },
}

const actions = {
  // get integrations list for a single project
  getForProject: async (ctx, {
    projectId,
    reload = true,
    skipDefaultErrorHandling = false,
  }) => {
    const { commit, getters } = ctx

    if (!reload && getters.forProject(projectId)) return

    return IntegrationService.projectIntegrationsGet({ projectId })
      .then(unpackGroupedIntegrations)
      .then(integrations =>
        commit('setIntegrations', { projectId, integrations }))
      .catch(e => {
        if (skipDefaultErrorHandling) throw e
        return handleError(ctx, e)
      })
  },

  // create an integration (bound to a project)
  createForProject: (ctx, {
    projectId,
    integration: { integrationCode, name, username, hostname: { hostname }, token, hostname: { id } },
  }) => {
    const { commit } = ctx
    const integration = {
      integrationType: integrationCode,
      data: { name, username, hostname, token, serverID: id },
    }
    return IntegrationService.projectIntegrationsPost({
      projectId,
      body: integration,
    })
      .then(integration => {
        commit('setIntegration', {
          projectId,
          integration: {
            ...integration,
            integrationCode,
          },
        })
        return integration.id
      })
      .catch(e => handleError(ctx, e))
  },

  // update Jira integration
  updateJira: (ctx, { integration, integrationId = null }) => {
    const { commit } = ctx

    return IntegrationService.projectJiraIntegrationPatch({
      integrationId,
      body: R.pipe(
        R.omit(['id']),
        R.when(
          integration => !integration.token?.trim?.(),
          R.omit(['token']),
        ),
      )(integration),
    })
      .then(integration => {
        integration = {
          ...integration,
          integrationCode: JIRA,
        }
        commit('setIntegration', {
          integrationId,
          integration,
        })
        return integration.id
      })
      .catch(e => handleError(ctx, e))
  },

  // get metadata about project and issue types of the connection,
  // for JIRA it is obtained through API call to remote JIRA
  getJiraMeta: (ctx, { /* projectId, */ integrationId, reload = true, initialQuery = false }) => {
    const { commit, getters } = ctx

    if (!reload && getters.get(integrationId)?.meta) return

    const mapError = mapErrorMessage(e => {
      if (e.response?.status === 400) {
        return i18n.t('integration.ErrorUnableToConnectToJiraM')
      }
      if (e.response?.status === 500) {
        return i18n.t('integration.ErrorUnableToConnectToJiraServerErrorM')
      }
    })

    const params = {
      integrationId,
    }

    if (initialQuery) params.initialQuery = initialQuery

    return IntegrationService.projectJiraIntegrationsMeta(params)
    // return wait(900)
    //   .then(() => R.clone(integrationMeta))
      .then(meta => commit('setMeta', { integrationId, meta }))
      .catch(e => handleError(ctx, mapError(e)))
  },

  /** get JIRA server info by hostname, namely auth type
   * @typedef {Object} GetJiraInfoResponse
   * @property {"BASIC"|"CLOUD"|"TOKEN"} authentication
   * @returns {Promise<GetJiraInfoResponse>}
   */
  getJiraInfo: async (ctx, { /* projectId, */ hostname }) => {
    try {
      return await IntegrationService.getJiraApplicationVersion({
        body: { hostname },
      })
      // const authentication = {
      //   'http://cloud.example.com': 'CLOUD',
      //   'https://cloud.example.com': 'CLOUD',
      //   'http://basic.example.com': 'BASIC',
      //   'https://basic.example.com': 'BASIC',
      //   'http://token.example.com': 'TOKEN',
      //   'https://token.example.com': 'TOKEN',
      // }[hostname] ?? 'CLOUD'
      // return { authentication }
    } catch (e) {
      await handleError(ctx, e)
      throw e
    }
  },

  // export issues as Jira tasks via integration
  exportToJira: (_, { integrationId, issues }) =>
    IntegrationService.exportIssuesToJira({
      integrationId,
      body: { issues },
    }),

  delete: (
    { commit },
    {
      integration: { id: integrationId, integrationCode },
      projectId = null,
      commitChanges = true,
    },
  ) => {
    let deleted = null
    if (integrationCode === JIRA) {
      deleted = IntegrationService
        .projectJiraIntegrationDelete({ integrationId })
    } else {
      throw new Error(i18n.t('integration.ErrorCannotDeleteIntegrationM', { integrationCode }))
    }
    return deleted
      .then(() =>
        commitChanges &&
        commit('removeIntegration', { projectId, integrationId }))
  },

  getIntegrationServers: ({ commit }) => IntegrationService.integrationServerListGet()
    .then(servers => commit('setIntegrationServers', { servers })),

  postIntegrationServer: (ctx, { hostname, name }) => {
    const { commit } = ctx

    return IntegrationService.integrationServerPost({ body: { hostname, name } })
      .then((server) => {
        commit('setIntegrationServer', { server })
      }).catch(e => handleError(ctx, e))
  },

  patchIntegrationServer: (ctx, { serverId, hostname, name }) => {
    const { commit } = ctx

    return IntegrationService.integrationServerPatch({ serverId, body: { hostname, name } })
      .then((server) => {
        commit('setIntegrationServer', { server })
      }).catch(e => handleError(ctx, e))
  },

  deleteIntegrationServer: (ctx, { serverId }) => {
    const { commit } = ctx

    IntegrationService.integrationServerDelete({ serverId })
      .then(() => {
        commit('deleteIntegrationServer', { serverId })
      }).catch(e => handleError(ctx, e))
  },

  getIntegrationServerDetails: (ctx, { serverId }) => IntegrationService.integrationServerDetailsGet({ serverId }),

  integrationJiraSearch: (_, { integrationId, field, query }) => {
    return IntegrationService.searchItemsJira({ integrationId, field, query })
  },

  regenerateToken: (ctx, { serverId }) => {
    const { commit } = ctx

    return IntegrationService.integrationServerRegenerateToken({ serverId })
      .then((server) => {
        commit('setIntegrationServer', { server })
      }).catch(e => handleError(ctx, e))
  },
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
