<template>
  <div class="DialogRoot">
    <template v-if="confirmDialogs.length">
      <ConfirmationDialog
        v-for="dialogState in confirmDialogs"
        :key="'confirm-' + dialogState.id"
        :dialog-state="dialogState"
      />
    </template>

    <template v-if="commonDialogs.length">
      <component
        :is="dialog.componentName"
        v-for="dialog in commonDialogs"
        :key="'common-dialog-' + dialog.id"
        :dialog-instance="dialog"
        v-bind="dialog.props"
        v-on="dialog.listeners"
      />
    </template>
  </div>
</template>

<script>
import Dialog from '../store/orm/dialog'

export default {
  name: 'DialogRoot',

  components: {
    ConfirmationDialog: () => import(
      /* webpackChunkName: "confirmation-dialog" */
      '../components/ConfirmationDialog'),

    // All common dialog components, opened through `dialog.js` should be imported here
    AppConnectionProjectsDialog: () => import(
      /* webpackChunkName: "app-connection-projects-dialog" */
      '../components/AppConnectionProjectsDialog'),
    CsvExportIssuesDialog: () => import(
      /* webpackChunkName: "csv-export-issues-dialog" */
      '../components/CsvExportIssuesDialog'),
    ExportTasksCreateDialog: () => import(
      /* webpackChunkName: "export-tasks-create-dialog" */
      '../components/ExportTasksCreateDialog'),
    ExportTasksDialog: () => import(
      /* webpackChunkName: "export-tasks-dialog" */
      '../components/ExportTasksDialog'),
    ExportTasksLinkDialog: () => import(
      /* webpackChunkName: "export-tasks-link-dialog" */
      '../components/ExportTasksLinkDialog'),
    HawserConnectionDialog: () => import(
      /* webpackChunkName: "hawser-connection-dialog" */
      '../components/HawserConnectionDialog'),
    ProjectGroupDialog: () => import(
      /* webpackChunkName: "project-group-dialog" */
      '../components/ProjectGroupDialog'),
    TeamDialog: () => import(
      /* webpackChunkName: "team-dialog" */
      '../components/TeamDialog'),
  },

  computed: {
    confirmDialogs() {
      return this.$store.state.confirm.dialogs
    },

    commonDialogs() {
      return Dialog
        .query()
        .orderBy('id')
        .all()
    },
  },

  watch: {
    '$route.fullPath'() {
      Dialog
        .query()
        .where('persistRouteChanges', false)
        .orderBy('id')
        .all()
        .forEach(dialog => dialog.close())
    },
  },
}
</script>
