import { Model } from '@vuex-orm/core'
import * as R from 'ramda'

import { ProjectService } from '../../api'
import { handleError } from '../../helpers'
import { Issue, ProjectGroup } from './_models'

export class Project extends Model {
  static entity = 'project'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.string(null).nullable(),
      name: this.string(null).nullable(),
      projectType: this.string(null).nullable(),
      scope: this.string(null).nullable(),
      description: this.string(null).nullable(),

      startDate: this.string(null).nullable(),
      completionDate: this.string(null).nullable(),

      hawserID: this.string(null).nullable(),
      connectionName: this.string(null).nullable(),
      lastIncomingPong: this.string(null).nullable(),
      lastOutgoingPing: this.string(null).nullable(),
      applicationConnect: this.attr(null).nullable(),

      owner: this.attr(null).nullable(),
      permission: this.string(null).nullable(),
      teamUsers: this.attr(null).nullable(),

      groupID: this.string(null).nullable(),
      group: this.belongsTo(ProjectGroup, 'groupID'),

      issues: this.hasMany(Issue, 'projectID'),
    }
  }

  get isConnected() { return this.hawserID != null }

  getGroupQ() {
    return ProjectGroup.query().where('id', this.groupID)
  }

  _searchIndex = null

  get searchIndex() {
    if (this._searchIndex != null) return this._searchIndex
    return (this._searchIndex = this._calcSearchIndex())
  }

  _calcSearchIndex() {
    return [
      this.name,
      this.projectType,
      // this.scope,
      this.description,
    ]
      .filter(Boolean)
      .map(s => s.trim().toLowerCase())
      .flatMap(s => s.split(/\s+?/g).filter(Boolean))
      .join(' ')
  }
}

export const ProjectModule = {
  actions: {
    $getList: (ctx, { reload = true } = {}) =>
      !reload && Project.query().exists()
        ? Promise.resolve(undefined)
        : ProjectService.projectsGet()
          .then(projects => Project.create({ data: projects }))
          .catch(e => handleError(ctx, e)),

    $getOne: (ctx, { projectId, reload = true }) =>
      !reload && Project.query().whereId(projectId).exists()
        ? Promise.resolve(undefined)
        : ProjectService.projectGet({ projectId })
          .then(project => Project.insert({ data: project }))
          .catch(e => handleError(ctx, e)),

    $create: (ctx, { project, localUsers = [] }) =>
      ProjectService.projectsPost({ body: { projectData: project, localUsers } })
        .then(async project => {
          await Project.insert({ data: project })
          return project.id
        })
        .catch(e => handleError(ctx, e)),

    $patch: (ctx, { project }) =>
      ProjectService.projectPatch({
        projectId: project.id,
        body: R.omit(['$id', 'id', 'hawserID'], project),
      })
        .then(async project => {
          await Project.insert({ data: project })
          return project.id
        })
        .catch(e => handleError(ctx, e)),

    $delete: (ctx, { projectId }) =>
      ProjectService.projectDelete({ projectId })
        .then(() => Project.delete(projectId))
        .catch(e => handleError(ctx, e)),

  },
}

export default Project
