<template>
  <div class="NotificationDrawer">
    <div
      class="NotificationDrawer__toolbar"
    >
      <h2 class="display-1">
        {{ $t('notification.Notifications') }}
      </h2>

      <v-btn
        class="NotificationDrawer__toolbar-close"
        icon
        @click="toggleSettingsDrawer"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <v-tabs
        v-model="activeTab"
        class="NotificationDrawer__toolbar-tabs"
        background-color="#F5F5F9"
        :height="40"
        hide-slider
        color="#41415A"
      >
        <v-tab
          v-for="item in TABS"
          :key="item.name"
          :href="`#${item.name}`"
        >
          <v-icon color="#90A4AE">
            {{ item.icon }}
          </v-icon>
          <span class="ml-1">{{ $t(item.displayName) }}</span>
        </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items
      v-model="activeTab"
    >
      <v-tab-item
        v-for="item in TABS"
        :key="item.name"
        :value="item.name"
      >
        <v-card
          class="NotificationDrawer__card"
          flat
        >
          <v-card-text v-if="item.name === 'events'">
            <v-virtual-scroll
              :items="sortedNotifications || []"
              :bench="bench"
              :height="`calc(${baseHeight})`"
              :item-height="124"
            >
              <template #default="{ item: notification }">
                <v-list-item
                  :key="notification.id"
                  class="NotificationDrawer__item pt-2 pb-2"
                  :to="itemRoute(notification)"
                  :ripple="{ class: 'app-ripple' }"
                  dense
                  @click.native="
                    !notification.read && markRead(notification.id);
                    $store.commit('appDrawer/setNotificationDrawer', false)
                  "
                >
                  <v-list-item-avatar
                    width="8"
                    min-width="8"
                    class="ml-4"
                  >
                    <v-avatar
                      size="8"
                      :color="notification.read ? 'transparent' : 'error'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="NotificationDrawer__item-title mb-2">
                      <span>{{ projectName(notification.projectID) }}</span>
                      <time
                        :time="notification.created"
                        :title="fmt(notification.created, 'llll')"
                        class="ml-auto"
                        v-text="fmtDt(notification.created)"
                      />
                    </v-list-item-title>
                    <v-tooltip
                      bottom
                      open-delay="300"
                    >
                      <template #activator="{ on, attrs }">
                        <v-list-item-subtitle
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div class="mb-2">
                            <v-icon
                              :color="getIconColor(notification)"
                              :style="{ backgroundColor: getBackgroundColor(notification), padding: '2px', borderRadius: '4px' }"
                            >
                              {{ getIcon(notification) }}
                            </v-icon>
                            <span
                              class="NotificationDrawer__item-subtitle ml-2"
                              v-text="getTitle(notification)"
                            />
                          </div>
                          <span>{{ notification.message }}</span>
                        </v-list-item-subtitle>
                      </template>
                      <span v-text="notification.message" />
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-text
            v-else-if="item.name === 'settings'"
            :style="{ height: 'calc(100vh - 134px - 24px)' }"
          >
            <div
              v-for="type in NOTIFICATION_SETTING_TYPE"
              :key="type"
              class="NotificationDrawer__setting-item"
            >
              <div
                v-for="setting in NOTIFICATION_SETTINGS"
                :key="setting.settingsProp"
              >
                <div
                  v-if="setting.main && type === setting.type"
                  class="NotificationDrawer__title"
                >
                  <v-icon color="#A09EB9">
                    mdi-square-rounded-badge-outline
                  </v-icon>
                  <h3 class="ml-3">
                    {{ $t(setting.label) }}
                  </h3>
                  <v-switch
                    v-model="notificationModel[setting.settingsProp]"
                    class="ml-auto"
                    :label="notificationModel[setting.settingsProp] ? $t('notification.on') : $t('notification.off')"
                    @click="saveMainNotificationSettings(setting.settingsProp)"
                  />
                </div>
                <div
                  v-else-if="!setting.main && type === setting.type"
                  class="NotificationDrawer__setting-item-child"
                >
                  <p>
                    {{ $t(setting.label) }}
                  </p>
                  <v-switch
                    v-model="notificationModel[setting.settingsProp]"
                    class="NotificationDrawer__switcher ml-auto"
                    :label="notificationModel[setting.settingsProp] ? $t('notification.on') : $t('notification.off')"
                    @click="saveNotificationSettings(setting.settingsProp)"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <div
      v-if="TABS[activeTab].showFooter"
      class="NotificationDrawer__footer"
    >
      <v-btn
        color="primary"
        outlined
        :disabled="!anyUnread"
        @click="markAllRead"
      >
        {{ $t('notification.MarkAllRead') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'

import { NOTIFICATION_SETTING_ITEMS, NOTIFICATION_SETTING_TYPE } from '../constants'
import { fmtDt } from '../helpers'
import Project from '../store/orm/project'

const TABS = Object.freeze({
  events: {
    name: 'events',
    displayName: 'notification.Events',
    icon: 'mdi-calendar',
    id: 'events',
    showFooter: true,
  },
  settings: {
    name: 'settings',
    displayName: 'notification.Settings',
    icon: 'mdi-cog-outline',
    id: 'settings',
    showFooter: false,
  },
})

const DEFAULT_NOTIFICATION_SETTINGS = () => ({
  ...R.pipe(
    R.values,
    R.indexBy(R.prop('settingsProp')),
    R.map(() => true),
  )(NOTIFICATION_SETTING_ITEMS),
})

const NOTIFICATION_SETTINGS = R.pipe(
  R.values,
  R.sortBy(R.prop('type')),
  values => Object.freeze(values),
)(NOTIFICATION_SETTING_ITEMS)

export default {
  name: 'NotificationDrawer',

  props: {
    baseHeight: { type: String, default: '100vh - 184px - 24px' },
    bench: { type: Number, default: 2 }, // useful in tests
  },

  data() {
    return {
      NOTIFICATION_SETTING_ITEMS,
      TABS,
      NOTIFICATION_SETTINGS,
      NOTIFICATION_SETTING_TYPE,

      saving: false,
      activeTab: 'events',
      notificationModel: DEFAULT_NOTIFICATION_SETTINGS(),
    }
  },

  computed: {
    isOpen() { return this.$store.state.appDrawer.notificationDrawer },
    notifications() { return this.$store.state.notification.notifications },
    notificationSettings() { return this.$store.state.service.notificationSettings },
    anyUnread() { return this.$store.getters['notification/anyUnread'] },

    sortedNotifications() {
      const { notifications } = this
      return notifications && R.sortWith([
        // R.ascend(R.prop('read')), // unread first, task#1724
        R.descend(R.prop('created')), // newer before later
      ], notifications)
    },

    settingsDrawer: {
      get() { return this.$store.state.appDrawer.settingsDrawer },
      set(settingsDrawer) {
        this.$store.commit('appDrawer/setSettingsDrawer', settingsDrawer)
      },
    },

    mainSettings() {
      return Object.values(NOTIFICATION_SETTING_ITEMS).filter(item => item.main)
    },
  },

  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) { isOpen && this.fetchNotifications() },
    },

    notificationSettings: {
      immediate: true,
      deep: true,
      handler(settings) {
        this.notificationModel = settings
          ? { ...settings }
          : DEFAULT_NOTIFICATION_SETTINGS()
      },
    },
  },

  mounted() {
    this.$store.dispatch('service/getNotificationSettings')
  },

  methods: {
    fmtDt,
    fmt(dt, fmt) { return moment(dt).format(fmt) },

    fetchNotifications() {
      return this.$store.dispatch('notification/getList')
    },

    itemRoute(notification) {
      const {
        issue_id: issueId,
        project_id: projectId,
        message_id: messageId,
        jiraIssueKey,
      } = notification?.metaData || {}
      if (issueId && projectId) {
        return jiraIssueKey
          ? {
            name: 'ProjectIssueList',
            params: { projectId },
            query: { issueId },
          }
          : {
            name: 'ProjectIssueListIssue',
            params: { projectId, issueId },
            query: messageId ? { chat: 'on' } : {},
          }
      }
      if (projectId) {
        return {
          name: 'ProjectDashboard',
          params: { projectId },
        }
      }
      return undefined
    },

    markRead(notificationId) {
      return this.$store.dispatch('notification/markRead', { notificationId })
    },

    async markAllRead() {
      const { sortedNotifications } = this
      const unreadNotifications = sortedNotifications.filter(n => !n.read)

      this.saving = true
      try {
        const allMarkedRead = unreadNotifications
          .map(n => n.id)
          .map(notificationId => this.markRead(notificationId))
        await Promise.all(allMarkedRead)
      } finally {
        this.saving = false
      }
    },

    getTitle(notification) {
      return this.$t(NOTIFICATION_SETTING_ITEMS[notification.eventType]?.label) ||
        notification.eventType
    },

    getIcon(notification) {
      return NOTIFICATION_SETTING_ITEMS[notification.eventType]?.icon || ''
    },

    getIconColor(notification) {
      return NOTIFICATION_SETTING_ITEMS[notification.eventType]?.iconColor || '#111111'
    },

    getBackgroundColor(notification) {
      return NOTIFICATION_SETTING_ITEMS[notification.eventType]?.backgroundColor || 'transparent'
    },

    async saveMainNotificationSettings(item) {
      const { $store, notificationModel: settings, mainSettings } = this

      const notificationType = mainSettings?.find(type => type.settingsProp === item)?.type || null

      if (notificationType) {
        Object.keys(settings).map(key => {
          if (key.startsWith(notificationType.toString().toLowerCase())) settings[key] = settings[item]
        })
      }

      this.saving = true
      try {
        $store.dispatch('service/setNotificationSettings', { settings })
      } finally {
        this.saving = false
      }
    },

    async saveNotificationSettings(item) {
      const { $store, notificationModel: settings, mainSettings } = this

      const settingItem = Object.values(NOTIFICATION_SETTING_ITEMS)?.find(notification => notification.settingsProp === item)
      const mainItemName = mainSettings.map(item => item.settingsProp)?.find(item => item.startsWith(settingItem.type.toString().toLowerCase()))

      if (settings[item] && !settings[mainItemName]) settings[mainItemName] = true

      this.saving = true
      try {
        $store.dispatch('service/setNotificationSettings', { settings })
      } finally {
        this.saving = false
      }
    },

    projectName(projectId) {
      return Project.find(projectId)?.name ?? '...'
    },

    toggleSettingsDrawer() {
      this.settingsDrawer = !this.settingsDrawer
    },
  },
}
</script>

<style lang="sass" scoped>
.NotificationDrawer
  &__item
    &::before
      display: none

    &-title
      display: flex
      color: #A09EB9
      align-items: center

    &-subtitle
      color: #41415A
      font-size: 16px
      font-weight: 500

  &__toolbar
    height: 132px
    background: #F5F5F9
    display: flex
    flex-direction: row
    flex-wrap: wrap
    padding: 46px 32px 0 32px

    &-close
      position: absolute
      top: 0
      right: 0

    &-tabs
      align-self: flex-end
      font-size: 16px

      &::v-deep
        .v-tab--active
          background-color: white

  &__title
    display: flex
    align-items: center
    color: #41415A

  &__setting-item
    padding: 32px 32px 0 48px

    &-child
      display: flex
      align-items: baseline
      color: #41415A

  &__card
    overflow-y: auto

    &::v-deep
      .v-card__text
        padding: 0

  &__footer
    display: flex
    justify-content: flex-end
    align-items: center

    border-top: 1px solid rgba(0, 0, 0, .12)
    height: 76px
    padding: 0 16px
    background: #F5F5F9
</style>
