<template>
  <v-list
    v-if="users && users.length"
    class="AuthDemoUsers"
    :max-height="550"
    :max-width="260"
    style="overflow: hidden auto; text-overflow: ellipsis"
  >
    <h2 class="display-2 mt-5 ml-6 mb-2 py-2 text-truncate">
      {{ $t('user.DemoUsers') }}
    </h2>

    <v-list-item
      v-for="user in users"
      :key="user.userLogin"
      :ripple="{ class: 'app-ripple' }"
      class="AuthDemoUsers__user mt-1 py-2"
      style="height: 56px"
      @click="$emit('click:user', user)"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('user.DemoUserLogin', { userLogin: user.userLogin }) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('user.DemoUserPassword', { userPassword: user.userPassword }) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'AuthDemoUsers',

  computed: {
    // Example:
    // [
    //   { userLogin: 'spam', userPassword: 'spam' },
    //   { userLogin: 'ham@example.com', userPassword: 'ham' },
    // ]
    users() { return this.$store.state.ext.demoUsers },
  },
}
</script>
