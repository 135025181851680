import { axiosInstance as axios, ProjectService } from '../../api'
import { handleError, extractFilenameFromContentDisposition } from '../../helpers'
import database from '../orm'

const Project = database.model('project')

const getFormData = hashMap => {
  const formData = new FormData()
  Object.entries(hashMap).forEach(([key, value]) =>
    formData.append(key, value))
  return formData
}

const initState = () => ({})

export default {
  namespaced: true,

  state: initState,

  mutations: {
    reset: state => Object.assign(state, initState()),
  },

  actions: {
    // Initiates new hawser connection.
    // Returns axios-response, url of ZIP archive with the config, filename.
    // Only relevant for projects with no `hawserID`.
    createNewConnection: ({ commit }, { projectId, connectionName = '' }) =>
      axios.post(`/projects/${projectId}/connections/`, getFormData({
        connection_type: 'new',
        connection_name: connectionName,
      }), {
        responseType: 'blob',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const reloadProject = () =>
            Project.dispatch('$getOne', { projectId, reload: true })
          const configUrl = URL.createObjectURL(response.data)
          const contentDisposition = response.headers['content-disposition']
          const supposedFilename = extractFilenameFromContentDisposition(response.headers['content-disposition'])
          return {
            response, // raw axios response
            reloadProject, // function to reload project with the new `hawserID`
            configUrl, // downloadable URL for zip-file with yml-config
            contentDisposition, // Content-Disposition response header value
            supposedFilename, // Filename from Content-Disposition
          }
        })
        .catch(e => handleError({ commit }, e)),

    // Takes `configFile` blob to establish a connection with a brig instance.
    // Returns nothing, but reloads project with the new `hawserID`.
    // Only relevant for projects with no `hawserID`.
    linkToExistingConnection: ({ commit }, { projectId, configFile, connectionName = '' }) =>
      axios.post(`/projects/${projectId}/connections/`, getFormData({
        connection_type: 'existing',
        connection_name: connectionName,
        config: configFile,
      }), {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => Project.dispatch('$getOne', { projectId, reload: true }))
        .catch(e => handleError({ commit }, e)),

    // Deletes previously established.
    // Only relevant for projects with `hawserID` set.
    delete: ({ dispatch, commit }, { projectId }) =>
      ProjectService.projectConnectionDelete({ projectId })
        .then(() => dispatch('_updateHawserId', {
          projectId,
          hawserConnectionId: null,
        }))
        .catch(e => handleError({ commit }, e)),

    // data-committing actions
    _updateHawserId: (
      _,
      { projectId, hawserConnectionId },
    ) => {
      const project = Project.find(projectId)
      return project != null
        // update local state
        ? Promise.resolve(Project.update({
          where: projectId,
          data: { hawserID: hawserConnectionId },
        }))
        // fall back to (re-)loading project
        : Project.dispatch('$getOne', { projectId, reload: true })
    },
  },
}
