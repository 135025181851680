import * as R from 'ramda'

const freeze = Object.freeze.bind(Object)

export const AxiosResponse = Symbol('axios-response')

export const AUTH = freeze({
  LDAP: 'LDAP',
  PLAIN: 'PLAIN',
})

export const DEFAULT = Symbol('default')
export const EMPTY_SET = Symbol('empty-set')

export const SERVER_TYPE = Object.freeze({
  brig: 'brig', // aka HIVE
  frigate: 'frigate', // aka APIARY
})

export const USER_TYPE = freeze({
  LDAP: 'LDAP', // aka HIVE
  LOCAL: 'LOCAL', // aka APIARY
})

export const PROJECT_PERMISSION_LEVEL = freeze({
  OWNER: freeze({ value: 'OWNER', label: 'project.owner', sort: 0, weight: 40 }),
  EDITOR: freeze({ value: 'EDITOR', label: 'project.editor', sort: 1, weight: 30 }),
  READONLY: freeze({ value: 'READONLY', label: 'project.readOnly', sort: 2, weight: 10 }),
  LIMITED: freeze({ value: 'LIMITED', label: 'project.assignee', sort: 3, weight: 20 }),
})
export const DEFAULT_PROJECT_PERMISSION = PROJECT_PERMISSION_LEVEL.EDITOR.value

export const ISSUE_PERMISSION_LEVEL = freeze({
  EDIT: freeze({ value: 'EDIT', label: 'issue.canEdit', sort: 0 }),
  READ: freeze({ value: 'READ', label: 'issue.canRead', sort: 1 }),
  // for UI only: different permission levels for selected issues
  _MIXED: freeze({ value: '_MIXED', label: 'issue.mixed', sort: 2 }),
})
export const DEFAULT_ISSUE_PERMISSION = ISSUE_PERMISSION_LEVEL.EDIT.value

export const USER_STATE = freeze({
  ACTIVE: freeze({
    value: 'ACTIVE',
    label: 'user.Accepted',
    icon: '$check',
    iconColor: 'success',
  }),
  PENDING: freeze({
    value: 'PENDING',
    label: 'user.Pending',
    icon: '$hourglass',
    iconColor: 'warning',
  }),
  BLOCKED: freeze({
    value: 'BLOCKED',
    label: 'user.Blocked',
    icon: '$block',
    iconColor: 'error',
  }),
  // for UI only
  _REMOVE: freeze({
    value: '--remove-frontend-only',
    label: 'Decline',
    icon: '$block',
    iconColor: 'error',
  }),
})

export const CHAT_MESSAGE_STATUS = {
  clientNew: 'clientNew', // NB! client-only, message is just created
  clientPurgatory: 'clientPurgatory', // NB! client-only, message is about to be deleted
  new: 'new', // Message was saved on the HIVE server
  sent: 'sent', // Message was sent to the CONVEY
  delivered: 'delivered', // Message was sent through the CONVEY to the APIARY server
  failed: 'failed',
}

export const NOTIFICATION_SETTING_TYPE = freeze({
  WEB: 'WEB',
  EMAIL: 'EMAIL',
})

export const NOTIFICATION_SETTING_ITEMS = freeze({
  ENABLED: freeze({
    value: true, // default value?
    settingsProp: 'webEnabled',
    label: 'notification.WebNotifications',
    type: NOTIFICATION_SETTING_TYPE.WEB,
  }),
  NEW_ISSUE: freeze({
    value: true,
    settingsProp: 'webNewIssue',
    label: 'notification.NewIssue',
    type: NOTIFICATION_SETTING_TYPE.WEB,
    icon: 'mdi-bug-outline',
    iconColor: '#2196F3',
    backgroundColor: '#E6F2FD',
    main: false,
  }),
  NEW_CHAT_MESSAGE: freeze({
    value: true,
    settingsProp: 'webNewIssueChatMessage',
    label: 'notification.NewMessage',
    type: NOTIFICATION_SETTING_TYPE.WEB,
    icon: 'mdi-message-processing-outline',
    iconColor: '#2AD9A4',
    backgroundColor: '#D6FFEA',
    main: false,
  }),
  ISSUE_STATUS_CHANGE: freeze({
    value: true,
    settingsProp: 'webIssueStatusChange',
    label: 'notification.IssueStatusChange',
    type: NOTIFICATION_SETTING_TYPE.WEB,
    icon: 'mdi-swap-horizontal',
    iconColor: '#FFC480',
    backgroundColor: '#FAF4D6',
    main: false,
  }),
  CREATED_JIRA_COMMENT: freeze({
    value: true,
    settingsProp: 'webCreatedJiraComment',
    label: 'notification.NewCommentJira',
    type: NOTIFICATION_SETTING_TYPE.WEB,
    icon: 'mdi-message-processing-outline',
    iconColor: '#CC72EC',
    backgroundColor: '#EFEDFF',
    main: false,
  }),
  UPDATED_JIRA_COMMENT: freeze({
    value: true,
    settingsProp: 'webUpdatedJiraComment',
    label: 'notification.UpdatedCommentJira',
    type: NOTIFICATION_SETTING_TYPE.WEB,
    icon: 'mdi-message-processing-outline',
    iconColor: '#CC72EC',
    backgroundColor: '#EFEDFF',
    main: false,
  }),
  DELETED_JIRA_COMMENT: freeze({
    value: true,
    settingsProp: 'webDeletedJiraComment',
    label: 'notification.DeletedCommentJira',
    type: NOTIFICATION_SETTING_TYPE.WEB,
    icon: 'mdi-message-processing-outline',
    iconColor: '#CC72EC',
    backgroundColor: '#EFEDFF',
    main: false,
  }),
  EMAIL_ENABLED: freeze({
    value: true,
    settingsProp: 'emailEnabled',
    label: 'notification.EmailNotifications',
    type: NOTIFICATION_SETTING_TYPE.EMAIL,
    main: true,
  }),
  EMAIL_NEW_ISSUE: freeze({
    value: true,
    settingsProp: 'emailNewIssue',
    label: 'notification.EmailNewIssue',
    type: NOTIFICATION_SETTING_TYPE.EMAIL,
    main: false,
  }),
  EMAIL_NEW_CHAT_MESSAGE: freeze({
    value: true,
    settingsProp: 'emailNewIssueChatMessage',
    label: 'notification.EmailNewMessage',
    type: NOTIFICATION_SETTING_TYPE.EMAIL,
    main: false,
  }),
  EMAIL_ISSUE_STATUS_CHANGE: freeze({
    value: true,
    settingsProp: 'emailIssueStatusChange',
    label: 'notification.EmailIssueStatusChange',
    type: NOTIFICATION_SETTING_TYPE.EMAIL,
    main: false,
  }),
  EMAIL_CREATED_JIRA_COMMENT: freeze({
    value: true,
    settingsProp: 'emailCreatedJiraComment',
    label: 'notification.EmailNewCommentJira',
    type: NOTIFICATION_SETTING_TYPE.EMAIL,
    icon: 'mdi-message-processing-outline',
    iconColor: '#2AD9A4',
    backgroundColor: '#D6FFEA',
    main: false,
  }),
  EMAIL_UPDATED_JIRA_COMMENT: freeze({
    value: true,
    settingsProp: 'emailUpdatedJiraComment',
    label: 'notification.EmailUpdatedCommentJira',
    type: NOTIFICATION_SETTING_TYPE.EMAIL,
    icon: 'mdi-message-processing-outline',
    iconColor: '#CC72EC',
    backgroundColor: '#EFEDFF',
    main: false,
  }),
  EMAIL_DELETED_JIRA_COMMENT: freeze({
    value: true,
    settingsProp: 'emailDeletedJiraComment',
    label: 'notification.EmailDeletedCommentJira',
    type: NOTIFICATION_SETTING_TYPE.EMAIL,
    icon: 'mdi-message-processing-outline',
    iconColor: '#CC72EC',
    backgroundColor: '#EFEDFF',
    main: false,
  }),
})

const DEFAULT_POLL_INTERVAL = 7000
// How often `IssueChat.vue` polls new chat messages for issue
export const CHAT_POLL_INTERVAL = DEFAULT_POLL_INTERVAL
// How often notifications are requested (see `notification` store module)
export const NOTIFICATIONS_POLL_INTERVAL = DEFAULT_POLL_INTERVAL * 2
// How often `ChecklistView.vue` polls checklist details
export const CHECKLIST_POLL_INTERVAL = DEFAULT_POLL_INTERVAL * 2
// NB! Only some cards are polled, namely checklist cards on project dashboards (`Dashboard.vue`)
export const DASHBOARD_CARDS_POLL_INTERVAL = DEFAULT_POLL_INTERVAL * 2
// How often to poll issues list (see `IssueList.vue`)
export const ISSUES_POLL_INTERVAL = DEFAULT_POLL_INTERVAL * 8.5

export const GREEN = 'green'
export const ORANGE = 'orange'
export const DEEP_PURPLE = 'deep-purple'
export const DEEPER_PURPLE = 'deeper-purple'
export const GREY = 'grey'
export const MAGENTA = 'magenta'
export const SKY_BLUE = 'sky-blue'
export const COLORS_LOOKUP = freeze({
  [GREEN]: freeze({
    key: GREEN,
    color: '#23C393',
  }),
  [ORANGE]: freeze({
    key: ORANGE,
    color: '#FFB950',
  }),
  [DEEP_PURPLE]: freeze({
    key: DEEP_PURPLE,
    color: '#42425C',
  }),
  [DEEPER_PURPLE]: freeze({
    key: DEEPER_PURPLE,
    color: '#3C3A52',
  }),
  [GREY]: freeze({
    key: GREY,
    color: '#8492A6',
  }),
  [MAGENTA]: freeze({
    key: MAGENTA,
    color: '#FF5065',
  }),
  [SKY_BLUE]: freeze({
    key: SKY_BLUE,
    color: '#69D2EF',
  }),
})
export const COLORS = [
  DEEPER_PURPLE,
  DEEP_PURPLE,
  GREY,
  MAGENTA,
  ORANGE,
  GREEN,
  SKY_BLUE,
]

export const BG = freeze({
  cross: {
    key: 'cross',
    image: require('./assets/backgrounds/x.png'),
  },
  plus: {
    key: 'plus',
    image: require('./assets/backgrounds/+.png'),
  },
  line: {
    key: 'line',
    image: require('./assets/backgrounds/diagonal-line.png'),
  },
  ticTacToe: {
    key: 'ticTacToe',
    image: require('./assets/backgrounds/tic-tac-toe.png'),
  },
  triangles: {
    key: 'triangles',
    image: require('./assets/backgrounds/triangles-down.png'),
  },
  wave: {
    key: 'wave',
    image: require('./assets/backgrounds/wave.png'),
  },
})

export const CARD_DEFAULT_POSITION = null

export const CARD_TYPE = freeze({
  counter: 'counter',
  aOfB: 'ab',
  pieChart: 'pie_chart',
  table: 'table',
  checklistStatus: 'checklist_status',
})

// non-filter query params for issue list & create card page
export const NON_FILTER_PARAMS = [
  // see also `views/IssueList.vue:props`, `views/EditCard.vue:props`
  'cardId',
  'issueId',
  'list',
  'groupingValue',
  'subQuery',
  'chat',
  'back',
  'copy',
  'checklistId',
]

export const PREDEFINED_STATUSES = freeze([
  freeze({
    author: null,
    color: '#00B8D4',
    displayName: 'issue.StatusNewIssue',
    icon: '$common-cool-face',
    listOrder: 0,
    name: 'new_issue',
    syncing: 'IN',
    system: true,
  }),
  freeze({
    author: null,
    color: '#CC72EC',
    displayName: 'issue.StatusRetestRequired',
    icon: '$common-confused',
    listOrder: 1,
    name: 'retest_required',
    syncing: 'OUT',
    system: true,
  }),
  freeze({
    author: null,
    color: '#40C4FF',
    displayName: 'issue.StatusFixConfirmed',
    icon: '$common-nerd-face',
    listOrder: 2,
    name: 'fix_confirmed',
    syncing: 'IN',
    system: true,
  }),
  freeze({
    author: null,
    color: '#FF8A80',
    displayName: 'issue.StatusFixUnconfirmed',
    icon: '$common-sad-face',
    listOrder: 3,
    name: 'fix_unconfirmed',
    syncing: 'IN',
    system: true,
  }),
])
export const PREDEFINED_STATUS_LOOKUP = R.pipe(
  R.indexBy(R.prop('name')),
  freeze,
)(PREDEFINED_STATUSES)

export const SCORE_INFO = 0
export const SCORE_LOW = 1
export const SCORE_MEDIUM = 2
export const SCORE_HIGH = 3
export const SCORE_CRITICAL = 4
export const SCORE_LOOKUP = freeze({
  [SCORE_CRITICAL]: freeze({
    value: SCORE_CRITICAL,
    displayValue: 'dashboard.ScoreCritical',
    chartLabel: 'critical',
    color: 'error',
  }),
  [SCORE_HIGH]: freeze({
    value: SCORE_HIGH,
    displayValue: 'dashboard.ScoreHigh',
    chartLabel: 'high',
    color: 'error',
  }),
  [SCORE_MEDIUM]: freeze({
    value: SCORE_MEDIUM,
    displayValue: 'dashboard.ScoreMedium',
    chartLabel: 'medium',
    color: 'warning',
  }),
  [SCORE_LOW]: freeze({
    value: SCORE_LOW,
    displayValue: 'dashboard.ScoreLow',
    chartLabel: 'low',
    color: 'success',
  }),
  [SCORE_INFO]: freeze({
    value: SCORE_INFO,
    displayValue: 'dashboard.ScoreInfo',
    chartLabel: 'info',
    color: 'success',
  }),
})
export const SCORE = freeze([
  SCORE_LOOKUP[SCORE_INFO],
  SCORE_LOOKUP[SCORE_HIGH],
  SCORE_LOOKUP[SCORE_MEDIUM],
  SCORE_LOOKUP[SCORE_LOW],
  SCORE_LOOKUP[SCORE_CRITICAL],
])

export const BOOL_ON_VALUES = new Set(['1', 'on', 'true', 'yes'])
export const BOOL_OFF_VALUES = new Set(['0', 'off', 'false', 'no'])

export const ISSUE_FIELD_TYPE = freeze({
  TEXT: 'TEXT',
  TEXT_MD: 'TEXT_MD',
  INTEGER: 'INTEGER',
  FLOAT: 'FLOAT',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  UUID: 'UUID',
  IP: 'IP',
  HOSTNAME: 'HOSTNAME',
  ASSET: 'ASSET',
  REQUEST: 'REQUEST',
  DATASOURCE: 'DATASOURCE',
  CVSS_BASE_VECTOR: 'CVSS_BASE_VECTOR',
  CVSS_BASE_SCORE: 'CVSS_BASE_SCORE',
})

export const ISSUE_CUSTOM_WIDGET_FIELDS = freeze([
  // 1. hidden system fields
  'id',
  'uuid',

  // 2. system dates
  'postTime',
  'editTime',

  // 3. mandatory issue name & status
  'name',
  'status',

  // 4. scores and assets are treated specially
  'criticalityScore',
  'probabilityScore',
  'totalScore',
  'ips',
  'hostnames',
  'ports',
  'paths',
  'optionals',
  'assets',
  'protocols',
])

export const IMAGE_MIME_TYPES = freeze([
  'image/png',
  'image/jpeg',
  'image/gif',
])

const SIMPLE_COUNTER_ROW = '--simple-counters'
const AB_COUNTER_ROW = '--a-of-b-counters'
const PIE_ROW = '--pies'
const TABLE_ROW = '--tables'
const CHECKLIST_STATUS = '--checklist-counters'
export const DASHBOARD = freeze({
  SIMPLE_COUNTER_ROW,
  AB_COUNTER_ROW,
  PIE_ROW,
  TABLE_ROW,
  CHECKLIST_STATUS,
  PROJECT_DEFAULT_ROWS: freeze([SIMPLE_COUNTER_ROW, AB_COUNTER_ROW, PIE_ROW, TABLE_ROW, CHECKLIST_STATUS]),
  CROSS_PROJECT_DEFAULT_ROWS: freeze([SIMPLE_COUNTER_ROW, AB_COUNTER_ROW]),
})
export const PROJECT_DASHBOARD = freeze({
})

export const JIRA = 'JIRA'
export const SLACK = 'SLACK'
export const INTEGRATION = freeze({
  [JIRA]: freeze({
    code: JIRA,
    licenseFeatureName: 'jira',
    name: 'Jira',
    assets: freeze({
      logo48: require('./assets/images/jira-logo-48.png'),
      logo32: require('./assets/images/jira-logo-32.png'),
      icon: '$integration-jira',
    }),
  }),
  [SLACK]: freeze({
    code: SLACK,
    licenseFeatureName: 'notImplemented',
    name: 'Slack',
    assets: freeze({
      logo48: require('./assets/images/slack-logo-48.png'),
      logo32: require('./assets/images/slack-logo-32.png'),
      icon: '$integration-slack',
    }),
  }),
})
export const INTEGRATION_ORDER = freeze([JIRA, SLACK])
export const JIRA_AUTH_TYPE = freeze({
  CLOUD: 'CLOUD',
  BASIC: 'BASIC',
  TOKEN: 'TOKEN',
})

export const JIRA_ISSUE_REQUIRED_FIELDS_TYPE = freeze([
  'STRING',
  'ENUM',
  'ARRAY',
  'SEARCH_USER',
  'SEARCH_EPIC',
  'SEARCH_SPRINT',
  'DATE',
  'UNSUPPORTED',
])

export const JIRA_ISSUE_REQUIRED_FIELDS_TYPE_COMPONENT = freeze({
  STRING: {
    componentName: 'AppTextField',
  },
  ENUM: {
    componentName: 'v-autocomplete',
  },
  SEARCH_USER: {
    componentName: 'v-autocomplete',
  },
  SEARCH_EPIC: {
    componentName: 'v-autocomplete',
  },
  SEARCH_SPRINT: {
    componentName: 'v-autocomplete',
  },
  ARRAY: {
    SEARCH_USER: {
      componentName: 'v-autocomplete',
    },
    STRING: {
      componentName: 'v-combobox',
    },
    ENUM: {
      componentName: 'v-autocomplete',
    },
  },
  UNSUPPORTED: {
    componentName: 'AppTextField',
  },
})

export const INTEGRATION_STATE = freeze({
  CREATED: 'CREATED',
  AUTHENTICATED: 'AUTHENTICATED',
})

export const LDAP_NOT_LOADED = undefined
export const LDAP_MISSING = null

export const LDAP_PROTOCOLS = ['ldap', 'ldaps']
export const LDAP_DEFAULT_PORTS = [
  389, // default for ldap://
  636, // default for ldaps://
  1760,
  3268,
  3269,
  3407,
]
export const LDAP_PROTOCOL_DEFAULT_PORTS = {
  ldap: 389,
  ldaps: 636,
}

export const PROJECT_CONNECTION_TYPE = Object.freeze({
  app: 'appConnection',
  project: 'projectConnection',
})

export const SMTP_NOT_LOADED = undefined
export const SMTP_MISSING = null

export const SMTP_CONNECTION_SECURITY = ['NONE', 'STARTTLS', 'SSL/TLS']

// dark colors (with white text) from here:
// https://observablehq.com/@dsemenov/gmd-colors
export const DARK_COLORS = freeze([
  'red',
  'red lighten-1',
  'red darken-1',
  'red darken-2',
  'red darken-3',
  'red darken-4',
  'red accent-2',
  'red accent-3',
  'red accent-4',

  'pink',
  'pink lighten-2',
  'pink lighten-1',
  'pink darken-1',
  'pink darken-2',
  'pink darken-3',
  'pink darken-4',
  'pink accent-2',
  'pink accent-3',
  'pink accent-4',

  'purple',
  'purple lighten-2',
  'purple lighten-1',
  'purple darken-1',
  'purple darken-2',
  'purple darken-3',
  'purple darken-4',
  'purple accent-2',
  'purple accent-3',
  'purple accent-4',

  'deep-purple',
  'deep-purple lighten-2',
  'deep-purple lighten-1',
  'deep-purple darken-1',
  'deep-purple darken-2',
  'deep-purple darken-3',
  'deep-purple darken-4',
  'deep-purple accent-2',
  'deep-purple accent-3',
  'deep-purple accent-4',

  'indigo',
  'indigo lighten-2',
  'indigo lighten-1',
  'indigo darken-1',
  'indigo darken-2',
  'indigo darken-3',
  'indigo darken-4',
  'indigo accent-2',
  'indigo accent-3',
  'indigo accent-4',

  'blue',
  'blue darken-1',
  'blue darken-2',
  'blue darken-3',
  'blue darken-4',
  'blue accent-2',
  'blue accent-3',
  'blue accent-4',

  'light-blue darken-1',
  'light-blue darken-2',
  'light-blue darken-3',
  'light-blue darken-4',
  'light-blue accent-4',

  'cyan darken-2',
  'cyan darken-3',
  'cyan darken-4',

  'teal',
  'teal darken-1',
  'teal darken-2',
  'teal darken-3',
  'teal darken-4',

  'green darken-1',
  'green darken-2',
  'green darken-3',
  'green darken-4',

  'light-green darken-2',
  'light-green darken-3',
  'light-green darken-4',

  'lime darken-4',

  'orange darken-3',
  'orange darken-4',

  'deep-orange',
  'deep-orange darken-1',
  'deep-orange darken-2',
  'deep-orange darken-3',
  'deep-orange darken-4',
  'deep-orange accent-3',
  'deep-orange accent-4',

  'brown',
  'brown lighten-2',
  'brown lighten-1',
  'brown darken-1',
  'brown darken-2',
  'brown darken-3',
  'brown darken-4',

  'grey darken-1',
  'grey darken-2',
  'grey darken-3',
  'grey darken-4',

  'blue-grey',
  'blue-grey lighten-1',
  'blue-grey darken-1',
  'blue-grey darken-2',
  'blue-grey darken-3',
  'blue-grey darken-4',
])

export const DEFAULT_ENVIRONMENT = Object.freeze({
  product: { version: '0.0.0-default', build: 0, languages: [{ name: 'en', displayName: 'English' }] },
  capabilities: {
    users: { registration: 'ON', confirmation: 'ON' },
    hawser: 'ENABLED',
    integrations: { jira: 'OFF' },
    rtlSwitcher: 'off',
  },
})

export const CHECKLIST_DONE_STATUSES = freeze([
  'done',
  'not_done',
  'indeterminate',
  'inapplicable',
])

export const TERMINATION_NOTICE = 30 // Time in days when the license expiration notification starts to be shown to the user

export const COMMUNITY_PLAN = 'community'
export const PENTEST_PLAN = 'pentest'
export const CORPORATE_PLAN = 'corporate'

export const INCLUDED_IN_PLAN = '✔'
export const UNLIMITED_IN_PLAN = 'license.Unlimited'
export const NOT_INCLUDED_IN_PLAN = ''
export const PRICE_PER_USER = 'license.PerUser'

export const LICENSE_PLAN = freeze({
  [COMMUNITY_PLAN]: freeze({
    name: 'community',
    displayName: 'license.community',
    priceInfo: null,
  }),
  [PENTEST_PLAN]: freeze({
    name: 'pentest',
    displayName: 'license.pentest',
    priceInfo: 'license.PriceInfoPentest',
  }),
  [CORPORATE_PLAN]: freeze({
    name: 'corporate',
    displayName: 'license.enterprise',
    priceInfo: 'license.PriceInfoCorporate',
  }),
})

export const PLAN_TABLE = freeze([
  freeze({
    name: 'license.planFeature.DeploymentsCount',
    techName: 'deploymentsCount',
    counterName: null,
    [COMMUNITY_PLAN]: 'license.OnPrem',
    [PENTEST_PLAN]: 'license.OnPrem',
    [CORPORATE_PLAN]: 'license.OnPrem',
    showCounter: false,
    perProject: false,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.UserCount',
    techName: 'userCount',
    counterName: 'user',
    [COMMUNITY_PLAN]: '3',
    [PENTEST_PLAN]: PRICE_PER_USER,
    [CORPORATE_PLAN]: PRICE_PER_USER,
    showCounter: true,
    perProject: false,
    hideWhenActive: false,
    addMoreBtn: true,
  }),
  freeze({
    name: 'license.planFeature.ProjectCount',
    techName: 'projectCount',
    counterName: 'project',
    [COMMUNITY_PLAN]: '3',
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: true,
    perProject: false,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.ChecklistCount',
    techName: 'checklistCount',
    counterName: null,
    [COMMUNITY_PLAN]: '3',
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: false,
    perProject: true,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.ApplicationCount',
    techName: 'applicationCount',
    counterName: null,
    [COMMUNITY_PLAN]: '5',
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: false,
    perProject: true,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.CredentialsCount',
    techName: 'credentialsCount',
    counterName: null,
    [COMMUNITY_PLAN]: '10',
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: false,
    perProject: true,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.IssueSchemaCount',
    techName: 'issueSchemaCount',
    counterName: null,
    [COMMUNITY_PLAN]: '2',
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.SnapshotCount',
    techName: 'snapshotCount',
    counterName: null,
    [COMMUNITY_PLAN]: '20',
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: false,
    perProject: true,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.ApiaryReportCount',
    techName: 'apiaryReportCount',
    counterName: 'report',
    [COMMUNITY_PLAN]: '15',
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: true,
    perProject: true,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.HiveReportCount',
    techName: 'hiveReportCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.CustomReportBrandCount',
    techName: 'customReportBrandCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.BotUsersCount',
    techName: 'botUsersCount',
    counterName: 'botUser',
    [COMMUNITY_PLAN]: NOT_INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: '5',
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: true,
    perProject: false,
    hideWhenActive: false,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.ApiCount',
    techName: 'apiCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.ImportFromToolsCount',
    techName: 'importFromToolsCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.NotesCount',
    techName: 'notesCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.TagsCount',
    techName: 'tagsCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.NotificationsCount',
    techName: 'notificationsCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.DashboardsCount',
    techName: 'dashboardsCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.IssuesCount',
    techName: 'issuesCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.IssueCustomFieldsCount',
    techName: 'issueCustomFieldsCount',
    counterName: null,
    [COMMUNITY_PLAN]: INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.IssueTemplatesCount',
    techName: 'issueTemplatesCount',
    counterName: null,
    [COMMUNITY_PLAN]: NOT_INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: UNLIMITED_IN_PLAN,
    [CORPORATE_PLAN]: UNLIMITED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.CrossProjectDashboardCount',
    techName: 'crossProjectDashboardCount',
    counterName: null,
    [COMMUNITY_PLAN]: NOT_INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: NOT_INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.MfaCount',
    techName: 'mfaCount',
    counterName: null,
    [COMMUNITY_PLAN]: NOT_INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: true,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.LdapCount',
    techName: 'ldapCount',
    counterName: 'ldap',
    [COMMUNITY_PLAN]: NOT_INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: NOT_INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: true,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.JiraCount',
    techName: 'jiraCount',
    counterName: 'jira',
    [COMMUNITY_PLAN]: NOT_INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: NOT_INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: true,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.ReportTemplateServiceCount',
    techName: 'reportTemplateServiceCount',
    counterName: null,
    [COMMUNITY_PLAN]: NOT_INCLUDED_IN_PLAN,
    [PENTEST_PLAN]: INCLUDED_IN_PLAN,
    [CORPORATE_PLAN]: INCLUDED_IN_PLAN,
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
  freeze({
    name: 'license.planFeature.SupportCount',
    techName: 'supportCount',
    counterName: null,
    [COMMUNITY_PLAN]: 'license.Basic',
    [PENTEST_PLAN]: 'license.Professional',
    [CORPORATE_PLAN]: 'license.Advanced',
    showCounter: false,
    perProject: false,
    hideWhenActive: true,
    addMoreBtn: false,
  }),
])

export const ASSET_VERSION = Object.freeze({
  SIMPLE: 'SIMPLE',
  COMPLEX: 'COMPLEX',
  NOT_SET: 'NOT-SET',
})

export const ASSET_COMPLEX_FIELDS = Object.freeze({
  IPS: 'ips',
  PORTS: 'ports',
  HOSTNAMES: 'hostnames',
  PATHS: 'paths',
  OPTIONALS: 'optionals',
  PROTOCOLS: 'protocols',
})
