import VueI18n from 'vue-i18n'
import axios from 'axios'

import messages from './messages'
import moment from 'moment'

let i18n = {
  t: msg => msg,
  tc: (msg, n) => `${n} ${msg}s`,
}
const loadedLanguages = ['en']
function setI18nLanguage(lang) {
  i18n.locale = lang
  // axios.defaults.headers.common['Accept-Language'] = lang
  // document.querySelector('html').setAttribute('lang', lang)
  moment.locale(i18n.locale)
  return lang
}

export function registerI18n() {
  i18n = new VueI18n({
    locale: localStorage.getItem('appLanguage') || 'en',
    fallbackLocale: 'en',
    silentTranslationWarn: process.env.NODE_ENV === 'production',
    messages,
  })
  return i18n
}

export async function loadLanguageAsync(lang) {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  const response = await axios.get(`/ext/languages/${lang}.json`)
  const messages = response.data
  i18n.setLocaleMessage(lang, messages)
  loadedLanguages.push(lang)
  setI18nLanguage(lang)
}

export default {
  get t() { return i18n.t.bind(i18n) },
  get tc() { return i18n.tc.bind(i18n) },
}
