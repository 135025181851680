import { axiosInstance as axios } from '../../api'
import axiosLib from 'axios'
import { DEFAULT_ENVIRONMENT } from '@/constants'
import { loadLanguageAsync } from '../../i18n'

// import { ENVIRONMENT_MOCK } from '../../../tests/fixtures'

const initState = () => ({
  demoUsers: null,
  environment: null,
  selectedLocale: 'en',
})

const getters = {
  GET: state => state.environment,
  HAWSER_ENABLED: state => state.environment && state.environment.capabilities.hawser && state.environment.capabilities.hawser === 'ENABLED',
  REGISTRATION_ENABLED: state => state.environment && state.environment.capabilities.users.registration,
  CONFIRMATION_ENABLED: state => state.environment && state.environment.capabilities.users.confirmation,
  RTL_SWITCHER_ENABLED: state => state.environment && state.environment.capabilities.rtlSwitcher?.toLowerCase?.() === 'on',
}

const mutations = {
  reset: state => Object.assign(state, initState()),

  setDemoUsers: (state, demoUsers = null) => {
    state.demoUsers = demoUsers
  },

  setEnvironment: (state, { environment }) => {
    state.environment = environment
  },

  setSelectedLocale: (state, { selectedLocale }) => {
    state.selectedLocale = selectedLocale
  },
}

const actions = {
  getDemoUsers: (ctx, { reload = false } = {}) => {
    const { state, commit } = ctx
    return !reload && state.demoUsers != null
      ? Promise.resolve(undefined)
      : (
        axios.get('/ext/stand-demo-users.json', { baseURL: '/' })
          .then(({ data: users }) => commit('setDemoUsers', users))
          .catch(() => {}) // fail silently
      )
  },

  getEnvironment: ({ commit, getters: { GET: environ } }, { reload = false } = {}) =>
    !reload && environ != null
      ? Promise.resolve()
      : axiosLib.get('/ext/environment')
        .then(response => response.data)
        .catch(() => DEFAULT_ENVIRONMENT)
        .then(environment => {
          let availableLanguages = []
          const environmentLanguages = environment.product?.languages
          if (environmentLanguages && environmentLanguages.some(lang => lang.name === 'en')) {
            availableLanguages = environmentLanguages
            commit('setEnvironment', { environment })
          } else {
            availableLanguages = environmentLanguages ? [...environmentLanguages, { name: 'en', displayName: 'English' }] : [{ name: 'en', displayName: 'English' }]
            commit('setEnvironment', { environment: { ...environment, product: { ...environment.product, languages: availableLanguages } } })
          }
          return availableLanguages
        })
        .then(languages => {
          const localStorageLanguage = localStorage.getItem('appLanguage')
          const locale = languages.find(lang => lang.name === localStorageLanguage) ? localStorageLanguage : 'en'
          commit('setSelectedLocale', { selectedLocale: locale })
          loadLanguageAsync(locale)
        }),

  // getEnv !reloadironment: ({ commit }) => commit('setEnvironment', { environment: ENVIRONMENT_MOCK }),

  getLanguage: (ctx, { locale } = {}) => {
    const { commit } = ctx
    loadLanguageAsync(locale)
    commit('setSelectedLocale', { selectedLocale: locale })
  },

}

export const watch = store => {
  const unsubscribe = store.subscribeAction({
    after: (action, state) => {
      if (action.type === 'ext/getLanguage') {
        localStorage.setItem('appLanguage', state.ext.selectedLocale)
      }
    },
  })
  return () => {
    unsubscribe()
  }
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
