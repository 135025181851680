import axios from 'axios'
import moment from 'moment'
import objectHash from 'object-hash'
import * as R from 'ramda'
import * as RA from 'ramda-adjunct'

import i18n from '@/i18n'
import sentry from './plugins/sentry'
import { EMPTY_SET, DARK_COLORS, ISSUE_FIELD_TYPE, PREDEFINED_STATUS_LOOKUP, SCORE, SCORE_LOOKUP } from './constants'

// resolveErrorMessage :: Error -> Promise<String>
export const resolveErrorMessage = (e, fallbackMessage = i18n.t('layout.fallbackMessage')) => {
  if (
    typeof e.response?.data?.text === 'function' &&
    e.response?.data?.type?.includes?.('json')
  ) {
    return e.response.data
      .text()
      .then(s => JSON.parse(s))
      .then(data => ({ response: { data } }))
      .then(resolvedError => resolveErrorMessage(resolvedError, fallbackMessage))
      .catch(err => {
        console.error(err)
        return fallbackMessage
      })
  }

  return e?.clientErrorMessage ||
    e?.response?.data?.detail ||
    fallbackMessage
}

export const handleError = async (
  { commit },
  e,
  fallbackMessage = i18n.t('layout.fallbackMessage'),
  defaults = {},
) => {
  if (axios.isCancel(e)) throw e // axios request was cancelled by us
  if (!(e?.response?.config?.url || '').startsWith('/session/')) {
    if (e.status === 401 || e.response?.status === 401) throw e // handled in interceptor, no snackbar required
  }

  const message = await resolveErrorMessage(e, fallbackMessage)
  if (e.status === 461 || e.response?.status === 461) {
    commit('license/setLicenseDialog', { isOpen: true, errorMessage: message }, { root: true })
  } else {
    commit('$snackbar/setMessage', { ...defaults, message }, { root: true })
  }
  throw e
}

// mapErrorMessage:: (Error -> String|null) -> Error -> Error
export const mapErrorMessage = R.curryN(2, (getMessage, err) => {
  const newMessage = getMessage(err)
  if (newMessage == null) return err

  const newError = new (err?.constructor || Error)(newMessage)
  Object.getOwnPropertyNames(err || {})
    .forEach(prop => (newError[prop] = err[prop]))
  newError.clientErrorMessage = newMessage

  return newError
})

export const removeInPlace = (array, value) => {
  let ix = -1
  while ((ix = array.indexOf(value)) !== -1) array.splice(ix, 1)
}

export const queryToObject = query => {
  if (!query?.trim()) return {}
  return R.pipe(
    R.constructN(1, URLSearchParams),
    R.invoker(0, 'entries'),
    entries => Object.fromEntries(entries),
  )(query)
}

export const issueFilterQueryToObject = R.pipe(
  queryToObject,
  RA.renameKeys({ projectID: 'project' }),
)

export const objectToQuery = object =>
  R.pipe(
    R.constructN(1, URLSearchParams),
    R.invoker(0, 'toString'),
    R.when(R.startsWith('?'), R.drop(1)),
  )(object)

export const objectToIssueFilterQuery = R.pipe(
  RA.renameKeys({ project: 'projectID' }),
  objectToQuery,
)

export const plainObjectHash = (o, options = {}) =>
  objectHash(o, {
    ignoreUnknown: true,
    respectFunctionProperties: false,
    respectFunctionNames: false,
    respectType: false,
    ...options,
  })

export const unorderedPlainObjectHash = (o, options = {}) =>
  plainObjectHash(o, {
    unorderedArrays: true,
    unorderedSets: true,
    unorderedObjects: true,
    ...options,
  })

export const mapIndexed = R.addIndex(R.map)

export const unreachable = () => {
  throw new Error('UnreachableError')
}

export const randomChoice = array =>
  array.length
    ? array[(array.length * Math.random()) | 0]
    : undefined

export const listenBeforeDestroy = (vm, targetVm, event, handler) => {
  targetVm.$on(event, handler)
  vm.$once('hook:beforeDestroy', () =>
    targetVm.$off(event, handler))
}

export const addEventListenerBeforeDestroy = (vm, target, event, handler) => {
  target.addEventListener(event, handler, false)
  vm.$once('hook:beforeDestroy', () =>
    target.removeEventListener(event, handler, false))
}

export const reportError = e =>
  sentry.then(sentry =>
    sentry !== null
      ? sentry.captureException(e)
      : console.error(e))

export const issueRouteFromIssueList =
  ({ query = {}, projectId = null, cardId, issueId }) =>
    projectId
      ? cardId
        ? {
          name: 'ProjectCardIssueListIssue',
          params: { projectId, cardId, issueId },
          query: R.omit(['issueId'], query),
        }
        : {
          name: 'ProjectIssueListIssue',
          params: { projectId, issueId },
          query: R.omit(['issueId'], query),
        }
      : cardId
        ? {
          name: 'CardIssueListIssue',
          params: { cardId, issueId },
          query: R.omit(['issueId'], query),
        }
        : {
          name: 'IssueListIssue',
          params: { issueId },
          query: R.omit(['issueId'], query),
        }

export const wait = (timeout = 0) =>
  new Promise(resolve => setTimeout(resolve, timeout))
export const animationFrame = () =>
  new Promise(resolve => requestAnimationFrame(resolve))

// Compares two ips, returns 0, 1 or -1, for `Array.prototype.sort`
// or ramda `sort`
// String -> String -> Number
export const compareIps = (left, right) =>
  left.localeCompare(right, 'en', {
    numeric: true,
    sensitivity: 'base',
  })

// Compares two hostnames, returns 0, 1 or -1, for `Array.prototype.sort`
// String -> String -> Number
export const compareHostnames = (left, right) =>
  left.localeCompare(right, 'en', {
    sensitivity: 'base',
    ignorePunctuation: true,
  })

// splitValues('') = []
// splitValues('1') = ['1']
// splitValues('1,2') = ['1', '2']
export const splitValues = R.pipe(
  R.split(','),
  R.map(R.trim),
  R.reject(R.equals('')),
)

// const john = { possessions: ['A mace'] }
// dotPath('possessions.0', john) = 'A mace'
// dotPath('possessions', john) = ['A mace']
// dotPath('wrong.path', null) = undefined // undefined if not found
// dotPath('', 42) = 42 // identity on empty path
export const dotPath = R.uncurryN(2, dotSeparatedPath =>
  R.path(
    R.pipe(
      R.split('.'),
      R.reject(R.equals('')),
    )(dotSeparatedPath),
  ))

// NB! merges with the narrowing
// > mergeIssueFilters({ status: 'fix_confirmed,fix_unconfirmed' }, { criticalityScore: '1' })
// < { status: 'fix_confirmed,fix_unconfirmed', criticalityScore: '1' }
// > mergeIssueFilters({ status: 'fix_confirmed,fix_unconfirmed' }, { status: 'fix_unconfirmed' })
// < { status: 'fix_unconfirmed' }
// > mergeIssueFilters({ status: 'fix_confirmed,fix_unconfirmed' }, { status: 'awaiting_retest' })
// Symbol('empty-set')
// > mergeIssueFilters({ totalScore: '1,2' }, { totalScore: '3' })
// Symbol('empty-set')
// > mergeIssueFilters({ overdue: 'true' }, { overdue: 'false' })
// Symbol('empty-set')
// this boolean value is overriden, don't know if good or bad
// > mergeIssueFilters({ isCompleted: true }, { isCompleted: false })
// false
export const mergeIssueFilters = (base, narrowBy) => {
  if (base === EMPTY_SET || narrowBy === EMPTY_SET) return EMPTY_SET

  const res = { ...base }
  for (const [prop, value] of Object.entries(narrowBy)) {
    if (!R.has(prop, res)) {
      res[prop] = value
    } else {
      if (prop === 'isCompleted') {
        const baseValue = base[prop]
        res[prop] = value == null ? baseValue : value
      } else {
        const baseValues = splitValues(base[prop])
        const narrowedValues = splitValues(value)
        if (narrowedValues.some(v => !baseValues.includes(v))) {
        // throw new Error('impossible narrowBy, cannot merge', value, 'into', base[prop])
        // skip impossible narrowing
          res[prop] = narrowedValues
            .filter(v => baseValues.includes(v))
            .join(',')
        } else {
          res[prop] = narrowedValues.join(',')
        }
      }
    }
  }

  if (narrowBy.project || narrowBy.groups) {
    if (narrowBy.project) res.project = narrowBy.project
    else delete res.project
    if (narrowBy.groups) res.groups = narrowBy.groups
    else delete res.groups
  }

  // if there's an empty set we use special symbol
  // to emulate no results found
  if (R.pipe(
    R.omit(['isCompleted']),
    R.filter(val => !val),
    R.values,
    R.length,
  )(res)) return EMPTY_SET // this is a special case

  return res
}

// Additive version of the former (without additional narrowing)
// works like checking checkboxes in the UI
// > mergeIssueFiltersAdditive({ status: 'fix_confirmed,fix_unconfirmed' }, { criticalityScore: '1' })
// < { status: 'fix_confirmed,fix_unconfirmed', criticalityScore: '1' }
// > mergeIssueFiltersAdditive({ status: 'fix_confirmed,fix_unconfirmed' }, { status: 'fix_unconfirmed' })
// < { status: 'fix_confirmed,fix_unconfirmed' }
// > mergeIssueFiltersAdditive({ status: 'fix_confirmed,fix_unconfirmed' }, { status: 'awaiting_retest' })
// < { status: 'fix_confirmed,fix_unconfirmed,awaiting_retest' }
// > mergeIssueFiltersAdditive({ totalScore: '1,2' }, { totalScore: '3' })
// < { totalScore: '1,2,3' }
export const mergeIssueFiltersAdditive = (left, right) => {
  left = left || {}
  right = right || {}
  const res = R.clone(left)
  for (const [prop, value] of Object.entries(right)) {
    if (!value || !value?.trim?.()) continue

    if (!R.has(prop, res)) {
      res[prop] = String(value)
    } else {
      res[prop] = R.uniq([
        ...splitValues(res[prop]),
        ...splitValues(value),
      ]).join(',')
    }
  }
  return res
}

// takes a `query` as an input (`status=new&totalScore=1,2`)
// returns query as an object and removes redundant values like:
// * 'totalScore=1,2,3' (all possible values filter nothing)
// * 'status=' (empty set also filters nothing).
// Output shape:
// {
//   status: [{ name: 'new_issue', displayName: 'New issue', ...}],
//   totalScore: [{ value: 1, displayValue: 'Low' }, ...],
//   criticalityScore: [{ value: 2, displayValue: 'Medium' }, ...],
//   probabilityScore: [...],
//   overdue: [{ value: true, displayValue: 'Overdue' }, ...],
//   isCompleted: [{ value: true, displayValue: 'Completed' }, ...],
//   ips: ['0.0.0.0', ...],
//   hostnames: ['example.com', ...],
//   customSelect: [{ value: 'foobar', displayValue: 'Foo, Bar!' }, ...],
// }
// If value is considered redundant the corresponding key is excluded
// from the output
// params:
// * issueStatus - `$store.getters['$issueStatus/getList']`
// * issueStatusLookup - `$store.getters['$issueStatus/getLookup']`
// * fieldsLookup - `$store.getters['issueSchema/fieldsLookup'](projectId)`
export const parseFilterQuery = (issueStatus, issueStatusLookup, fieldsLookup, query) => {
  const sorted = R.sortBy(R.identity)
  const allStatusValues = R.pipe(
    R.map(R.prop('name')),
    sorted,
  )(issueStatus)

  const evolveEnums = R.evolve(R.pipe(
    R.filter(field => field.allowedValues?.length),
    R.map(field => R.pipe(...[
      field.type === ISSUE_FIELD_TYPE.FLOAT &&
          R.map(R.unless(R.isNil, Number)),
      field.type === ISSUE_FIELD_TYPE.INTEGER &&
          R.map(R.unless(R.isNil, v => parseInt(v, 10))),
      field.type === ISSUE_FIELD_TYPE.BOOLEAN &&
          R.map(R.unless(R.isNil, Boolean)),
      R.uniq,
      R.when(
        values => R.equals(
          R.sortBy(R.identity, field.allowedValues.map(opt => opt.value)),
          values && R.sortBy(R.identity, values),
        ),
        R.always(null),
      ),
      R.when(
        R.isEmpty,
        R.always(null),
      ),
    ].filter(Boolean))),
  )(fieldsLookup))

  const replaceEnumValuesWithFullOption = R.evolve(R.pipe(
    // evolve only enums (fields with `allowedValues`)
    R.filter(field => field.allowedValues?.length),
    // replace value with full option definition
    R.map(field => R.map(value =>
      field.allowedValues.find(opt => opt.value === value))),
  )(fieldsLookup))

  return R.pipe(
    // str -> Object<str, str>
    issueFilterQueryToObject, // parse query to an object

    // Object<str, str> -> Object<str, str[]>
    R.map(value => value.split(',').filter(Boolean)), // convert values to arrays

    // Object<str, str[]> -> Object<str, str[] | int[] | null>
    // typeCast enum values && replace redundant values with `null`
    evolveEnums,

    // Object<str, str[] | int[] | null> -> Object<str, str[] | int[] | bool[] | null>
    R.evolve({ // replace redundant values with `null`
      status: status =>
        status.length === 0 || R.equals(sorted(status), allStatusValues)
          ? null
          : status,
      overdue: overdue =>
        overdue?.length
          ? overdue
          : null,
      isCompleted: isCompleted =>
        isCompleted?.length
          ? isCompleted.map(v => v === 'true')
          : null,
      ips: ips =>
        ips.length === 0 ? null : ips,
      hostnames: hostnames =>
        hostnames.length === 0 ? null : hostnames,
      ports: ports =>
        ports.length === 0 ? null : ports,
    }),

    // Object<str, str[] | int[] | bool[] | null> -> Object<str, str[] | int[] | bool[]>
    R.reject(R.isNil), // omits keys with `null` values

    // Object<str, str[] | int[] | bool[]> -> Object<str, str[] | int[] | bool[] | Object[]>
    replaceEnumValuesWithFullOption,

    // Object<str, str[] | bool[] | Object[]> -> Object<str, str[] | Object[]>
    // IPs and hostnames stay strings,
    // status & scores are replaced with their full object definitions.
    R.evolve({ // replaces filter values with corresponding definitions
      status: R.map(status => issueStatusLookup[status]),
      overdue: R.map(overdue => ({
        value: overdue,
        displayValue: {
          true: i18n.t('filter.overdue'),
          false: i18n.t('filter.notOverdue'),
          'no-sla': i18n.t('filter.NoSla'),
        }[overdue],
      })),
      isCompleted: R.map(isCompleted => ({
        value: isCompleted,
        displayValue: isCompleted ? i18n.t('filter.completed') : i18n.t('filter.notCompleted'),
      })),
    }),
  )(query)
}

// takes a `query` as an input (`status=new&totalScore=1,2`)
// returns query as an object and removes redundant values like:
// * 'totalScore=1,2,3' (all possible values (if known) filter nothing)
// * 'status=' (empty set also filters nothing).
// Output shape:
// {
//   status: [<Project>], // vuex-orm Project instances
//   status: [{ name: 'new_issue', displayName: 'New issue', ...}],
//   totalScore: [{ value: 1, displayValue: 'Low' }, ...],
//   criticalityScore: [{ value: 2, displayValue: 'Medium' }, ...],
//   probabilityScore: [...],
//   overdue: [{ value: true, displayValue: 'Overdue' }, ...],
//   isCompleted: [{ value: true, displayValue: 'Completed' }, ...],
//   ips: ['0.0.0.0', ...],
//   hostnames: ['example.com', ...],
// }
// If value is considered redundant the corresponding key is excluded
// from the output
// props:
// `db` - `VuexORM.Database` instance
// warning:
// Projects better be loaded, otherwise only limited fallback is provided
export const parseCrossProjectFilterQuery = (db, query) => {
  const Project = db.model('project')
  const ProjectGroup = db.model('projectGroup')
  const sorted = R.sortBy(R.identity)
  const sortedScoreValues = sorted(SCORE.map(score => score.value))

  const parseScore = (scoreString) => {
    const value = parseInt(scoreString, 10)
    if (!Object.prototype.hasOwnProperty.call(SCORE_LOOKUP, value)) {
      throw new Error(i18n.t('dashboard.ErrorInvalidScore', { scoreString }))
    }
    return value
  }

  const processScores = (scores) =>
    scores.length === 0 || R.equals(sorted(scores), sortedScoreValues)
      ? null
      : scores

  return R.pipe(
    // str -> Object<str, str>
    issueFilterQueryToObject, // parse query to an object

    // Object<str, str> -> Object<str, str[]>
    R.map(value => value.split(',').filter(Boolean)), // convert values to arrays

    // Object<str, str[]> -> Object<str, str[] | int[] | null>
    R.evolve({
      totalScore: R.map(parseScore),
      probabilityScore: R.map(parseScore),
      criticalityScore: R.map(parseScore),
    }),

    // Object<str, str[] | int[] | null> -> Object<str, str[] | int[] | bool[] | null>
    R.evolve({ // replace redundant values with `null`
      status: status =>
        status.length === 0
          ? null
          : status,
      totalScore: processScores,
      probabilityScore: processScores,
      criticalityScore: processScores,
      overdue: overdue =>
        overdue?.length
          ? overdue
          : null,
      isCompleted: isCompleted =>
        isCompleted?.length
          ? isCompleted.map(v => v === 'true')
          : null,
      ips: ips =>
        ips.length === 0 ? null : ips,
      hostnames: hostnames =>
        hostnames.length === 0 ? null : hostnames,
      ports: ports =>
        ports.length === 0 ? null : ports,
    }),

    // Object<str, str[] | int[] | bool[] | null> -> Object<str, str[] | int[] | bool[]>
    R.reject(R.isNil), // omits keys with `null` values

    // Object<str, str[] | int[] | bool[]> -> Object<str, str[] | Object[]>
    // IPs and hostnames stay strings,
    // status & scores are replaced with their full object definitions.
    R.evolve({ // replaces filter values with corresponding definitions
      project: R.map(projectId => Project.find(projectId) || new Project({
        id: projectId,
        name: i18n.t('dashboard.Loading'),
      })),
      groups: R.map(groupId => ProjectGroup.find(groupId) || new Project({
        id: groupId,
        name: i18n.t('dashboard.Loading'),
      })),
      status: R.map(status => ({ ...PREDEFINED_STATUS_LOOKUP[status], displayName: i18n.t(PREDEFINED_STATUS_LOOKUP[status].displayName) }) || {
        name: status,
        displayName: status,
        color: '#ccc',
        icon: 'mdi-help',
        author: null,
        listOrder: Infinity,
        syncing: null,
        system: true,
      }),
      totalScore: R.map(score => ({ ...SCORE_LOOKUP[score], displayValue: i18n.t(SCORE_LOOKUP[score].displayValue) })),
      criticalityScore: R.map(score => ({ ...SCORE_LOOKUP[score], displayValue: i18n.t(SCORE_LOOKUP[score].displayValue) })),
      probabilityScore: R.map(score => ({ ...SCORE_LOOKUP[score], displayValue: i18n.t(SCORE_LOOKUP[score].displayValue) })),
      overdue: R.map(overdue => ({
        value: overdue,
        displayValue: {
          true: i18n.t('filter.overdue'),
          false: i18n.t('filter.notOverdue'),
          'no-sla': i18n.t('filter.noSla'),
        }[overdue],
      })),
      isCompleted: R.map(isCompleted => ({
        value: isCompleted,
        displayValue: isCompleted ? i18n.t('filter.completed') : i18n.t('filter.notCompleted'),
      })),
    }),
  )(query)
}

/** @returns {String} */
export const randomBgColor = () =>
  randomChoice(DARK_COLORS)

export const uuidToInt = uuid =>
  parseInt(uuid.replace(/[^\da-f]+/g, ''), 16)

// gets a color for the given uuid, usage:
// bgColorForUuid('c9fddd2f-0912-4a2e-933c-47b6f411e3cb') === 'purple accent-2'
export const bgColorForUuid = (uuid) =>
  DARK_COLORS[uuidToInt(uuid) % DARK_COLORS.length]

/** dt formatting:
 * '15:00' // just time for today
 * 'Yesterday 15:00' // 'Yesterday' + time for yesterday
 * 'Mon 15:00' // DOW + time for the last week
 * 'Sep 3' or 'Sep 3 15:00' // this year
 * 'Sep 3 2023' or 'Sep 3 2023 15:00' // otherwise
 * @param {number | Date | Moment} dt - date to format (ISO-string, moment instance or timestamp)
 * @param {boolean} hideTimeForFarDates - hides 'H:mm[:ss]' for far dates (tomorrow, yesterday or this week)
 * @param {boolean} includeSeconds - adds seconds (':ss') to the result
 * @returns {String}
 */
export const fmtDt = (dt, {
  hideTimeForFarDates = true,
  includeSeconds = false,
} = {}) => {
  const today = moment().startOf('day')
  const yesterday = moment().subtract(1, 'day').startOf('day')
  const sixDaysAgo = moment().subtract(6, 'days').startOf('day')
  const then = moment(dt)

  const secondsFormat = includeSeconds ? ':ss' : ''

  if (then.isSame(today, 'day')) {
    return then.format('H:mm' + secondsFormat)
  } else if (then.isSame(yesterday, 'day')) {
    return 'Yesterday ' + then.format('H:mm' + secondsFormat)
  } else if (
    then.isSameOrAfter(sixDaysAgo) &&
    then.isSameOrBefore(today.clone().endOf('day'))
  ) {
    return then.format('ddd H:mm' + secondsFormat)
  } else if (then.isSame(today, 'year')) {
    return hideTimeForFarDates
      ? then.format('MMM D')
      : then.format('MMM D H:mm' + secondsFormat)
  } else {
    return hideTimeForFarDates
      ? then.format('MMM D YYYY')
      : then.format('MMM D YYYY H:mm' + secondsFormat)
  }
}

/** date formatting:
 * 'Yesterday', 'Today', 'Tomorrow' // for today +- 1 day
 * 'Sep 3' // this year
 * 'Sep 3 2023' // otherwise
 * @param {Number | Date | Moment} dt - date to format (ISO-string, moment instance or timestamp)
 * @returns {String}
 */
export const fmtDate = (dt) => {
  const today = moment().startOf('day')
  const yesterday = moment().subtract(1, 'day').startOf('day')
  const tomorrow = moment().add(1, 'day').startOf('day')
  const then = moment(dt)

  if (then.isSame(today, 'day')) {
    return i18n.t('layout.Today')
  } else if (then.isSame(yesterday, 'day')) {
    return i18n.t('layout.Yesterday')
  } else if (then.isSame(tomorrow, 'day')) {
    return i18n.t('layout.Tomorrow')
  } else if (then.isSame(today, 'year')) {
    return then.format('MMM D')
  } else {
    return then.format('MMM D YYYY')
  }
}

export const pushRoute = (router, route) =>
  router.push(route)
    .catch(error => {
      if (error.name !== 'NavigationDuplicated') throw error
    })

export const replaceRoute = (router, route) =>
  router.replace(route)
    .catch(error => {
      if (error.name !== 'NavigationDuplicated') throw error
    })

// Expand Vue class definition to the object notation
// See https://vuejs.org/v2/guide/class-and-style.html#Object-Syntax
// expandVueClasses('') == {}
// expandVueClasses('foo bar') == { foo: true, bar: true }
// expandVueClasses([]) == {}
// expandVueClasses(['foo', false, null, 'bar']) == { foo: true, bar: true }
// expandVueClasses({ foo: true, bar: true }) == { foo: true, bar: true }
export const expandVueClasses = R.cond([
  [
    R.isEmpty,
    R.always({}),
  ],
  [
    R.is(String),
    classes => classes
      .split(/\s+?/g)
      .filter(Boolean)
      .reduce((acc, cls) => {
        acc[cls] = true
        return acc
      }, {}),
  ],
  [
    R.is(Array),
    classes => classes
      .filter(Boolean)
      .reduce((acc, cls) => {
        acc[cls] = true
        return acc
      }, {}),
  ],
  [R.T, R.identity],
])

// Notifies with https://developer.mozilla.org/en-US/docs/Web/API/notification
// and handles boilerplate asking for permission, etc.
export const notifyLocal = message => {
  if (!('Notification' in window)) {
    console.warn('Notification API is missing')
    console.warn('Failed to notify:', message)
    return
  }
  if (Notification.permission === 'granted') return new Notification(message)
  return Notification.requestPermission()
    .then((perm) => {
      if (perm === 'granted') return new Notification(message)
      else {
        console.warn('Cannot use Notification API, got permission: ' + perm)
        console.warn('Failed to notify:', message)
      }
    })
}

export const extractFilenameFromContentDisposition =
  (contentDisposition = '') =>
    (
      contentDisposition &&
      contentDisposition.indexOf('filename=') !== -1 &&
      contentDisposition.slice(
        contentDisposition.indexOf('filename=') + 'filename='.length,
        contentDisposition.length,
      )
    ) || ''
