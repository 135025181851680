import * as R from 'ramda'
import Vue from 'vue'

import { ProjectService } from '../../api'
import { ISSUE_FIELD_TYPE } from '../../constants'
import { handleError } from '../../helpers'

const initState = () => ({
  projectToSchema: {}, // Object<ProjectId, IssueSchema>
})

const getters = {
  /** Get issue schema (aka preset) for `projectId`
   * `undefined` when not loaded
   * `null` when server returned 404 response (no preset in project)
   */
  get: ({ projectToSchema }) =>
    projectId =>
      projectToSchema[projectId],

  fields: (_, { get }) =>
    (projectId, includeHidden = false) => {
      const preset = get(projectId)
      return preset && R.pipe(
        R.pick(['additionalFieldsSettings', 'predefinedFieldsSettings']),
        R.evolve({
          additionalFieldsSettings: R.map(R.assoc('predefined', false)),
          predefinedFieldsSettings: R.map(R.assoc('predefined', true)),
        }),
        R.values,
        R.flatten,
        R.filter(f => includeHidden || !f.hidden),
        R.sort(R.ascend(field => preset.order.indexOf(field.name))),
      )(preset)
    },

  fieldsLookup: (_, { fields: getFields }) =>
    (projectId, includeHidden = false) => {
      const fields = getFields(projectId, includeHidden)
      return fields && R.indexBy(R.prop('name'), fields)
    },

  filterableFields: (_, { fields: getFields }) =>
    (projectId) => {
      const fields = getFields(projectId)
      const isFilterableEnumField = R.both(
        R.propSatisfies(
          type =>
            [ISSUE_FIELD_TYPE.TEXT, ISSUE_FIELD_TYPE.INTEGER].includes(type),
          'type',
        ),
        R.propSatisfies(
          allowedValues => !!allowedValues?.length,
          'allowedValues',
        ),
      )
      const isBooleanField =
        R.propSatisfies(R.equals(ISSUE_FIELD_TYPE.BOOLEAN), 'type')
      const isFilterable = R.either(isFilterableEnumField, isBooleanField)
      return R.unless(
        R.isNil,
        R.filter(isFilterable),
        fields,
      )
    },
}

const mutations = {
  reset: state => Object.assign(state, initState()),

  set: (state, { projectId, schema }) => {
    Vue.set(state.projectToSchema, projectId, schema)
  },
}

const actions = {
  get: ({ commit, getters }, { projectId, reload = true }) =>
    !reload && getters.get(projectId)
      ? Promise.resolve(undefined)
      : ProjectService.getIssueCustomSchema({ projectId })
        .catch(e => {
          if (e?.response?.status === 404) return null // No schema
          throw e
        })
        .then(schema => commit('set', { projectId, schema }))
        .catch(e => handleError({ commit }, e)),
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
