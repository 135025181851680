import { axiosInstance as axios, ApplicationConnectService } from '../../api'
import { handleError, extractFilenameFromContentDisposition } from '../../helpers'

const getFormData = hashMap => {
  const formData = new FormData()
  Object.entries(hashMap).forEach(([key, value]) =>
    formData.append(key, value))
  return formData
}

const initState = () => ({
  appConnections: {},
  appConnectionsOrder: null,
})

export default {
  namespaced: true,

  state: initState,

  getters: {
    appConnection: ({ appConnections }) =>
      appConnectionId => // id
        appConnections[appConnectionId] ?? null,
    appConnections: ({ appConnectionsOrder: order }, { appConnection: getAppConnection }) =>
      order && order.map(id => getAppConnection(id)),
  },

  mutations: {
    reset: state => Object.assign(state, initState()),
    setAppConnections: (state, { appConnections }) => {
      if (appConnections == null) {
        state.appConnections = {}
        state.appConnectionsOrder = null
      } else {
        state.appConnections = appConnections.reduce((lookup, appConnection) => {
          lookup[appConnection.id] = appConnection
          return lookup
        }, {})
        state.appConnectionsOrder = appConnections.map(c => c.id)
      }
    },

  },

  actions: {
    getAppConnections: async ({ commit }) =>
      ApplicationConnectService.getApplicationConnects()
        .then(appConnections => commit('setAppConnections', { appConnections }))
        .catch(e => handleError({ commit }, e)),

    getAppConnectionProjects: async ({ commit }, { applicationConnectId }) => {
      return ApplicationConnectService.getProjectsByAppConnect({ applicationConnectId })
        // .then(appConnections => commit('setAppConnections', { appConnections }))
        .catch(e => handleError({ commit }, e))
    },

    // Initiates new app connection.
    // Returns axios-response, url of ZIP archive with the config, filename.
    createAppConnection: ({ commit }) =>
      axios.post('/application_connects/', getFormData({
        type: 'PRIMARY',
      }), {
        responseType: 'blob',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const configUrl = URL.createObjectURL(response.data)
          const contentDisposition = response.headers['content-disposition']
          const supposedFilename = extractFilenameFromContentDisposition(response.headers['content-disposition'])
          return {
            response, // raw axios response
            configUrl, // downloadable URL for zip-file with yml-config
            contentDisposition, // Content-Disposition response header value
            supposedFilename, // Filename from Content-Disposition
          }
        })
        .catch(e => handleError({ commit }, e)),
    // .finally(() => commit('addAppConnection', { appConnection: createdAppConnection }))

    patchAppConnection: ({ commit }, { applicationConnectId, name }) => {
      ApplicationConnectService.patchApplicationConnect({ applicationConnectId, body: { name } })
        .catch(e => handleError({ commit }, e))
    },

    deleteAppConnection: ({ commit }, { applicationConnectId }) =>
      ApplicationConnectService.deleteApplicationConnect({ applicationConnectId })
        // .then(() => commit('removeTemplate', { applicationConnectId }))
        .catch(e => handleError({ commit }, e)),

    createProject: ({ commit }, { applicationConnectId, project, localUsers = [], remoteUsers = [] }) =>
      ApplicationConnectService.postProjectAppConnect({ applicationConnectId, body: { projectData: project, localUsers, remoteUsers } })
        .then(project => {
          return project.id
        })
        .catch(e => handleError({ commit }, e)),

    getRemoteUsers: ({ commit }, { applicationConnectId, forceUpdate = false }) =>
      ApplicationConnectService.getUsersListByAppConnect({ applicationConnectId, forceUpdate })
        .then(response => {
          return response
        })
        .catch(e => handleError({ commit }, e)),
  },
}
