import { Model } from '@vuex-orm/core'
import moment from 'moment'
import * as R from 'ramda'

import { Project } from './_models'
import { ProjectGroupService } from '../../api'
import { handleError } from '../../helpers'
import i18n from '../../i18n'

export class ProjectGroup extends Model {
  static entity = 'projectGroup'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.string(null).nullable(),
      name: this.string(''),
      description: this.string(''),
      created: this.string('').nullable(),
      updated: this.string('').nullable(),
      parentID: this.string(null).nullable(),
      projects: this.hasMany(Project, 'groupID'),
      children: this.hasMany(ProjectGroup, 'parentID'),
    }
  }

  static getRootGroupQ() {
    return ProjectGroup
      .query()
      .where('parentID', null)
  }

  static treeLoaded() {
    return ProjectGroup.getRootGroupQ().exists()
  }

  getMaybeParentQ() {
    if (!this.parentID) return null
    return ProjectGroup.query().where('id', this.parentID)
  }

  getChildrenQ() {
    return ProjectGroup.query().where('parentID', this.id)
  }

  getProjectsQ() {
    return Project.query().where('groupID', this.id)
  }

  get isRoot() { return this.parentID == null }

  get isEmpty() {
    if (this.children.length || this.projects.length) return false
    return !this.getChildrenQ().exists() && !this.getProjectsQ().exists()
  }

  // gets path from root group to this group as a ProjectGroup[] array
  getPath({ includeRoot = true, includeSelf = true, topToBottom = true } = {}) {
    let next = this
    const nextParent = () => {
      const parentId = next.parentID
      if (!parentId) return null
      else {
        const parent = next.getMaybeParentQ().first()
        if (!parent) throw new Error(i18n.t('project.ErrorParentGroupNotLoadedM', { parentId }))
        return parent
      }
    }
    const pathFromSelf = []
    if (includeSelf) pathFromSelf.push(this)
    while ((next = nextParent()) != null) {
      if (includeRoot || !next.isRoot) pathFromSelf.push(next)
    }
    return topToBottom ? pathFromSelf.reverse() : pathFromSelf
  }

  getAllDescendants({
    includeSelf = false,
    includeProjects = true,
  } = {}) {
    const res = includeSelf ? [this] : []

    const addGroupRecursive = (group) => {
      if (group.id !== this.id || includeSelf) res.push(group)
      if (includeProjects) {
        for (const project of group.getProjectsQ().all()) {
          res.push(project)
        }
      }
      for (const childGroup of group.getChildrenQ().all()) {
        addGroupRecursive(childGroup)
      }
    }

    addGroupRecursive(this)

    return res
  }

  /** Mixed child groups and projects sorted by provided prop and direction
   * NB! Groups are always sorted by name only (asc or desc),
   * if any other `sortProp` is given - it applies only to projects and
   * groups will be sorted by name asc. */
  getSortedDescendants(sortProp = 'name', sortDirection = 'asc') {
    const groups = this.children.length
      ? this.children
      : this.getChildrenQ().with('projects').with('children').all()
    const projects = this.projects.length
      ? this.projects
      : this.getProjectsQ().all()

    const textCollatorCmp = (new Intl.Collator('en', { sensitivity: 'base' })).compare

    if (sortProp === 'name') {
      const cmp = (a, b) => textCollatorCmp(a.name, b.name) * (sortDirection === 'asc' ? 1 : -1)
      return R.sortWith([
        cmp,
        (sortDirection === 'asc' ? R.ascend : R.descend)(R.prop('id')),
      ], [
        ...groups,
        ...projects,
      ])
    } else {
      const propCollatorCmp =
        ['startDate', 'endDate'].includes(sortProp)
          ? (a, b) =>
            !a && !b ? 0 : !a && b ? 1 : a && !b ? -1
              : moment(a).isSame(b) ? 0 : moment(a).isBefore(b) ? -1 : 1
          : textCollatorCmp
      const groupCmp = (a, b) => textCollatorCmp(a.name, b.name)
      const projectCmp = (a, b) => propCollatorCmp(a[sortProp], b[sortProp]) * (sortDirection === 'asc' ? 1 : -1)
      return [
        ...R.sortWith([
          groupCmp,
          R.ascend(R.prop('id')),
        ], groups),
        ...R.sortWith([
          projectCmp,
          (sortDirection === 'asc' ? R.ascend : R.descend)(R.prop('id')),
        ], projects),
      ]
    }
  }

  /** returns count of descendants (projects & groups), doesn't include `this` group */
  countDescendants() {
    const countGroup = (group) => {
      const groups = group.children.length
        ? group.children
        : group.getChildrenQ().with('projects').with('children').all()
      const projects = group.projects.length
        ? group.projects
        : group.getProjectsQ().all()
      return projects.length +
        groups.length +
        groups.reduce((sum, g) => sum + countGroup(g), 0)
    }
    return countGroup(this)
  }

  getIcon(expanded) {
    if (this.isEmpty) {
      return expanded ? '$folder-open-outline' : '$folder-outline'
    } else {
      return expanded ? '$folder-open' : '$folder'
    }
  }

  _searchIndex = null

  get searchIndex() {
    if (this._searchIndex != null) return this._searchIndex
    return (this._searchIndex = this._calcSearchIndex())
  }

  _calcSearchIndex() {
    return [
      this.name,
      this.description,
    ]
      .filter(Boolean)
      .map(s => s.trim().toLowerCase())
      .flatMap(s => s.split(/\s+?/g).filter(Boolean))
      .join(' ')
  }
}

export const ProjectGroupModule = {
  actions: {
    async $getTree(ctx, { reload = true } = {}) {
      if (!reload && ProjectGroup.treeLoaded()) return
      try {
        const rootGroup = await ProjectGroupService.getProjectGroupsTree()
        await ProjectGroup.create({ data: rootGroup })
      } catch (e) {
        await handleError(ctx, e, i18n.t('project.ErrorFailedFetchingProjectGroupTreeM'))
      }
    },

    async $getOne(ctx, { reload = true, projectGroupId }) {
      if (!reload && ProjectGroup.query().where('id', projectGroupId).exists()) return
      try {
        const group = await ProjectGroupService.getProjectGroup({ projectGroupId })
        await ProjectGroup.insertOrUpdate({ data: group })
      } catch (e) {
        await handleError(ctx, e, i18n.t('project.ErrorFailedFetchingProjectGroupM'))
      }
    },

    async $create(ctx, { projectGroup }) {
      try {
        const createdGroup = await ProjectGroupService.postProjectGroup({ body: R.reject(R.isNil, projectGroup) })
        await ProjectGroup.insert({ data: createdGroup })
        if (projectGroup.projectsBatch?.length) {
          const projectIds = new Set(projectGroup.projectsBatch.map(({ id }) => id))
          await Project.update({
            data: { groupID: createdGroup.id },
            where: p => projectIds.has(p.id),
          })
        }
        if (projectGroup.childrenBatch?.length) {
          const groupIds = new Set(projectGroup.childrenBatch.map(({ id }) => id))
          await ProjectGroup.update({
            data: { parentID: createdGroup.id },
            where: g => groupIds.has(g.id),
          })
        }
      } catch (e) {
        await handleError(ctx, e, i18n.t('project.ErrorFailedCreatingProjectGroupM'))
      }
    },

    async $update(ctx, { projectGroup: { id: projectGroupId, ...body } }) {
      try {
        const updatedGroup = await ProjectGroupService.patchProjectGroup({
          projectGroupId,
          body,
        })
        await ProjectGroup.insertOrUpdate({ data: updatedGroup })
        if (body.projectsBatch?.length) {
          const projectIds = new Set(body.projectsBatch.map(({ id }) => id))
          await Project.update({
            data: { groupID: projectGroupId },
            where: p => projectIds.has(p.id),
          })
        }
        if (body.childrenBatch?.length) {
          const groupIds = new Set(body.childrenBatch.map(({ id }) => id))
          await ProjectGroup.update({
            data: { parentID: projectGroupId },
            where: g => groupIds.has(g.id),
          })
        }
      } catch (e) {
        await handleError(ctx, e, i18n.t('project.ErrorFailedUpdatingProjectGroupM'))
      }
    },

    async $delete(ctx, { projectGroupId }) {
      const deleteRecursive = async (group) => {
        for (const child of ProjectGroup.query().where('parentID', group.id).all()) {
          await deleteRecursive(child)
        }
        await group.$delete()
      }
      try {
        await ProjectGroupService.deleteProjectGroup({ projectGroupId })
        await deleteRecursive(ProjectGroup.find(projectGroupId) || (new ProjectGroup({ id: projectGroupId })))
      } catch (e) {
        await handleError(ctx, e, i18n.t('project.ErrorFailedDeletingProjectGroupM'))
      }
    },
  },
}

export default ProjectGroup
