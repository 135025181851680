import { helpers } from '@hexway/shared-front'
import combineUrls from 'axios/lib/helpers/combineURLs'
import moment from 'moment'
import URI from 'urijs'
import Vue from 'vue'

import { API_BASE, axiosInstance as axios, ReportTemplateService } from '../../api'
import { handleError } from '../../helpers'
import i18n from '../../i18n'

import Project from '../orm/project'

const initState = () => ({
  templates: {},
  templatesOrder: null,
})

const getters = {
  template: ({ templates }) =>
    templateId => // uuid
      templates[templateId] ?? null,

  templates: ({ templatesOrder: order }, { template: getTemplate }) =>
    order && order.map(id => getTemplate(id)),
}

const mutations = {
  reset: (state) =>
    Object.assign(state, initState()),

  setTemplates: (state, { templates }) => {
    if (templates == null) {
      state.templates = {}
      state.templatesOrder = null
    } else {
      state.templates = templates.reduce((lookup, template) => {
        lookup[template.uuid] = template
        return lookup
      }, {})
      state.templatesOrder = templates.map(t => t.uuid)
    }
  },

  addTemplate: ({ templatesOrder, templates }, { template }) => {
    Vue.set(templates, template.uuid, template)
    if (!templatesOrder.includes(template.uuid)) {
      templatesOrder.push(template.uuid)
    }
  },

  removeTemplate: ({ templates, templatesOrder: order }, { templateId }) => {
    let ix = -1
    while ((ix = order.indexOf(templateId)) !== -1) order.splice(ix, 1)
    Vue.delete(templates, templateId)
  },
}

const actions = {
  getTemplates: async ({ commit, getters }, { reload = true } = {}) =>
    (!reload && getters.templates != null) ||
    ReportTemplateService.reportTemplatesGet()
      .then(templates => commit('setTemplates', { templates }))
      .catch(e => handleError({ commit }, e)),

  downloadTemplate: ({ commit }, { template }) => {
    // return ReportTemplateService.reportTemplateGet({ templateId: template.uuid })
    //   .then((binaryResponse) => downloadToUserMachine(binaryResponse))
    //   .catch(e => handleError({ commit }, e))

    const url = `/report_template/${template.uuid}/`
    return axios.head(url)
      .then(() => {
        const a = document.createElement('a')
        a.href = combineUrls(API_BASE, url)
        a.download = template.fileName?.trim?.() || 'template.docx'
        a.target = '_blank'
        a.click()
      })
      .catch((error) => handleError({ commit }, error))
  },

  uploadTemplate: async ({ commit }, { file }) => {
    // return ReportTemplateService.reportTemplateGet({ templateId: template.uuid })
    //   .then((binaryResponse) => downloadToUserMachine(binaryResponse))
    //   .catch(e => handleError({ commit }, e))
    let createdTemplate
    try {
      const formData = new FormData()
      formData.append('template', file)
      const response = await axios.post('/report_template/', formData)
      if (!response.data?.uuid) {
        throw new Error(i18n.t('report.ErrorExpectedReportTemplateM'))
      }
      createdTemplate = response.data
    } catch (e) {
      return handleError({ commit }, e)
    }

    commit('addTemplate', { template: createdTemplate })
  },

  deleteTemplate: ({ commit }, { templateId }) =>
    ReportTemplateService.reportTemplateDelete({ templateId })
      .then(() => commit('removeTemplate', { templateId }))
      .catch(e => handleError({ commit }, e)),

  downloadReport: ({ commit }, { projectId, template, debug, selectedStatusNames = undefined }) => {
    // return ProjectService.projectReportGet({ projectId, templateId: template.uuid })
    //   .then((binaryResponse) => downloadToUserMachine(binaryResponse))
    //   .catch(e => handleError({ commit }, e))

    // const url = `/project/${projectId}/report/?templateID=${template.uuid}`
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const locale = Intl.DateTimeFormat().resolvedOptions().locale
    const url = `/reports/f/${projectId}`
    const params = {
      templateId: template.uuid,
      status: selectedStatusNames,
      debug: String(!!debug),
      timezone,
      locale,
    }
    return axios.head(url, { params })
      .then(() => {
        const a = document.createElement('a')
        a.href = combineUrls(API_BASE, URI(url).setSearch(params).toString())
        const projectName = Project.find(projectId)?.name
        const now = moment().toISOString()
        a.download = projectName
          ? `${helpers.slugifyBase(projectName) || 'report'}.docx`
          : `[${now}] report.docx`
        a.target = '_blank'
        a.click()
      })
      .catch(() => axios.get(url, { params }))
      .catch((error) => handleError({ commit }, error))
  },
}

export default {
  namespaced: true,

  state: initState,
  getters,
  actions,
  mutations,
}
