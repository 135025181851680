<template>
  <div
    class="AppTable"
    role="table"
  >
    <slot name="prepend" />

    <div class="AppTable__header">
      <div
        v-for="header in headers"
        :key="header.value"
        v-ripple="header.sortable !== false ? { class: 'app-ripple' } : false"
        class="AppTable__cell AppTable__cell--header"
        :style="{ flex: header.flex || '1 0 0' }"
        v-bind="headerBind(header)"
        @click="header.sortable !== false && toggleSort(header.value)"
      >
        <div class="AppTable__header-text">
          <slot
            :name="'header.' + header.value"
            :header="header"
          >
            {{ header.text }}
          </slot>
        </div>

        <span
          v-if="header.sortable !== false"
          class="AppTable__sort-icon"
          :class="{
            'AppTable__sort-icon--active': sort.prop === header.value,
            'AppTable__sort-icon--desc': sort.prop === header.value && sort.desc === true,
          }"
          v-text="'↓'"
        />
      </div>
    </div>

    <component
      :is="rowTo != null ? 'router-link' : 'div'"
      v-for="item in sortedItems"
      :key="item[item.id]"
      v-ripple="$listeners['click:row'] != null || rowTo != null ? { class: 'app-ripple' } : false"
      class="AppTable__row"
      v-bind="rowBind(item)"
      :style="{ background: resolveItemFunction(rowBackground, item) }"
      @click="$emit('click:row', item)"
    >
      <component
        :is="header.to != null ? 'router-link' : 'div'"
        v-for="header in headers"
        :key="header.value"
        v-ripple="header.click != null || header.to != null ? { class: 'app-ripple' } : false"
        class="AppTable__cell AppTable__cell--item"
        v-bind="itemBind(header, item)"
        :style="{
          flex: header.flex || '1 0 0',
          whitespace: header.nowrap ? 'nowrap' : null,
        }"
        @click.native="$event.stopPropagation()"
        @click="header.click != null &&
          (header.click(item, $event), $event.stopPropagation(), $event.preventDefault())"
      >
        <v-tooltip
          :disabled="!getTooltip(header, item)"
          bottom
          nudge-top="12"
          open-delay="500"
        >
          <template #activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <div
                class="AppTable__cell-contents"
                v-bind="attrs"
                v-on="on"
              >
                <slot
                  :name="'item.' + header.value"
                  :item="item"
                  :header="header"
                  :value="item[header.value]"
                  :displayValue="displayValue(header, item)"
                  :hover="hover"
                >
                  <span
                    class="AppTable__item-text"
                    v-text="displayValue(header, item)"
                  />
                </slot>
              </div>
            </v-hover>
          </template>
          <span v-text="getTooltip(header, item)" />
        </v-tooltip>
      </component>
    </component>

    <slot name="append" />
  </div>
</template>

<script>
import * as R from 'ramda'

export default {
  name: 'AppTable',

  // props & slots mimic those of v-data-table whenever possible
  // https://vuetifyjs.com/en/api/v-data-table/
  props: {
    // Header props:
    // text - string
    // value - string - item prop
    // displayValue - (Item -> string)?
    // sortable - boolean? - to disable sort, on by default
    // nowrap - boolean? - apply nowrap style to items' cells
    // click - (Item -> void)? -> click handler
    // to - (string | Route)? -> cell should behave as router-link
    // replace - boolean? -> router-link replace prop
    // exact - boolean? -> router-link exact prop
    // flex - string - css flex style, default '1 0 0'
    // align - 'left' | 'right' | 'center' - cell content alignment
    // tooltip - string | (Item -> string) - cell tooltip
    headers: { type: Array, required: true },
    items: { type: Array, required: true },
    itemKey: { type: String, default: 'id' },
    // string with css color or function: (Item) -> CssColor
    rowBackground: { type: [String, Function], default: null },
    mustSort: { type: Boolean, default: false },

    // router-link integration with table rows,
    // functions take item as a single argument and should return corresponding
    // router-link's value
    rowTo: { type: [String, Object, Function], default: null },
    rowExact: { type: [Boolean, Function], default: false },
    rowReplace: { type: [Boolean, Function], default: false },

    sortBy: { type: String, default: null }, // .sync
    sortDesc: { type: Boolean, default: null }, // .sync
  },

  data() {
    let sortProp = this.sortBy || null
    if (this.mustSort) sortProp = this.headers[0]?.value ?? null
    return {
      sort: {
        prop: sortProp,
        desc: !!this.sortDesc,
      },
    }
  },

  computed: {
    // cells() {
    //   const table = R.xprod(this.items, this.headers)
    //   return table.map(([item, header]) => ({
    //     item,
    //     header,
    //     key: `cell~${header.value}~${item[this.itemKey]}`,
    //     text: this.displayValue(header, item),
    //   }))
    // },

    // sortedCells() {
    //   const { sort, cells } = this
    //   if (!sort.prop) return cells
    //   const sortOrder = sort.desc ? R.descend : R.ascend
    //   return R.sort(sortOrder(cell => cell.item[sort.prop] ?? ''), cells)
    // },

    sortedItems() {
      const { sort, items } = this
      if (!sort.prop) return items
      const sortOrder = sort.desc ? R.descend : R.ascend
      return R.sort(sortOrder(item => item[sort.prop] ?? ''), items)
    },
  },

  watch: {
    sortBy(sortBy) {
      if (this.sort.prop !== sortBy) this.sort.prop = sortBy || null
    },
    'sort.prop'(sortBy) {
      this.$emit('update:sortBy', sortBy)
    },
    sortDesc(sortDesc) {
      if (this.sort.desc !== !!sortDesc) this.sort.desc = !!sortDesc
    },
    'sort.desc'(sortDesc) {
      this.$emit('update:sortDesc', sortDesc)
    },
  },

  methods: {
    toggleSort(prop) {
      if (this.sort.prop === prop) {
        if (this.sort.desc && !this.mustSort) {
          this.sort = { prop: null, desc: false }
        } else {
          this.sort.desc = !this.sort.desc
        }
      } else {
        this.sort = { prop, desc: false }
      }
    },

    displayValue(header, item) {
      if (header.displayValue) return header.displayValue(item)
      return item[header.value]
    },

    getTooltip(header, item) {
      return this.resolveItemFunction(header.tooltip, item)
    },

    headerBind(header) {
      const bind = {}
      const classes = []
      if (header.sortable ?? true) {
        Object.assign(bind, { tabindex: '0', role: 'button' })
        classes.push('AppTable__cell--clickable')
      }
      if (header.align === 'center') {
        classes.push('AppTable__cell--center')
      }
      if (header.align === 'right') {
        classes.push('AppTable__cell--right')
      }
      if (classes.length) {
        bind.class = classes.join(' ')
      }
      return bind
    },

    rowBind(item) {
      const { rowTo, rowExact, rowReplace } = this

      const bind = {}
      if (rowTo != null || this.$listeners['click:row'] != null) {
        Object.assign(bind, { tabindex: '0', class: 'AppTable__row--clickable' })
      }
      if (rowTo != null) {
        bind.to = this.resolveItemFunction(rowTo, item)
        bind.replace = this.resolveItemFunction(rowReplace, item) || false
        bind.exact = this.resolveItemFunction(rowExact, item) || false
      }
      return bind
    },

    itemBind(header, item) {
      const bind = {}
      const classes = []
      if (header.click || header.to != null) {
        Object.assign(bind, { tabindex: '0', role: 'button' })
        classes.push('AppTable__cell--clickable')
      }
      if (header.to != null) {
        bind.to = this.resolveItemFunction(header.to, item)
        bind.replace = this.resolveItemFunction(header.replace, item) || false
        bind.exact = this.resolveItemFunction(header.exact, item) || false
      }
      if (header.align === 'center') {
        classes.push('AppTable__cell--center')
      }
      if (header.align === 'right') {
        classes.push('AppTable__cell--right')
      }
      if (classes.length) {
        bind.class = classes.join(' ')
      }
      return bind
    },

    resolveItemFunction(fnOrValue, item) {
      if (R.is(Function, fnOrValue)) return fnOrValue(item)
      return fnOrValue
    },
  },
}
</script>

<style lang="sass" scoped>
.AppTable
  background: #FFFFFF
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) // Elevation / 02
  border-radius: 8px
  grid-column-gap: 16px
  padding-bottom: 28px
  overflow: hidden
  text-overflow: ellipsis

  &__header
    display: flex
    padding-right: 20px
    color: #8B90A0

  &__cell
    display: flex
    align-items: center
    overflow: hidden
    text-overflow: ellipsis
    padding: 0 24px
    &--header
      white-space: nowrap
      height: 64px
      border-bottom: 1px solid #F0F1F3
      margin-bottom: 6px
    &--item
      white-space: nowrap
      height: 56px
      align-items: stretch
    &--clickable
      transition: all 150ms ease-out
      cursor: pointer
      user-select: none
      color: inherit
      text-decoration: none
    &--center
      justify-content: center
    &--right
      justify-content: flex-end

    & + &
      margin-left: 16px

  &__cell-contents
    display: flex
    align-items: center

  &__cell--center &__cell-contents
      justify-content: center
  &__cell--right &__cell-contents
      justify-content: flex-end

  &__row
    display: flex
    padding-right: 20px

    &--clickable
      transition: all 150ms ease-out
      cursor: pointer
      user-select: none
      color: inherit
      text-decoration: none

    &:hover, &:active, &:focus
      background: rgba(229, 229, 255, 0.3) !important

  &__header-text
    flex: 0 1 auto
    overflow: hidden
    text-overflow: ellipsis
    line-height: 64px

  &__item-text
    overflow: hidden
    text-overflow: ellipsis

  &__sort-icon
    line-height: 64px
    margin-left: 9px
    transition: all 150ms ease-out
    color: #BDBDBD
    &--active
      color: #1066E5
    &--desc
      transform: rotate(-180deg)
</style>
