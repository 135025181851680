<template functional>
  <div
    class="AppDashed"
    :class="{ [data.staticClass || '']: true, ...data.class }"
  >
    <span class="AppDashed__title">
      <slot name="title">
        {{ props.title }}
      </slot>
    </span>
    <span
      class="AppDashed__connection"
      :style="{ color: props.connectionColor }"
    />
    <span class="AppDashed__value">
      <slot name="value">
        {{ props.value }}
      </slot>
    </span>

    <slot name="append" />
  </div>
</template>

<script>
export default {
  name: 'AppDashed',

  functional: true,

  props: {
    title: { type: String, default: '' },
    value: { type: String, default: '' },
    connectionColor: { type: String, default: 'rgba(0, 0, 0, .2)' },
  },
}
</script>

<style lang="sass" scoped>
.AppDashed
  display: flex

  &__title
    display: inline-block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &__connection
    display: inline-block
    flex: 1
    border-bottom: 1px dashed currentColor
    align-self: center
    height: 2ex

  &__value
    display: inline-block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    flex-shrink: 0
</style>
