import { ProjectService } from '../../api'
import { handleError } from '@/helpers'
import Vue from 'vue'
const eachDeep = require('deepdash/eachDeep')

const initState = () => ({
  byId: {}, // Object<int, Checklist>
  checklists: [], // Array<Object>
  // uuid of selected checklist
  selectedChecklist: '',
  checklistDoneFilter: '', // String, e.g. done, not_done, indeterminate, inapplicable
})

export default {
  namespaced: true,
  state: initState,

  getters: {
    get: ({ byId }) =>
      checklistId =>
        byId[checklistId] || null,
  },

  mutations: {
    reset: state => Object.assign(state, initState()),

    setChecklist(state, { checklists }) {
      eachDeep(checklists, (value, key, checklist) => {
        if (key === 'uuid' && checklist.done) {
          Vue.set(checklist, '$folded', state.byId[value]?.$folded || false)
          Vue.set(state.byId, checklist.uuid, checklist)
        }
      })

      state.checklists = checklists
    },
  },

  actions: {
    getChecklist: ({ commit }, { projectId }) =>
      ProjectService.projectChecklistGet({ projectId })
        .then(checklists => checklists.content)
        .then(checklists => {
          commit('setChecklist', { checklists })
        })
        .catch(e => handleError({ commit }, e)),
  },
}
