import * as R from 'ramda'
import Vue from 'vue'

import { ProjectService } from '../../api'
import { handleError, compareIps, compareHostnames } from '../../helpers'

const initState = () => ({
  // now it is always an empty object, but may have some props in future
  settings: null,

  allIps: {}, // Object<ProjectUuid, Array<String>>
  allHostnames: {}, // Object<ProjectUuid, Array<String>>
  allPorts: {}, // Object<ProjectUuid, Array<String>>
})

export default {
  namespaced: true,

  state: initState,

  getters: {
    // For filters: all possible ids and hostnames per project
    allIps: ({ allIps }) =>
      projectId => {
        const ips = allIps[projectId] ?? null
        return ips && R.sort(compareIps, ips)
      },
    allHostnames: ({ allHostnames }) =>
      projectId => {
        const hostnames = allHostnames[projectId] ?? null
        return hostnames && R.sort(compareHostnames, hostnames)
      },
    allPorts: ({ allPorts }) =>
      projectId => {
        const ports = allPorts[projectId] ?? null
        return ports && R.sort((a, b) => a - b, ports).map(port => port.toString())
      },
  },

  mutations: {
    reset: state => Object.assign(state, initState()),

    set: (state, { settings }) => {
      state.settings = settings
    },

    // stores all possible ips and hostnames (from all issues for now ¯\_(ツ)_/¯)
    setFilterVariants: (state, { projectId, issues }) => {
      Vue.set(state.allIps, projectId, R.pipe(
        R.map(R.prop('ips')),
        R.reject(R.isNil),
        R.flatten,
        R.reject(R.isNil),
        R.uniq,
      )(issues))
      Vue.set(state.allHostnames, projectId, R.pipe(
        R.map(R.prop('hostnames')),
        R.reject(R.isNil),
        R.flatten,
        R.reject(R.isNil),
        R.uniq,
      )(issues))
      Vue.set(state.allPorts, projectId, R.pipe(
        R.map(R.prop('ports')),
        R.reject(R.isNil),
        R.flatten,
        R.reject(R.isNil),
        R.uniq,
      )(issues))
    },
  },

  actions: {
    // commented in favor of $issueStatus module, but some other settings may be added in future
    // fetches app-level settings (currently only status enum)
    get: async ({ commit }) => {
      try {
        const settings = await ProjectService.projectsSettingsGet()
        commit('set', { settings })
      } catch (e) {
        handleError({ commit }, e)
      }
    },

    // fetches all issues to store all possible IPs/hostnames/ports
    getFilterVariants: async ({ commit, getters }, { projectId, reload = true }) => {
      try {
        if (!reload && getters.allIps(projectId) && getters.allHostnames(projectId) && getters.allPorts(projectId)) return
        const issues = await ProjectService.projectIssuesGet({ projectId })
        commit('setFilterVariants', { projectId, issues })
      } catch (e) {
        handleError({ commit }, e)
      }
    },
  },
}
