import { CancelToken } from 'axios'
import * as R from 'ramda'

import { plainObjectHash } from '../helpers'

// Map<str, (() => void)>
// key: action dispatch key (action name) + significant arguments
// value: cancel function
// example: new Map([ ['getList-<UUID>', () => {/* cancels request /*}] ])
const activeActions = new Map()

const genKey = R.cond([
  [R.anyPass([R.is(Number), R.is(String), R.isNil]), v => `id:${v}`],
  [R.T, v => `hash:${plainObjectHash(v)}`],
])

// Usage:
// await withCancelToken(
//   (cancelToken) => MyService.post(data, { cancelToken }),
//   'my-action-id', // any id string, number or plain object/array, that will be hashed to generate key
// )
export const withCancelToken = (action, actionIdSource) => {
  // handle cancellation of consequent requests
  const actionKey = genKey(actionIdSource)
  const cancelPreviousAction = activeActions.get(actionKey)
  if (cancelPreviousAction) cancelPreviousAction()

  // setup cancellation for the next request
  const cancelSource = CancelToken.source()
  activeActions.set(actionKey, () =>
    cancelSource.cancel(`New request was fired for action ${actionKey} (based on provided ${JSON.stringify(actionIdSource)})`))

  return action(cancelSource.token)
}
