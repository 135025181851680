<template>
  <v-combobox
    ref="vuetifyCombobox"
    class="AppCombobox"
    :class="{ ...stateClasses, ...marginsClasses }"
    v-bind="$attrs"
    :hide-details="shouldHideDetails"
    :hint="hint"
    :error="error"
    :error-messages="errorMessages"
    v-on="$listeners"
  >
    <template
      v-if="$scopedSlots.append"
      #append
    >
      <slot name="append" />
    </template>
    <template
      v-if="$scopedSlots['append-outer']"
      #append-outer
    >
      <slot name="append-outer" />
    </template>
    <template
      v-if="$scopedSlots.prepend"
      #prepend
    >
      <slot name="prepend" />
    </template>
    <template
      v-if="$scopedSlots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner" />
    </template>
  </v-combobox>
</template>

<script>
import appInput from '../mixins/appInput'

export default {
  name: 'AppCombobox',

  mixins: [appInput],

  inheritAttrs: false,

  methods: {
    // propagating methods to vuetify
    focus(...args) {
      this.$refs.vuetifyCombobox.focus(...args)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/mixins'

.AppCombobox
  &--disabled
    @include input-disabled()
</style>
