<!-- ajax loader, similar to https://github.com/quasarframework/quasar/blob/ca2c70a3d7cb3fe2b4029071b7efb3934a8a6d47/ui/src/components/ajax-bar/QAjaxBar.js -->

<template>
  <Portal
    to="ajax-bar"
    :disabled="!$store.state.ajaxBar.portal"
  >
    <v-progress-linear
      class="TheAjaxBar"
      :active="active"
      :value="value"
      :buffer-value="0"
      :class="{ 'TheAjaxBar--animated': animated }"
      color="success"
    />
  </Portal>
</template>

<script>
const TIMEOUT = 150
const HIDE_AFTER = TIMEOUT * 3
const FRACTION = 1 / 25

const wait = ms =>
  new Promise(resolve =>
    setTimeout(resolve, ms))

export default {
  name: 'TheAjaxBar',

  data: () => ({
    value: 0, // [0, 100]
    requestsCount: 0,
    animated: false,
    active: false,
  }),

  watch: {
    requestsCount: {
      async handler(n) {
        if (n) {
          this.active = true
        } else {
          this.value = 100
          await wait(HIDE_AFTER)
          if (!this.requestsCount) {
            this.active = false
            this.animated = false
          }
        }
      },
      immediate: true,
    },
  },

  created() {
    this.$xhrSend = null // XMLHttpRequest.prototype.send
    this.$xhrOpen = null // XMLHttpRequest.prototype.open
  },

  mounted() {
    this.hijackXhr()
  },

  methods: {
    hijackXhr() {
      if (this.$xhrSend) return
      this.$xhrSend = XMLHttpRequest.prototype.send
      this.$xhrOpen = XMLHttpRequest.prototype.open

      const vm = this

      XMLHttpRequest.prototype.open = function(_, url) {
        // skip loader for external requests (like analytics)
        let isExternal = true
        try {
          const parsed = new URL(url, location)
          isExternal = parsed.origin !== location.origin
        } catch (e) {
          console.warn(e)
        }
        this._isExternalRequest = isExternal
        return vm.$xhrOpen.apply(this, arguments)
      }

      XMLHttpRequest.prototype.send = function() {
        const request = this

        if (request._isExternalRequest) {
          return vm.$xhrSend.apply(request, arguments)
        }

        vm.onXhrStart(request, ...Array.from(arguments))
        request.addEventListener('loadend', function(...args) {
          const request = this
          return vm.onXhrEnd(request, ...args)
        }, false)

        return vm.$xhrSend.apply(this, arguments)
      }

      this.$once('hook:beforeDestroy', () => {
        XMLHttpRequest.prototype.send = this.$xhrSend
        XMLHttpRequest.prototype.open = this.$xhrOpen
        this.$xhrSend = null
        this.$xhrOpen = null
      })
    },

    async onXhrStart() {
      if (!this.requestsCount) this.value = 1
      ++this.requestsCount
      setTimeout(this.progressTick, TIMEOUT * 2)

      await this.$nextTick()
      this.animated = !!this.requestsCount
    },

    onXhrEnd() {
      this.requestsCount = Math.max(0, this.requestsCount - 1)
    },

    progressTick(timeout = TIMEOUT, fraction = FRACTION) {
      if (!this.requestsCount) return

      this.animated = true
      const free = 100 - this.value
      this.value += Math.min(100, free * fraction)
      setTimeout(() => this.progressTick(timeout, fraction), timeout)
    },
  },
}
</script>

<style lang="sass" scoped>
  $timeout: 150
  $hideAfter: $timeout * 3
  $heightDuration: 50

  .TheAjaxBar
    position: absolute
    top: 0
    transition: width 0s, top 0s, height #{$heightDuration}ms ease-in
    &--animated
      transition-property: width, top, height
      transition-duration: #{$timeout}ms, 0s, #{$heightDuration}ms
      transition-timing-function: linear, linear, cubic-bezier(.9, .2, .8, .7)
      transition-delay: 0, 0, #{$hideAfter - $timeout - $heightDuration}ms
</style>
