import * as R from 'ramda'

import { ServiceService, SettingsService } from '../../api'
import { LDAP_NOT_LOADED, LDAP_MISSING, SMTP_MISSING, SMTP_NOT_LOADED } from '../../constants'
import { handleError } from '../../helpers'

const initState = () => ({
  ldapConfig: LDAP_NOT_LOADED,

  // {
  //   authenticationMethod: 'LDAP', // or 'PLAIN'
  // }
  serverInfo: null,

  // See `UserNotificationSettingsSchema`
  // {
  //   enable: true,
  //   newIssue: true,
  //   newIssueMessage: true,
  //   issueStatusChange: true,
  // }
  notificationSettings: null,

  // See `SystemWideSettingsSchema`
  // {
  //   mfaRequired: true,
  // }
  systemSettings: null,

  // See `SmtpConfigSchema`
  smtpConfig: null,
})

const getters = {}

const mutations = {
  reset: (state, full = false) => Object.assign(
    state,
    // preserve serverInfo to save authenticationMethod
    R.omit(full ? [] : ['serverInfo'], initState()),
  ),

  setServerInfo: (state, serverInfo) => {
    state.serverInfo = serverInfo
  },

  setLdapConfig: (state, ldapConfig) => {
    state.ldapConfig = ldapConfig
  },

  setNotificationSettings: (state, settings) => {
    state.notificationSettings = settings
  },

  setSystemSettings: (state, settings) => {
    state.systemSettings = settings
  },

  setSmtpConfig: (state, smtpConfig) => {
    state.smtpConfig = smtpConfig
  },
}

const actions = {
  testLdapConfig: (_, { ldapConfig }) =>
    ServiceService.ldapConfigTest({ body: ldapConfig }),

  getServerInfo: async ({ state, commit }, { reload = true } = {}) =>
    (!reload && state.serverInfo != null) ||
    ServiceService.serverInfo()
      .then(serverInfo => commit('setServerInfo', serverInfo))
      .catch(e => handleError({ commit }, e)),

  getLdapConfig: async ({ state, commit }, { reload = true } = {}) =>
    (!reload && ![LDAP_NOT_LOADED, LDAP_MISSING].includes(state.ldapConfig)) ||
    ServiceService.ldapConfigGet()
      .then(ldapConfig => commit('setLdapConfig', ldapConfig))
      .catch(error => {
        if (error?.response?.status === 400) {
          commit('setLdapConfig', LDAP_MISSING)
        } else {
          commit('setLdapConfig', LDAP_NOT_LOADED)
          throw error
        }
      })
      .catch(e => handleError({ commit }, e)),

  createLdapConfig: ({ commit }, { ldapConfig }) =>
    ServiceService.ldapConfigPost({ body: ldapConfig })
      .then(ldapConfig => commit('setLdapConfig', ldapConfig))
      .catch(e => handleError({ commit }, e)),

  updateLdapConfig: ({ commit }, { ldapConfig }) =>
    ServiceService.ldapConfigPatch({ body: ldapConfig })
      .then(ldapConfig => commit('setLdapConfig', ldapConfig))
      .catch(e => handleError({ commit }, e)),

  deleteLdapConfig: ({ commit }) =>
    ServiceService.ldapConfigDelete()
      .then(() => commit('setLdapConfig', LDAP_MISSING))
      .catch(e => handleError({ commit }, e)),

  getNotificationSettings: ({ commit }) =>
    SettingsService.notificationsSettingsGet()
      .then(settings => commit('setNotificationSettings', settings))
      .catch(e => handleError({ commit }, e)),

  setNotificationSettings: ({ commit }, { settings }) =>
    SettingsService.notificationsSettingsPatch({ body: settings })
      .then(settings => commit('setNotificationSettings', settings))
      .catch(e => handleError({ commit }, e)),

  getSystemSettings: ({ commit }) =>
    SettingsService.systemSettingsGet()
      .then(settings => commit('setSystemSettings', settings))
      .catch(e => handleError({ commit }, e)),

  setSystemSettings: ({ commit }, { settings }) =>
    SettingsService.systemSettingsPatch({ body: settings })
      .then(settings => commit('setSystemSettings', settings))
      .catch(e => handleError({ commit }, e)),

  getSmtpConfig: ({ commit }) =>
    ServiceService.smtpConfigGet().then(smtpConfig => commit('setSmtpConfig', smtpConfig))
      .catch(error => {
        if (error?.response?.status === 400) {
          commit('setSmtpConfig', SMTP_MISSING)
        } else {
          commit('setSmtpConfig', SMTP_NOT_LOADED)
          throw error
        }
      })
      .catch(e => handleError({ commit }, e)),

  createSmtpConfig: ({ commit }, { smtpConfig }) =>
    ServiceService.smtpConfigPost({ body: smtpConfig })
      .then(smtpConfig => commit('setSmtpConfig', smtpConfig))
      .catch(e => handleError({ commit }, e)),

  updateSmtpConfig: ({ commit }, { smtpConfig }) =>
    ServiceService.smtpConfigPatch({ body: smtpConfig })
      .then(smtpConfig => commit('setSmtpConfig', smtpConfig))
      .catch(e => handleError({ commit }, e)),

  deleteSmtpConfig: ({ commit }) =>
    ServiceService.smtpConfigDelete()
      .then(() => commit('setSmtpConfig', SMTP_MISSING))
      .catch(e => handleError({ commit }, e)),

  sendTestEmail: ({ commit }, { mail }) =>
    ServiceService.sendTestEmail({ body: mail }).catch(e => handleError({ commit }, e)),
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}
