import axios from 'axios'
import moment from 'moment'
import * as R from 'ramda'

import { LicenseService } from '../../../gen'
import { handleError } from '@/helpers'
import * as CONST from '@/constants'
import i18n from '../../i18n'

const initState = () => ({
  // `LicenseInfo`
  // Example:
  // {
  //   customerName: 'The best Customer',
  //   endDate: '2022-04-31T07:10:50.528907+00:00',
  //   id: '1cc2d47d-2fea-4579-93a9-215ad83c7c93',
  //   isNotExpired: true,
  //   isValid: true,
  //   licenseType: 'community',
  //   productName: 'Apiary',
  //   startDate: '2022-04-19T07:10:50.528903+00:00',
  //   userCount: 20,
  //   version: 1,
  // }
  current: null,

  // `LicenseInformationSchema`
  // with props:
  // projectParameters: Object<ProjectId, PerProjectCounters>
  // commonParameters: `LicenseInformationSchema_commonParameters`
  information: null,
  // `LicenseCounter`
  // Same as above, example:
  // {
  //   commonParameters: {
  //     project: {
  //       currentCount: 3,
  //       isAvailable: true,
  //       isVisible: true,
  //       maxCount: null,
  //     },
  //     jira: {
  //       currentCount: null,
  //       isAvailable: true,
  //       isVisible: true,
  //       maxCount: null,
  //     },
  //     ldap: {
  //       currentCount: null,
  //       isAvailable: true,
  //       isVisible: true,
  //       maxCount: null,
  //     },
  //     user: { ... },
  //   },
  //   projectParameters: {
  //     '58c1b36b-13a0-4a19-8f30-dd4275b7ac9a': {
  //       report: {
  //         currentCount: 15,
  //         isAvailable: true,
  //         isVisible: true,
  //         maxCount: 30,
  //       },
  //     },
  //     '6b707d3e-6230-4a77-8c35-48b539308b27': { ... },
  //     '2fa5501d-d30a-4566-a1f5-a83d974a2ad2': { ... },
  //   },
  // }
  informationExpired: null,

  limitDialogState: { // Dialog for license limit
    isOpen: false,
    featureName: null,
    errorMessage: null,
    countable: null,
  },
})

const getters = {
  combinedCounters: ({ information, informationExpired }) => {
    if (!information || !informationExpired) return null

    if (!R.equals(
      Object.keys(information.projectParameters),
      Object.keys(informationExpired.projectParameters),
    )) {
      // we should try to zip counters if any projects don't match
      return null
    }

    const combineCounters = (counters, expiredCounters) => R.pipe(
      R.keys,
      R.map(key => [
        key,
        {
          current: counters[key],
          expired: expiredCounters[key],
        },
      ]),
      R.fromPairs,
    )(counters)

    return {
      commonParameters: combineCounters(
        information.commonParameters,
        informationExpired.commonParameters,
      ),
      projectParameters: combineCounters(
        information.projectParameters,
        informationExpired.projectParameters,
      ),
    }
  },

  isExtended: ({ current: lic }) =>
    lic == null
      ? null
      : lic.licenseType !== CONST.COMMUNITY_PLAN,

  isMaxLicense: ({ current: lic }) =>
    lic == null
      ? null
      : lic.licenseType === CONST.CORPORATE_PLAN,

  isExpired: ({ current: lic }) =>
    lic
      ? !lic.isNotExpired
      : null,

  expiresSoon: ({ current: lic }, { isExpired }) =>
    lic == null
      ? null
      : !isExpired && !!lic.endDate &&
          moment(lic.endDate).startOf('day')
            .diff(moment(), 'days') <= CONST.TERMINATION_NOTICE,

  featureActive: ({ information }) =>
    featureName => {
      if (!information) return undefined
      const featureInfo = information.commonParameters[featureName]
      if (!featureInfo) return null
      return featureInfo.isAvailable && featureInfo.isVisible
    },
}

const mutations = {
  reset: state =>
    Object.assign(state, initState()),

  setCurrent: (state, { license }) => {
    state.current = license
  },

  setInformation: (state, { licenseCounter }) => {
    state.information = licenseCounter
  },

  setInformationExpired: (state, { licenseCounter }) => {
    state.informationExpired = licenseCounter
  },

  setLicenseDialog: (state, {
    isOpen = null,
    featureName = null,
    errorMessage = null,
    countable = null,
  }) => {
    state.limitDialogState = {
      ...state.limitDialogState,
      ...R.ifElse(
        R.propEq('isOpen', true),
        R.identity,
        R.filter(val => !R.isNil(val)),
      )({ isOpen, featureName, errorMessage, countable }),
    }
  },
}

const actions = {
  post: ({ dispatch, commit }, { file }) => {
    const formData = new FormData()
    formData.append('license_file', file)

    return axios({
      method: 'POST',
      url: '/api/license/',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => dispatch('getFull'))
      .catch(e => handleError({ commit }, e, i18n.t('license.ErrorUploadingNewLicenseM')))
  },

  get: ({ commit }) =>
    LicenseService.licenseGet()
      .then((license) => commit('setCurrent', { license }))
      .catch(e => handleError({ commit }, e, i18n.t('license.ErrorFetchingActiveLicenseM'))),

  getInformation: ({ state, commit }, { reload = true } = {}) =>
    !reload && state.information != null
      ? Promise.resolve(undefined)
      : LicenseService.licenseInformation()
        .then((licenseCounter) => commit('setInformation', { licenseCounter }))
        .catch(e => handleError({ commit }, e, i18n.t('license.ErrorFetchingLicenseInfoM'))),

  getInformationExpired: ({ commit }) =>
    LicenseService.licenseInformationExpired()
      .then((licenseCounter) => commit('setInformationExpired', { licenseCounter }))
      .catch(e => handleError({ commit }, e, i18n.t('license.ErrorFetchingLicenseInfoM'))),

  getFull: ({ dispatch }) => Promise.all([
    dispatch('get'),
    dispatch('getInformation'),
    dispatch('getInformationExpired'),
  ]),

  delete: ({ dispatch, commit }) =>
    LicenseService.licenseDelete()
      .then(() => dispatch('getFull'))
      .catch(e => handleError({ commit }, e, i18n.t('license.ErrorDeletingLicenseM'))),
}

export default {
  namespaced: true,

  state: initState,
  getters,
  mutations,
  actions,
}
